import { Box, CircularProgress, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import firebase from '../../firebase'
import EthereumNFT from '../ethereum/EthereumNFT'

export default function EthereumShowSimilar(props) {
  const [similarNFTs, setSimilarNFTs] = useState([])
  const [dissimilarNFTs, setDissimilarNFTs] = useState([])
  const [showSimilarNFTs, setShowSimilarNFTs] = useState(false)
  const [showOppositeNFTs, setShowOppositeNFTs] = useState(false)
  const [didLoad, setDidLoad] = useState(false)
  const [progress, setProgress] = useState(0);
  const { contractAddress, tokenID,  url, chain } = props

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 9));
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const loadSimilar = () => {
    if (url) {
      var data = new FormData()
      data.append('url', url)

      axios
        .get(`https://api.lazy-more-like.xyz/api/v1/get-recommendations?${new URLSearchParams({ url })}`, {
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
        })
        .then((res) => {
          // TODO: Get bizzaroworld to not show storage.opensea.io links
          const limit = props.limit ? props.limit : 999999;
          const similar = res.data.alikes.filter(x => x.image_url.indexOf('storage.opensea.io') == -1).slice(0, limit)
          const dissimilar = res.data.unlikes.filter(x => x.image_url.indexOf('storage.opensea.io') == -1).slice(0, limit)
          setSimilarNFTs(similar)
          setDissimilarNFTs(dissimilar)
          setDidLoad(true)
        })
        .catch((err) => {
          setDidLoad(true)
        })
    }
  }

  const viewRabbitHole = () => {
    if (chain === 'ethereum') {
      window.location.href =
      '/rabbithole/ethereum/' + contractAddress + '/' + tokenID
    } else {
      window.location.href = '/rabbithole' + "?" + new URLSearchParams({
        tokenID: tokenID, 
        url: btoa(url),
        chain: 'wax'
      }).toString()
    }
  }

  const viewBizarroWorld = () => {
    if (chain === 'ethereum') {
      window.location.href =
      '/bizarroworld/ethereum/' + contractAddress + '/' + tokenID
    } else {
      window.location.href = '/bizarroworld' + "?" + new URLSearchParams({
        tokenID: tokenID, 
        url: btoa(url),
        chain: 'wax'
      }).toString()
    }
  }

  useEffect(() => {
    loadSimilar()
  }, [contractAddress])

  const similarComponents = similarNFTs.map((NFT, idx) => {
    return (
      <EthereumNFT
        key={idx}
        contractAddress={NFT.asset_contract_address}
        tokenID={NFT.token_id}
        walletAddress=''
        name={NFT.name}
        image={NFT.image_url}
        username=""
        tokenNo={NFT.token_id}
        onClickUrl={`/${props.view == 'similar' ? 'rabbithole' : 'bizarroworld'}/ethereum/` + NFT.asset_contract_address + '/' + NFT.token_id}
      />
    )
  })
  const dissimilarComponents = dissimilarNFTs.map((NFT, idx) => {
    return (
      <EthereumNFT
        key={idx}
        contractAddress={NFT.asset_contract_address}
        tokenID={NFT.token_id}
        walletAddress={''}
        name={NFT.name}
        image={NFT.image_url}
        username=""
        tokenNo={NFT.token_id}
        onClickUrl={`/${props.view == 'similar' ? 'rabbithole' : 'bizarroworld'}/ethereum/` + NFT.asset_contract_address + '/' + NFT.token_id}
      />
    )
  })

  const loadingComponent = !didLoad && (
      <Box sx={{ display: 'flex', }}>
        <CircularProgress variant="determinate" value={progress} style={{marginBottom: 60, color: 'rgba(0,0,0,.3)' }} size={300} />
        <Box
          sx={{
            top: 140,
            left: -184,
            position: 'relative',
            width: 0
          }}
      >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            style={{ width: 200}}
          >{`Loading NFTs`}</Typography>
        </Box>
      </Box>
    );
  let content;
  if (props.view === 'similar') {
    content = (
      <div className="container">
        <div className="heading">
          <span className="title">Similar NFTs 🎩</span>
        </div>
        <div className="NFT-container">
          {loadingComponent}
          {similarComponents}
          {similarComponents.length === 0 && didLoad && (
            <center>No similar NFTs found. Check back later!</center>
          )}
        </div>
        <center>
          <button
            className="clear"
            style={{ marginTop: '20px', marginBottom: '50px', minWidth: '220px' }}
            onClick={viewBizarroWorld}
          >
            BizarroWorld
          </button>
        </center>
      </div>
    )
  } else if (props.view === 'opposite') {
    content = (
      <div className="container">
        <div className="heading">
          <span className="title">Opposite NFTs</span>
        </div>
        <div className="NFT-container">
          {loadingComponent}
          {dissimilarComponents}
          {dissimilarComponents.length === 0 && didLoad && (
            <center>No opposite NFTs found. Check back later!</center>
          )}
        </div>
        <center>
          <button
            className="clear"
            style={{ marginTop: '20px', marginBottom: '50px', minWidth: '220px' }}
            onClick={viewRabbitHole}
          >
            RabbitHole
          </button>
        </center>
      </div>
    )
  } else {
    content = (
      <div>
        {similarComponents.length > 0 && !showSimilarNFTs && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px', minWidth: '220px' }}
              onClick={() => setShowSimilarNFTs(true)}
            >
              Show Similar 🎩
            </button>
          </center>
        )}
        {loadingComponent}
        {similarComponents.length > 0 && showSimilarNFTs && (
          <div className="container">
            <div className="heading">
              <span className="title">Similar NFTs 🎩</span>
            </div>
            <div className="NFT-container">{similarComponents}</div>
          </div>
        )}
        {dissimilarComponents.length > 0 && !showOppositeNFTs && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px', marginBottom: '50px', minWidth: '220px' }}
              onClick={() => setShowOppositeNFTs(true)}
            >
              Show Opposites 🚫
            </button>
          </center>
        )}
        {dissimilarComponents.length > 0 && showOppositeNFTs && (
          <div className="container">
            <div className="heading">
              <span className="title">Opposite NFTs 🚫</span>
            </div>
            <div className="NFT-container">{dissimilarComponents}</div>
          </div>
        )}
      </div>
    )
  }

  return content
}
