import React from 'react'
import './WalletsItem.css'
import firebase from '../../firebase'

export default function WalletItem(props) {
  const {
    id: walletID,
    type: walletType,
    address: walletAddress,
    displayName: walletDisplayName
  } = props.wallet

  const removeWallet = (_walletID) => {
    if (window.confirm('Are you sure you want to remove this wallet?')) {
      const db = firebase.firestore()
      const username = localStorage.getItem('username')
      db.collection('users')
        .doc(username)
        .collection('wallets')
        .doc(_walletID)
        .delete()
        .then((snapshot) => {
          // log event
          firebase.analytics().logEvent('wallet_removed', {
            wallet_type: walletType,
            wallet_address: '_' + walletAddress,
            username: username
          })
          props.loadWallets()
        })
    }
  }

  return (
    <div className="wallet-container">
      <div className="wallet">
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
          <span className="wallet-type">
            <b>{walletType}</b>
          </span>
          <span className="wallet-address">
            {walletDisplayName ? <>@{walletDisplayName}</> : walletAddress}
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <button className="clear" onClick={() => removeWallet(walletID)}>
            Remove
          </button>
        </div>
      </div>
    </div>
  )
}
