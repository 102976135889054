import { isNil } from 'lodash';
import React, { useState } from 'react';
import firebase from '../../firebase'
import { WaxNFTData, WAX_IPFS_URL } from './WaxHelper';
import WaxBundleModal from './waxModals/BundleModal';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ImageWSkeleton from '../ImageWSkeleton';
import VideoWSkeleton from '../VideoWSkeleton';

export type WaxNFTProps = {
    data: WaxNFTData;
    walletAddress: string;
    key: number;
    username: string;
    isTiny?: boolean;
    caption?: string;
    pinID: string
    functions?: any;
    hiddenItem?: boolean;
    isAuthor?: boolean; // cheat for auctions
    reloadPinnedNFTs?: () => void;
    removePin?: (id: string) => void;
    isDraggable: boolean;
    isClickable: boolean;
};

type State = {
  showBundle: boolean;
  menuShown: boolean;
  pinned: boolean;
  caption?: string | null;
};


const WaxNFTNew: React.FC<WaxNFTProps> = (props) => {

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.pinID
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const [pinned, setPinned] = useState(props.pinned);
  const [menuShown, setMenuShown] = useState(false);
  const [showBundle, setShowBundle] = useState(false);

  const loadMenu = () => {
    let menu;
    let menuButton;

    if (menuShown) {
        const viewerIsAuthor = checkIfAuthor();
        const viewerIsAdmin = isAdmin();

        menu = (
          <div className="NFT-menu" style={{ display: 'inline-block' }}>
            {viewerIsAuthor && !pinned && !props.hiddenItem && (
              <div className="NFT-menu-option" onClick={handleHide}>
                Hide
              </div>
            )}
            {viewerIsAuthor && !pinned && props.hiddenItem && (
              <div className="NFT-menu-option" onClick={handleUnhide}>
                Unhide
              </div>
            )}
            {viewerIsAuthor && !pinned && !props.hiddenItem && (
              <div className="NFT-menu-option" onClick={handleAddPin}>
                Pin
              </div>
            )}
            {viewerIsAuthor && pinned && !props.hiddenItem && (
              <div className="NFT-menu-option" onClick={handleRemovePin}>
                Unpin
              </div>
            )}
            {viewerIsAuthor && !props.hiddenItem && isSellable && (
              <div className="NFT-menu-option" onClick={handleSell}>
                List for Sale
              </div>
            )}
            {viewerIsAuthor && !props.hiddenItem && isSellable && (
              <div className="NFT-menu-option" onClick={handleAuction}>
                List for Auction
              </div>
            )}
            {viewerIsAuthor && !props.hiddenItem && isOnSale && (
              <div className="NFT-menu-option" onClick={handleCancelSale}>
                Cancel Sale Listing
              </div>
            )}
            {!props.hiddenItem && isOnSale && (
              <div className="NFT-menu-option" onClick={() => viewSaleOnWax(props.data.salesData!.sale_id)}>
                View Sale on AtomicHub
              </div>
            )}
            {!props.hiddenItem && isOnAuction && (
              <div className="NFT-menu-option" onClick={() => viewAuctionOnWax(props.data.auctionsData!.auction_id!)}>
                View Auction on AtomicHub
              </div>
            )}
            {viewerIsAuthor && !props.hiddenItem && (isOnAuction || onAuctionButOver) && (
              <div className="NFT-menu-option" onClick={handleCancelSale}>
                Cancel Auction Listing
              </div>
            )}
            {<div className="NFT-menu-option" onClick={() => viewRabbithole('rabbithole')}>
              Rabbithole
            </div>}
            {<div className="NFT-menu-option" onClick={() => viewRabbithole('bizarroworld')}>
              Bizarroworld
            </div>}
            <div className="NFT-menu-option" onClick={() => viewOnWax(props.data.asset_id)}>
              View on AtomicHub
            </div>
            {viewerIsAuthor && <div className="NFT-menu-option" onClick={() => props.functions.setAsProfilePicture(img_url, animation_url)}>
              Set as Profile Picture
            </div>}
          </div>
        )
        menuButton = (
          <button
            className="options-btn"
            onClick={showOptions}
            style={{ backgroundImage: "url('/menu-close-icon.png')" }}
          ></button>
        )
      } else {
        menu = <div className="NFT-menu" style={{ display: 'none' }}></div>
        menuButton = (
          <button
            className="options-btn"
            onClick={showOptions}
            style={{ backgroundImage: "url('/menu-more-icon.png')" }}
          ></button>
        )
      }
      return [menu, menuButton]
  }

  const checkIfAuthor = () => {
    const username = localStorage.getItem('username')
    if (username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const isAdmin = () => {
    if (firebase.auth().currentUser?.uid === 'hhoXubahBuWi9o4ifrKjHIVZe843') {
      return true
    }
    return false
  }
    

  const handleAddPin = () => {
    setMenuShown(false);
    props.functions.handlePin(props.walletAddress, props.data.contract, props.data.asset_id, 'Wax')
    .then(() => setPinned(true));

  };

  const handleRemovePin = () => {
    setMenuShown(false);
    props.functions.handleUnpin(props.pinID)
    .then(() => setPinned(false))
    
  };

  const handleHide = () => {
    setMenuShown(false);
    props.functions.handleHide(props.walletAddress, '', props.data.asset_id, 'Wax')
    
  };

  const handleUnhide = async () => {
    setMenuShown(false);
    props.functions.handleUnhide(props.pinID)
  };

  const viewNFTDetail = () => {
    if (props.isClickable !== false) {
      setMenuShown(false);
      document.location.href = '/wax/' + props.data.asset_id;
    }
  };

  const isAuthor = () => {
    const username = localStorage.getItem('username')!
    if (username && props.username) {
        return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const showOptions = () => {
    setMenuShown(!menuShown);
  }

  const viewOnWax = (assetId: string) => {
    setMenuShown(false);
    firebase.analytics().logEvent('wax_click', {
        flow_id: '_' + 'this.props.flowID',
        moment_id: '_' + props.data.asset_id,
        wallet_address: '_' + props.walletAddress.toLowerCase()
    });
    window.open(`https://wax.atomichub.io/explorer/asset/${assetId}`);
  };

  const viewSaleOnWax = (saleId: string) => {
    setMenuShown(false);
    window.open(`https://wax.atomichub.io/market/sale/${saleId}`);
  };
  
  const viewAuctionOnWax = (auctionId: string) => {
    setMenuShown(false);
    window.open(`https://wax.atomichub.io/market/auction/${auctionId}`);
  };
  
  const handleSell = () => {
    props.functions.handleWaxSell(props.data.asset_id, props.data.owner);
  };
  
  const handleSellCancel = (id: string) => {
    props.functions.handleWaxCancelSell(id);
  };
  
  const handleCancelSale = () => {
    const auctid = props.data.auctionsData && props.data.auctionsData.auction_id!;
    const salesid = props.data.salesData && props.data.salesData.sale_id;
    if (auctid) {
      handleCancelAuction(auctid);
    } else if (salesid) {
      handleSellCancel(salesid);
    }
  };
  
  const handleAuction = () => {
    props.functions.handleWaxAuction(props.data.asset_id, props.data.owner);
  };
  
  const handleCancelAuction = (id: string) => {
    props.functions.handleWaxCancelAuction(id);
  };
  
  const handleBidAuction = () => {
    props.functions.handleWaxBidAuction(props.data);
  };
  
  const handleClaimNFT = () => {
    props.functions.handleClaimWaxAuctionNFT(props.data);
  };
  
  const handleClaimMoney = () => {
    props.functions.handleClaimWaxMoney(props.data);
  };
  
  const handleBuy = () => {
    props.functions.handleWaxBuy(props.data, props.data.owner);
  };
  
  const handleBuyBundle = () => {
    props.functions.handleWaxBuyBundle(props.data.salesData);
  };
  
  const showWaxBundle = () => {
    setShowBundle(true);
  };
    
  const getImgUrl = (ipfs?: string) => {
    if (ipfs && ipfs.indexOf('https') > -1) {
      return ipfs;
    }
    return `https://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=${ipfs}&size=370`;
  };
  
  const getFullImgUrl = (ipfs?: string) => {
    if (ipfs && ipfs.indexOf('https') > -1) {
      return ipfs;
    }
    return WAX_IPFS_URL + ipfs;
  };
  
  const viewRabbithole = (to: string) => {
    const url = getFullImgUrl(props.data.data.img);
    let animationUrl = '';
    if (props.data.data.video) {
      animationUrl = getFullImgUrl(props.data.data.video);
    }
    window.location.href = `/${to}` + '?' + new URLSearchParams({
      tokenID: props.data.asset_id,
      walletAddress: props.walletAddress,
      url: btoa(url),
      animationUrl: btoa(animationUrl),
      chain: 'wax'
    }).toString();
  }

  const [menu, menuButton] = loadMenu()

  let classes
  const viewerIsAuthor = props.isAuthor == undefined ? isAuthor() : props.isAuthor;
  const nftClass = viewerIsAuthor ? 'NFT-own' : 'NFT'
  if (props.isDraggable === true) {
    classes = `draggable ${nftClass}`
  } else {
    classes = `clickable ${nftClass}`
  }

  let media,
  animation_url: string | undefined,
  img_url: string | undefined;
  if (props.data.data.video) {
    animation_url = getFullImgUrl(props.data.data.video);
      media = <VideoWSkeleton width={320} height={320} videoSrc={animation_url} imgClassName='video' />
  } else {
    img_url = pinned ? getFullImgUrl(props.data.data.img) : getImgUrl(props.data.data.img)
      media = <ImageWSkeleton imgClassName='image' width={320} height={320} imgSrc={img_url} />
  }

  var caption = '"' + props.caption + '"';


  const onAuctionButOver = props.data.auctionsData && props.data.auctionsData.state === 4;
  const onAuctionButSold = props.data.auctionsData && props.data.auctionsData.state === 3;
  const auctionBoughtByUser = onAuctionButSold && !isNil(props.walletAddress) &&
    props.walletAddress === props.data.auctionsData!.buyer;
  const auctionMoneyClaimable = onAuctionButSold && props.walletAddress === props.data.auctionsData!.seller;
  const isSellable = !onAuctionButOver && (!props.data.salesData && props.data.auctions.length == 0);
  const isOnSale = props.data.salesData;
  const isOnAuction = props.data.auctionsData;
  const isAuctionLive = props.data.auctionsData && props.data.auctionsData.state === 1;
  const isSaleBundle = props.data.salesData && props.data.salesData.assets.length > 1;


  let listingPrice = 0, listingCurrency, showPrice: any = 0;
  if (props.data.salesData) {
      listingPrice = Number(props.data.salesData.listing_price);
      listingCurrency = props.data.salesData.listing_symbol.toUpperCase();
      if (listingCurrency == 'WAX') {
          showPrice = parseFloat((listingPrice / 100000000).toFixed(2));
      } else if (listingCurrency == 'USD') {
        showPrice = (listingPrice / 100).toFixed(2);
      }
  }

  const renderBundle = showBundle && (
    <WaxBundleModal 
      price={`${showPrice} ${listingCurrency}`}
      username={props.username} 
      walletAddress={props.walletAddress}
      data={props.data}
      handleBuy={() => handleBuyBundle()}
      handleClose={() => setShowBundle(false)}
    />
  )

  const bottomButtons = (
    <React.Fragment>
      {props.data.salesData && !viewerIsAuthor && !isSaleBundle && (
          <div style={{ textAlign: 'center'}}>
              <button
                  className="clear"
                  onClick={handleBuy}
                  style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                  Buy for {showPrice} {listingCurrency}
              </button>
          </div>
      )}
      {props.data.auctionsData && !viewerIsAuthor && isAuctionLive && (
          <div style={{ textAlign: 'center'}}>
              <button
                  className="clear"
                  onClick={handleBidAuction}
                  style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                  See Auction
              </button>
          </div>
      )}
       {(props.data.auctionsData || props.data.salesData) && viewerIsAuthor && (isOnSale || isAuctionLive) && !isSaleBundle && (
          <div style={{ textAlign: 'center'}}>
              <button
                  className="clear"
                  onClick={handleCancelSale}
                  style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                  Cancel Listing
              </button>
          </div>
      )}
      {
        auctionBoughtByUser && (
          <div style={{ textAlign: 'center'}}>
              <button
                  className="clear"
                  onClick={handleClaimNFT}
                  style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                  Claim Purchased NFT
              </button>
          </div>
        )
      }
      {
        auctionMoneyClaimable && (
          <div style={{ textAlign: 'center'}}>
              <button
                  className="clear"
                  onClick={handleClaimMoney}
                  style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                  Claim WAX From Auction
              </button>
          </div>
        )
      }
      {
        isSaleBundle && (
          <div style={{ textAlign: 'center'}}>
              <button
                  className="clear"
                  onClick={showWaxBundle}
                  style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                  See NFT Bundle for Sale
              </button>
          </div>
        )
      }
    </React.Fragment>
  )

  return (
    <div ref={setNodeRef} className={classes} style={style as any} {...attributes} {...listeners}>
      {renderBundle}
      {menuButton}
      {menu}
      <div style={{ textAlign: 'center'}}  onClick={viewNFTDetail}>
        {media}
        <div className="info">
          <div>
            <span className="name">{props.data.data.name}</span>
            <span style={{ display: 'block' }}>
              {props.data.collection ? props.data.collection.name : ''}
            </span>
            <span style={{top: 10, position: 'relative'}}>{props.caption && caption}</span>
          </div>
        </div>
      </div>
      {!props.isTiny && bottomButtons}
    </div>
  )

}

export default WaxNFTNew;