import React from 'react'

class ShareModal extends React.Component {
  constructor(props) {
    super(props)

    this.handleTwitterClick = this.handleTwitterClick.bind(this)
    this.handleFacebookClick = this.handleFacebookClick.bind(this)
    this.handleLinkedInClick = this.handleLinkedInClick.bind(this)
    this.handleEmailClick = this.handleEmailClick.bind(this)
  }

  handleTwitterClick() {
    let url =
      'https://twitter.com/share?text=' + this.props.shareText + '&url=' + this.props.shareLink
    window.open(url, '_blank')
  }

  handleFacebookClick() {
    let url =
      'https://www.facebook.com/sharer.php?t=' +
      encodeURIComponent(this.props.shareText) +
      '&u=' +
      encodeURIComponent(this.props.shareLink)
    window.open(url, '_blank')
  }

  handleLinkedInClick() {
    let url =
      'https://www.linkedin.com/sharing/share-offsite/?url=' +
      encodeURIComponent(this.props.shareLink)
    window.open(url, '_blank')
  }

  handleEmailClick() {
    let url = 'mailto:?&subject=Lazy.com&body=' + this.props.shareText + ' ' + this.props.shareLink
    window.location.href = url
  }

  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">Share</span>
          <span className="description">{this.props.message}</span>
          <button style={{ backgroundColor: '#4AA1EB' }} onClick={this.handleTwitterClick}>
            Twitter
          </button>
          <button style={{ backgroundColor: '#3479EA' }} onClick={this.handleFacebookClick}>
            Facebook
          </button>
          <button style={{ backgroundColor: '#2967BC' }} onClick={this.handleLinkedInClick}>
            LinkedIn
          </button>
          <button onClick={this.handleEmailClick}>Email</button>
          <button className="clear" onClick={this.props.handleClose}>
            Close
          </button>
        </div>
      </div>
    )
  }
}

export default ShareModal
