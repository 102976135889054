import axios from "axios";
import { isString } from "lodash";
require('dotenv').config()

const headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
const PAGE_LIMIT = 50


export const openseaGetByOwnerv2 = (walletAddress, cursor ) => {
    let endpoint
    if ( !process.env.REACT_APP_TESTING ) {
        endpoint =
            'https://api.opensea.io/api/v2/chain/ethereum/account/' +
            walletAddress +
            '/nfts?limit=' +
            PAGE_LIMIT 
        + (cursor ? `&next=${cursor}` : '')
    } else {
        endpoint =
            'https://rinkeby-api.opensea.io/api/v1/assets?owner=' +
            walletAddress +
            '&limit=' +
            PAGE_LIMIT +
        '&order_direction=desc'
        + '&include_orders=true'
            + cursor ? `&cursor=${cursor}` : ''
    }
    return axios.get( endpoint, { headers } );
}


export const openseaGetAssetsByContractv2 = (contractAddress, tokenID) => {
    let endpointURL;
    if ( process.env.REACT_APP_TESTING ) {
        endpointURL =
            'https://rinkeby-api.opensea.io/api/v1/assets?asset_contract_addresses=' +
            contractAddress +
            '&token_ids=' +
        tokenID
        + '&include_orders=true'
    } else {
        endpointURL =
            'https://api.opensea.io/api/v2/chain/ethereum/contract/' +
            contractAddress +
            '/nfts/' +
        tokenID
        + '&include_orders=true'
    }
    return axios.get( endpointURL, { headers } );
}

export const openseaGetMatic = ( walletAddress ) => {
    let endpointURL;
    if ( !process.env.REACT_APP_TESTING ) {
        endpointURL = 'https://api.opensea.io/api/v2/chain/matic/account/' + walletAddress + '/nfts?limit=50'
    } else {
        endpointURL =
        'https://api.opensea.io/api/v2/chain/mumbai/account/' + walletAddress + '/nfts?limit=50'
    }
    return endpointURL;
};

/*

// OpenSea V1 is retired

export const openseaGetAssetsByContract = (contractAddress, tokenID) => {
    let endpointURL;
    if ( process.env.REACT_APP_TESTING ) {
        endpointURL =
            'https://rinkeby-api.opensea.io/api/v1/assets?asset_contract_addresses=' +
            contractAddress +
            '&token_ids=' +
        tokenID
        + '&include_orders=true'
    } else {
        endpointURL =
            'https://api.opensea.io/api/v1/assets?asset_contract_addresses=' +
            contractAddress +
            '&token_ids=' +
        tokenID
        + '&include_orders=true'
    }
    return axios.get( endpointURL, { headers } );
}

*/ 

/*

// OpenSea V1 is retired

export const openseaGetAsset = ( contractAddressParams, tokenIdParam, walletAddressParam, options ) => {
    if ( contractAddressParams.filter( x => isString( x ) ).length == 0 ||
        tokenIdParam.filter( x => isString( x ) ).length == 0 ) {
        return Promise.resolve();
    }
    if (!options) options = []
    options.push('include_orders=true')
    const contractAddresses = contractAddressParams.map( x => '&asset_contract_addresses=' + x ).join( '' );
    const tokenIds = tokenIdParam.map( x => '&token_ids=' + x ).join('');
    const endpointURL =
        'https://api.opensea.io/api/v1/assets?' + walletAddressParam + contractAddresses.substring(1) + tokenIds + ( options ? options.map( x => '&' + x ).join('') : '' );
    return Promise.resolve( axios.get( endpointURL, { headers } ))
}
*/

/*
export const openseaGetOrders = ( contractAddressParams, tokenIdParam, walletAddressParam, options ) => {
    if ( contractAddressParams.filter( x => isString( x ) ).length == 0 ||
        tokenIdParam.filter( x => isString( x ) ).length == 0 ) {
        return Promise.resolve();
    }
    if (!options) options = []
    const contractAdds = {};
    contractAddressParams.forEach((addr, i) => {
        if (!contractAdds[addr]) {
            contractAdds[addr] = [tokenIdParam[i]];
        } else {
            contractAdds[addr].push(tokenIdParam[i]);
        }
    });
    const promises = Object.keys(contractAdds).map((addr, i) => {
        const tokenIds = contractAdds[addr].map( x => '&token_ids=' + x ).join('');
        const endpointURL = `https://api.opensea.io/api/v2/orders/ethereum/seaport/listings?asset_contract_address=${addr}` + tokenIds + ( options ? options.map( x => '&' + x ).join('') : '' );
        return axios.get(endpointURL, { headers });
    })
    return Promise.resolve(promises).then(x => { console.log(x); return x });
}
*/

/* 

// OpenSea V1 is retired

export const openseaGetByOwner = (walletAddress, cursor ) => {
    let endpoint
    if ( !process.env.REACT_APP_TESTING ) {
        endpoint =
            'https://api.opensea.io/api/v1/assets?owner=' +
            walletAddress +
            '&limit=' +
            PAGE_LIMIT +
        '&order_direction=desc'
        + '&include_orders=true'
        + (cursor ? `&cursor=${cursor}` : '')
    } else {
        endpoint =
            'https://rinkeby-api.opensea.io/api/v1/assets?owner=' +
            walletAddress +
            '&limit=' +
            PAGE_LIMIT +
        '&order_direction=desc'
        + '&include_orders=true'
            + cursor ? `&cursor=${cursor}` : ''
    }
    return axios.get( endpoint, { headers } );
}

*/