import React from 'react'
import BuyConfirmation from '../modals/BuyConfirmation'
import Error from '../modals/Error'
import WalletConfirmation from '../modals/WalletConfirmation'
import SellForm from '../modals/SellForm'
import UnlistConfirmation from '../modals/UnlistConfirmation'
import { EventType, OrderSide } from 'opensea-js/lib/types'
import firebase from '../../firebase'
import ShareModal from '../modals/ShareModal'
import PromptModal from '../modals/PromptModal'
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import Web3 from 'web3'
import { MAINNET_RPC_URL, walletPlugins } from '../../util/constants'
import { OpenSeaSDK, Network } from 'opensea-js'
import { ethers } from 'ethers'

require('dotenv').config()

const injected = injectedModule()

class Marketplace extends React.Component {
  provider = undefined
  signer = undefined
  constructor(props) {
    super(props)
    this.state = {
      displayBuyConfirmation: false,
      displayError: false,
      errorTitle: 'Error',
      errorMessage: null,
      displayWalletConfirmation: false,
      displaySellForm: false,
      displayUnlistConfirmation: false,
      name: null,
      price: null,
      contractAddress: null,
      tokenID: null,
      ownerWalletAddress: null,
      schemaName: null,
      shareLink: null,
      shareMessage: null,
      displayPromptModal: false,
      promptTitle: null,
      promptMessage: null,
      displayShareModal: false,
      selectedWalletName: null,
      selectedWalletAddress: null,
      order: null,
    }
    this.handleBuy = this.handleBuy.bind(this)
    this.handleBuyConfirmationCancel = this.handleBuyConfirmationCancel.bind(this)
    this.handleErrorClose = this.handleErrorClose.bind(this)
    this.handleBuyContinue = this.handleBuyContinue.bind(this)
    this.handleSellFormCancel = this.handleSellFormCancel.bind(this)
    this.handleSell = this.handleSell.bind(this)
    this.handleSellContinue = this.handleSellContinue.bind(this)
    this.handleUnlistConfirmationCancel = this.handleUnlistConfirmationCancel.bind(this)
    this.handleUnlist = this.handleUnlist.bind(this)
    this.handleUnlistContinue = this.handleUnlistContinue.bind(this)
    this.handlePromptClose = this.handlePromptClose.bind(this)
    this.handleShare = this.handleShare.bind(this)
    this.handleShareClose = this.handleShareClose.bind(this)

    var networkId
    if (!process.env.REACT_APP_TESTING) {
      // Main
      networkId = 1
    } else {
      // Rinkeby
      networkId = 4
    }
  }

  initOnboard = () => {
    this.onboard = Onboard({
      appMetadata: {
        name: 'My App',
        icon: `${location.origin}/lazy.png`,
        description: 'My app using Onboard'
      },
      chains: [
        {
          id: '0x1',
          token: 'ETH',
          label: 'Ethereum Mainnet',
          rpcUrl: MAINNET_RPC_URL
        }
      ],
        wallets: [injected, ...walletPlugins],
    })
  }

  async handleBuy(contractAddress, tokenID, name, price, ownerWalletAddress, paymentToken, order) {
    // log in google analytics
    firebase.analytics().logEvent('nft_buy_began', {
      contract_address: '_' + contractAddress,
      token_id: '_' + tokenID,
      price_in_eth: price / 1000000000000000000
    })

    this.setState({
      contractAddress: contractAddress,
      tokenID,
      name,
      price,
      ownerWalletAddress,
      paymentToken,
      order
    })

    try {
      let selectedWalletAddress = this.state.selectedWalletAddress;
      if (!this.onboard) {
        this.initOnboard()
      }
      if (this.onboard.state.get().wallets.length == 0) {
        try {
          const wallets = await this.onboard.connectWallet()
          if (wallets) {
            const address = wallets[0] && wallets[0].accounts && wallets[0].accounts[0] && wallets[0].accounts[0].address;
            if (address) {
              console.log(address);
              this.web3 = new Web3(wallets[0].provider)
              this.provider = new ethers.providers.Web3Provider(wallets[0].provider, 'any')
              selectedWalletAddress = address;
              this.setState({ selectedWalletAddress: address });

              this.signer = this.provider.getSigner()
            }
          }
        } catch (err) {
          console.log(err)
        }
      }

      // make sure a wallet was selected
      if (!selectedWalletAddress) {
        return
      }

      // make sure purchasing wallet is not the current owner of the NFT
      if (selectedWalletAddress.toLowerCase() === ownerWalletAddress.toLowerCase()) {
        this.setState({
          displayError: true,
          errorMessage: 'Your selected wallet address is already the current owner of this NFT.',
          selectedWalletAddress: null
        })
        return
      }

      // show buy confirmation
      this.setState({ displayBuyConfirmation: true })
    } catch (err) {
      this.setState({ selectedWalletAddress: null })
      console.log(err)
    }
  }

  async handleBuyContinue() {
    // hide buy confirmation
    this.setState({ displayBuyConfirmation: false })

    const contractAddress = this.state.contractAddress
    const tokenID = this.state.tokenID
    const ownerWalletAddress = this.state.ownerWalletAddress

    const provider = this.web3.currentProvider
    var network = null
    if (process.env.REACT_APP_TESTING) {
      network = Network.Rinkeby
    } else {
      network = Network.Main
    }

    const openseaSDK = new OpenSeaSDK(provider, {
      networkName: network,
      apiKey: process.env.REACT_APP_OPENSEA_API_KEY
    })

    // show wallet confirmation
    this.setState({ displayWalletConfirmation: true })

    try {
      // Get page 2 of all auctions, a.k.a. orders where `side == 1`
      const { orders, count } = await openseaSDK.api.getOrders({
        assetContractAddress: this.state.contractAddress,
        tokenIds: [Number(this.state.tokenID)],
        side: "ask",
        protocol: "seaport"
      })
      // get sell order
      await openseaSDK.fulfillOrder({ order: orders[0], accountAddress: this.state.selectedWalletAddress })

      // log in google analytics
      firebase.analytics().logEvent('nft_buy_completed', {
        contract_address: '_' + contractAddress,
        token_id: '_' + tokenID,
        price_in_eth: this.state.price / 1000000000000000000
      })

      this.setState({ displayWalletConfirmation: false })
      this.setState({
        displayPromptModal: true,
        promptTitle: 'Success',
        promptMessage:
          'Congrats on your purchase! It may take a few minutes for the transfer to complete.',
        shareLink: 'https://lazy.com',
        shareMessage: 'Check out this NFT I just purchased!'
      })
      //this.setState({ selectedWalletAddress: null })
    } catch (err) {
      //this.setState({ selectedWalletAddress: null })
      var errorMessage = err.message
      if (errorMessage.includes('err: insufficient funds for transfer')) {
        errorMessage = "You don't have enough funds in your wallet to buy this NFT."
      } else if (errorMessage.includes('Not found: no matching order found')) {
        errorMessage = 'This NFT was recently sold or unlisted but has not yet finished processing.'
      } else if (errorMessage.includes('User denied transaction')) {
        errorMessage = 'Transaction denied.'
      }
      this.setState({ displayWalletConfirmation: false })
      this.setState({
        displayError: true,
        errorMessage: errorMessage,
        errorTitle: 'Error'
      })
    }
  }

  async handleSell(contractAddress, tokenID, name, schemaName, ownerWalletAddress) {
    // log in google analytics
    firebase.analytics().logEvent('nft_list_began', {
      contract_address: '_' + contractAddress,
      token_id: '_' + tokenID
    })

    this.setState({ contractAddress, tokenID, name, schemaName, ownerWalletAddress })

    try {
      let selectedWalletAddress = this.state.selectedWalletAddress;
      if (!this.onboard) {
        this.initOnboard()
      }
      if (this.onboard.state.get().wallets.length == 0) {
        try {
          const wallets = await this.onboard.connectWallet()
          if (wallets) {
            const address = wallets[0] && wallets[0].accounts && wallets[0].accounts[0] && wallets[0].accounts[0].address;
            if (address) {
              console.log(address);
              this.web3 = new Web3(wallets[0].provider)
              this.provider = wallets[0].provider;
              selectedWalletAddress = address;
              this.setState({ selectedWalletAddress: address });
            }
          }
        } catch (err) {
          console.log(err)
        }
      }
      // make sure a wallet was selected
      if (!selectedWalletAddress) {
        return
      }

      // check that correct account is selected
      if (selectedWalletAddress.toLowerCase() !== ownerWalletAddress.toLowerCase()) {
        this.setState({
          displayError: true,
          errorMessage: 'Select wallet with address of ' + ownerWalletAddress + ' and try again.',
          selectedWalletAddress: null
        })
        return
      }

      // show sell form
      this.setState({ displaySellForm: true })
    } catch (err) {
      this.setState({ selectedWalletAddress: null })
      console.log(err)
    }
  }

  async handleSellContinue(amount) {
    this.setState({ displaySellForm: false })

    const contractAddress = this.state.contractAddress
    const tokenID = this.state.tokenID
    const ownerWalletAddress = this.state.ownerWalletAddress
    const schemaName = this.state.schemaName

    // create order
    const provider = this.web3.currentProvider
    var network = null
    if (process.env.REACT_APP_TESTING) {
      network = Network.Rinkeby
    } else {
      network = Network.Main
    }

    this.setState({ displayWalletConfirmation: true })

    const openseaSDK = new OpenSeaSDK(provider, {
      networkName: network,
      apiKey: process.env.REACT_APP_OPENSEA_API_KEY
    })

    try {
      if (schemaName === 'ERC1155') {
        await openseaSDK.createSellOrder({
          asset: {
            tokenId: tokenID,
            tokenAddress: contractAddress,
            schemaName: schemaName
          },
          accountAddress: ownerWalletAddress,
          startAmount: amount,
          quantity: 1,
          extraBountyBasisPoints: 25
        })
      } else {
        await openseaSDK.createSellOrder({
          asset: {
            tokenId: tokenID,
            tokenAddress: contractAddress,
            schemaName: schemaName
          },
          accountAddress: ownerWalletAddress,
          startAmount: amount,
          extraBountyBasisPoints: 25
        })
      }

      // log in google analytics
      firebase.analytics().logEvent('nft_list_completed', {
        contract_address: '_' + contractAddress,
        token_id: '_' + tokenID,
        price_in_eth: amount
      })

      this.setState({ displayWalletConfirmation: false })
      this.setState({
        displayError: true,
        errorTitle: 'Success',
        errorMessage:
          'The item was listed successfully! It may take a few minutes for changes to be visible.'
      })
      //this.setState({ selectedWalletAddress: null })
    } catch (err) {
      //this.setState({ selectedWalletAddress: null })
      console.log(err)
      this.setState({ displayWalletConfirmation: false })
      this.setState({
        displayError: true,
        errorMessage: err.message,
        errorTitle: 'Error'
      })
    }
  }

  async handleUnlist(contractAddress, tokenID, name, price, ownerWalletAddress, order) {
    // log in google analytics
    firebase.analytics().logEvent('nft_unlist_began', {
      contract_address: '_' + contractAddress,
      token_id: '_' + tokenID
    })

    this.setState({ contractAddress: contractAddress, tokenID, name, price, ownerWalletAddress, order })

    try {
      let selectedWalletAddress = this.state.selectedWalletAddress;
      if (!this.onboard) {
        this.initOnboard()
      }
      if (this.onboard.state.get().wallets.length == 0) {
        try {
          const wallets = await this.onboard.connectWallet()
          if (wallets) {
            const address = wallets[0] && wallets[0].accounts && wallets[0].accounts[0] && wallets[0].accounts[0].address;
            if (address) {
              console.log(address);
              this.web3 = new Web3(wallets[0].provider)
              this.provider = wallets[0].provider;
              selectedWalletAddress = address;
              this.setState({ selectedWalletAddress: address });
            }
          }
        } catch (err) {
          console.log(err)
        }
      }

      // make sure a wallet was selected
      if (!selectedWalletAddress) {
        return
      }

      // check that correct account is selected
      if (selectedWalletAddress.toLowerCase() !== ownerWalletAddress.toLowerCase()) {
        this.setState({
          displayError: true,
          errorMessage: 'Select wallet with address of ' + ownerWalletAddress + ' and try again.',
          selectedWalletAddress: null
        })
        return
      }

      // show unlist confirmation
      this.setState({ displayUnlistConfirmation: true })
    } catch (err) {
      this.setState({ selectedWalletAddress: null })
      console.log(err)
    }
  }

  async handleUnlistContinue() {
    this.setState({ displayUnlistConfirmation: false })

    const contractAddress = this.state.contractAddress
    const tokenID = this.state.tokenID
    const ownerWalletAddress = this.state.ownerWalletAddress

    this.setState({ displayWalletConfirmation: true })

    // initialize Seaport
    const provider = this.web3.currentProvider
    var network = null
    if (process.env.REACT_APP_TESTING) {
      network = Network.Rinkeby
    } else {
      network = Network.Main
    }

    const openseaSDK = new OpenSeaSDK(provider, {
      networkName: network,
      apiKey: process.env.REACT_APP_OPENSEA_API_KEY
    })

    try {
      const { orders, count } = await openseaSDK.api.getOrders({
        assetContractAddress: this.state.contractAddress,
        tokenIds: [Number(this.state.tokenID)],
        side: "ask",
        protocol: "seaport"
      })
      // cancel order
      await openseaSDK.cancelOrder({
        order: orders[0],
        accountAddress: ownerWalletAddress
      })

      firebase.analytics().logEvent('nft_unlist_completed', {
        contract_address: '_' + contractAddress,
        token_id: '_' + tokenID
      })

      this.setState({ displayWalletConfirmation: false })
      this.setState({
        displayError: true,
        errorMessage:
          'The item was unlisted successfully! It may take a few minutes for changes to be visible.',
        errorTitle: 'Success'
      })
      this.setState({ selectedWalletAddress: null })
    } catch (err) {
      this.setState({ selectedWalletAddress: null })
      console.log(err)
      var errorMessage = err.message
      if (errorMessage.includes('Not found: no matching order found')) {
        errorMessage = 'The NFT was previously unlisted but has not yet finished processing.'
      }
      this.setState({ displayWalletConfirmation: false })
      this.setState({
        displayError: true,
        errorMessage: errorMessage,
        errorTitle: 'Error'
      })
    }
  }

  handleBuyConfirmationCancel() {
    this.setState({ displayBuyConfirmation: false })
  }

  handleSellFormCancel() {
    this.setState({ displaySellForm: false })
  }

  handleUnlistConfirmationCancel() {
    this.setState({ displayUnlistConfirmation: false })
  }

  handleErrorClose() {
    this.setState({ displayError: false })
  }

  handlePromptClose() {
    this.setState({ displayPromptModal: false })
  }

  handleShare() {
    this.setState({ displayPromptModal: false, displayShareModal: true })
    firebase.analytics().logEvent('nft_shared', {
      contract_address: '_' + this.state.contractAddress,
      token_id: '_' + this.state.token_id
    })
  }

  handleShareClose() {
    this.setState({ displayShareModal: false })
  }

  render() {
    // setup modals
    var name = this.props.name
    if (this.state.name) {
      name = this.state.name
    }
    var price = this.props.price
    if (this.state.price) {
      price = this.state.price
    }

    var buyConfirmation = (
      <BuyConfirmation
        name={name}
        address={this.state.selectedWalletAddress}
        price={price}
        handleCancel={this.handleBuyConfirmationCancel}
        handleContinue={this.handleBuyContinue}
        paymentToken={this.state.paymentToken}
      />
    )

    var error = (
      <Error
        title={this.state.errorTitle}
        message={this.state.errorMessage}
        handleClose={this.handleErrorClose}
        shareLink={this.state.shareLink}
      />
    )

    const walletConfirmation = <WalletConfirmation walletName={this.state.selectedWalletName} />

    var sellForm = (
      <SellForm
        name={name}
        handleCancel={this.handleSellFormCancel}
        handleContinue={this.handleSellContinue}
      />
    )

    var unlistConfirmation = (
      <UnlistConfirmation
        name={name}
        price={price}
        handleCancel={this.handleUnlistConfirmationCancel}
        handleContinue={this.handleUnlistContinue}
      />
    )

    var promptModal = (
      <PromptModal
        title={this.state.promptTitle}
        message={this.state.promptMessage}
        options={['Share NFT']}
        handlers={[this.handleShare]}
        handleClose={this.handlePromptClose}
      />
    )

    const shareLink = 'https://lazy.com/nft/' + this.state.contractAddress + '/' + this.state.tokenID
    var shareModal = (
      <ShareModal
        message="Share your new NFT with the world!"
        shareText="Check out my new NFT!"
        shareLink={shareLink}
        handleClose={this.handleShareClose}
      />
    )

    return (
      <div>
        {this.state.displayBuyConfirmation && buyConfirmation}
        {this.state.displayError && error}
        {this.state.displayWalletConfirmation && walletConfirmation}
        {this.state.displaySellForm && sellForm}
        {this.state.displayUnlistConfirmation && unlistConfirmation}
        {this.state.displayPromptModal && promptModal}
        {this.state.displayShareModal && shareModal}
      </div>
    )
  }
}

export default Marketplace
