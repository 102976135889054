import React from 'react'
import BuyConfirmation from '../modals/BuyConfirmation'
import Error from '../modals/Error'
import * as waxjs from "@waxio/waxjs/dist";
import AnnounceSale from './waxModals/AnnounceSale'
import GenericModal from '../modals/GenericModal'
import { WaxNFTData, WaxSalesData } from './WaxHelper'
import BuyWaxModal from './waxModals/BuyWaxModal'
import AuctionModal from './waxModals/AuctionModal'


type Props = {}
type State = {
    display: string;
    errorTitle: string;
    errorMessage: string;
    name?: string;
    price?: string;
    contractAddress?: string;
    tokenID?: string;
    ownerWalletAddress?: string;
    selectedWalletName?: string;
    selectedWalletAddress?: string;
    saleID?: string;
    nft?: WaxNFTData;
    auctionsData?: WaxSalesData;
};

class WaxMarketplace extends React.Component<Props,State> {
  provider = undefined
  wax: waxjs.WaxJS | undefined;

  constructor(props: Props) {
    super(props)

    this.state = {
      display: '',
      errorTitle: 'Error',
      errorMessage: '',
    }

    this.wax = new waxjs.WaxJS({
      rpcEndpoint: 'https://wax.greymass.com',
      //userAccount:  "4ogmy.c.wam",
     // pubKeys: ["EOS55BoSQVxgh5wxfWiGy8QnXqZtdEmb85YkiGQGtuTJiGomGtZCY", "EOS8UhZSLGoiUSifugc4x2LrLbKW6GwKKNzJbxtZBBChqcKbfV18G"]
    });
  }

  initOnboard = () => {
    if (this.wax) {
      this.wax.login().then((walletAddress: string) => {
        this.setState({
            selectedWalletAddress: walletAddress
        })
      });  
    }
  }

  handleAnnounceAuction = async (tokenID: string, ownerWalletAddress: string) => {
    this.setState({
      //contractAddress: contractAddress,
      tokenID,
      ownerWalletAddress,
    })
    this.setState({ display: 'announceAuction' })
   };

  announceAuctionContinue = (price: number, type: string, duration?: number) => {
    let listing_price;
    switch (type) {
        case 'wax':
            listing_price = Number(price).toFixed(8) + " WAX";
            break;
        case 'usd':
            listing_price = Number(price).toFixed(2) + " USD";
            break;
        default:
            break;
    }

    if (!this.wax || !this.wax.api) {
        this.initOnboard();
    }
    let { selectedWalletAddress } = this.state;
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    if (this.wax && this.wax.api && selectedWalletAddress && this.state.tokenID && duration) { 
        this.wax.api.transact({
            actions: [
            {
              account: 'atomicmarket',
              name: 'announceauct',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                seller: selectedWalletAddress,
                asset_ids: [this.state.tokenID],
                starting_bid: listing_price,
                maker_marketplace: "golazydotcom",
                duration: duration * 86400,
              },
            },
            {
                account: 'atomicassets',
                name: 'transfer',
                authorization: [{
                  actor: selectedWalletAddress,
                  permission: 'active',
                }],
                data: {
                  from: selectedWalletAddress,
                  to: 'atomicmarket',
                  asset_ids: [this.state.tokenID],
                  memo: 'auction',
                },
              },]
          }, {
            blocksBehind: 3,
            expireSeconds: 60,
          }).then(x => {
              console.log(x);
            this.setState({
                display: 'error',
                errorTitle: 'Success!',
                errorMessage: 'Your NFT is now for auction! It will take roughly ~30-90 seconds for WAX to update with your bid. \n\n Click this to reload the page for updated details.'
            })
          }).catch(e => {
              console.log(e);
              this.setState({
                  display: 'error',
                  errorTitle: 'Error!',
                  errorMessage: `Something went wrong putting your NFT up for auction. The transaction was reverted and nothing happened.\n\n
                  What WAX says: \n\n${e}`
              })
          });
    }
  };

  handleClaimMoney = async (nft: WaxNFTData) => {
    if (!this.wax || !this.wax.api) {
      this.initOnboard();
    }
    let { selectedWalletAddress } = this.state;
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    if (this.wax && this.wax.api && selectedWalletAddress && nft && nft.auctionsData) { 
        this.wax.api.transact({
          actions: [
            {
              account: 'atomicmarket',
              name: 'auctclaimsel',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                auction_id: nft.auctionsData.auction_id
              },
            }]
          }, {
            blocksBehind: 3,
            expireSeconds: 60,
          }).then(x => {
              console.log(x);
            this.setState({
                display: 'error',
                errorTitle: 'Success!',
                errorMessage: 'Your have claimed your WAX tokens! Click this to reload the page for updated details.'
            })
          }).catch(e => {
              console.log(e);
              this.setState({
                  display: 'error',
                  errorTitle: 'Error!',
                  errorMessage: `Something went wrong claiming your WAX tokens. The transaction was reverted and nothing happened.\n\n
                  What WAX says: \n\n${e}`
              })
          });
      }
  };

  handleClaimAuctionNFT = async (nft: WaxNFTData) => {
    if (!this.wax || !this.wax.api) {
      this.initOnboard();
    }
    let { selectedWalletAddress } = this.state;
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    if (this.wax && this.wax.api && selectedWalletAddress && nft && nft.auctionsData) { 
        this.wax.api.transact({
          actions: [
            {
              account: 'atomicmarket',
              name: 'auctclaimbuy',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                auction_id: nft.auctionsData.auction_id
              },
            }]
          }, {
            blocksBehind: 3,
            expireSeconds: 60,
          }).then(x => {
              console.log(x);
            this.setState({
                display: 'error',
                errorTitle: 'Success!',
                errorMessage: 'Your have claimed your NFT! Click this to reload the page for updated details.'
            })
          }).catch(e => {
              console.log(e);
              this.setState({
                  display: 'error',
                  errorTitle: 'Error!',
                  errorMessage: `Something went wrong claiming your NFT. The transaction was reverted and nothing happened.\n\n
                  What WAX says: \n\n${e}`
              })
          });
      }
  };

  handleBidAuction = async (nft: WaxNFTData, ) => {
    this.setState({
      //contractAddress: contractAddress,
      nft
    })
    this.setState({ display: 'bid' })
   };

   handleBidContinue = (price: number) => {
    if (!this.wax || !this.wax.api) {
        this.initOnboard();
    }
    let { selectedWalletAddress, nft } = this.state;
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    if (this.wax && this.wax.api && selectedWalletAddress && nft && nft.auctionsData) { 
        this.wax.api.transact({
          actions: [
            {
              account: 'eosio.token',
              name: 'transfer',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                from: selectedWalletAddress,
                to: 'atomicmarket',
                quantity: `${price.toFixed(8)} WAX`,
                memo: 'deposit',
              },
            },
            {
              account: 'atomicmarket',
              name: 'auctionbid',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                auction_id: nft.auctionsData.auction_id,
                bid: `${price.toFixed(8)} WAX`,
                bidder: selectedWalletAddress,
                taker_marketplace: 'golazydotcom',
              },
            }]
          }, {
            blocksBehind: 3,
            expireSeconds: 60,
          }).then(x => {
              console.log(x);
            this.setState({
                display: 'error',
                errorTitle: 'Success!',
              errorMessage: 'Your bid was placed! It will take roughly ~30-90 seconds for WAX to update with your bid. \n\n Click this to reload the page for updated details. '
            })
          }).catch(e => {
              console.log(e);
              this.setState({
                  display: 'error',
                  errorTitle: 'Error!',
                  errorMessage: `Something went wrong bidding. The transaction was reverted and nothing happened.\n\n
                  What WAX says: \n\n${e}`
              })
          });
    }
  };

  handleCancelAuction = async (saleID: string) => {
    this.setState({
      saleID
    })
    this.setState({ display: 'cancelAuction' })
   };

  cancelAuctionContinue = () => {
    let { saleID, selectedWalletAddress} = this.state;
    if (!this.wax || !this.wax.api) {
        this.initOnboard();
    }
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    if (this.wax && this.wax.api && selectedWalletAddress && saleID) { 
        this.wax.api.transact({
            actions: [
            {
              account: 'atomicmarket',
              name: 'cancelauct',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                auction_id: saleID
              },
            }]
          }, {
            blocksBehind: 3,
            expireSeconds: 60,
          }).then(x => {
              console.log(x);
            this.setState({
                display: 'error',
                errorTitle: 'Success!',
                errorMessage: 'Your NFT auction is now canceled! It will take roughly ~30-90 seconds for WAX to update with your cancellation. \n\n Click this to reload the page for updated details.'
            })
          }).catch(e => {
              console.log(e);
              this.setState({
                  display: 'error',
                  errorTitle: 'Error!',
                  errorMessage: `Something went wrong cancelling your NFT sale. The transaction was reverted and nothing happened.\n\n
                  What WAX says: \n\n${e}`
              })
          });
    }
  };

  handleAnnounceSale = async (tokenID: string, ownerWalletAddress: string) => {
    this.setState({
      //contractAddress: contractAddress,
      tokenID,
      ownerWalletAddress,
    })
    this.setState({ display: 'announceSale' })
   };

  announceSaleContinue = (price: number, type: string) => {
    let { selectedWalletAddress } = this.state;
    let listing_price;
    switch (type) {
        case 'wax':
            listing_price = Number(price).toFixed(8) + " WAX";
            break;
        case 'usd':
            listing_price = Number(price).toFixed(2) + " USD";
            break;
        default:
            break;
    }

    if (!this.wax || !this.wax.api) {
        this.initOnboard();
    }
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    if (this.wax && this.wax.api && selectedWalletAddress && this.state.tokenID) { 
        this.wax.api.transact({
            actions: [
            {
              account: 'atomicmarket',
              name: 'announcesale',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                seller: selectedWalletAddress,
                asset_ids: [this.state.tokenID],
                listing_price,
                settlement_symbol: "8,WAX",
                maker_marketplace: "golazydotcom",
              },
            },
            {
                account: 'atomicassets',
                name: 'createoffer',
                authorization: [{
                  actor: selectedWalletAddress,
                  permission: 'active',
                }],
                data: {
                  sender: selectedWalletAddress,
                  recipient: 'atomicmarket',
                  sender_asset_ids: [this.state.tokenID],
                  recipient_asset_ids: [],
                  memo: 'sale',
                },
              },]
          }, {
            blocksBehind: 3,
            expireSeconds: 60,
          }).then(x => {
              console.log(x);
            this.setState({
                display: 'error',
                errorTitle: 'Success!',
                errorMessage: 'Your NFT is now for sale!  It will take roughly ~30-90 seconds for WAX to update with your sale. \n\nClick this to reload the page for updated details.'
            })
          }).catch(e => {
              console.log(e);
              this.setState({
                  display: 'error',
                  errorTitle: 'Error!',
                  errorMessage: `Something went wrong putting your NFT up for sale. The transaction was reverted and nothing happened.\n\n
                  What WAX says: \n\n${e}`
              })
          });
    }
  };

  handleCancelSale = async (saleID: string) => {
    this.setState({
      saleID
    })
    this.setState({ display: 'cancelSale' })
   };

  cancelSaleContinue = () => {
    if (!this.wax || !this.wax.api) {
        this.initOnboard();
    }
    let { selectedWalletAddress } = this.state;
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    if (this.wax && this.wax.api && selectedWalletAddress && this.state.saleID) { 
        this.wax.api.transact({
            actions: [
            {
              account: 'atomicmarket',
              name: 'cancelsale',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                sale_id: this.state.saleID
              },
            }]
          }, {
            blocksBehind: 3,
            expireSeconds: 60,
          }).then(x => {
              console.log(x);
            this.setState({
                display: 'error',
                errorTitle: 'Success!',
                errorMessage: 'Your NFT sale is now canceled!  It will take roughly ~30-90 seconds for WAX to update with your cancellation. \n\nClick this to reload the page for updated details.'
            })
          }).catch(e => {
              console.log(e);
              this.setState({
                  display: 'error',
                  errorTitle: 'Error!',
                  errorMessage: `Something went wrong cancelling your NFT sale. The transaction was reverted and nothing happened.\n\n
                  What WAX says: \n\n${e}`
              })
          });
    }
  };

// This only existed for Mark to do once to make his wallet the taker of the fees
 /* makeMarketplace = async (name: string) => {
    let { selectedWalletAddress } = this.state;
    if (!this.wax || !this.wax.api) {
      this.initOnboard();
    }
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
  if (this.wax && this.wax.api && selectedWalletAddress) { 
    const transactData = {
        actions: [
        {
          account: 'atomicmarket',
          name: 'regmarket',
          authorization: [{
            actor: selectedWalletAddress, //selectedWalletAddress,//'ipnqy.wam',
            permission: 'active',
          }],
          data: {
            creator: selectedWalletAddress,
            marketplace_name: name ? name : 'golazydotcom',
          },
        },
        ]
      };
      transactData && this.wax.api.transact(transactData, {
        blocksBehind: 3,
        expireSeconds: 60,
      }).then(x => {
          console.log(x);
        this.setState({
            display: 'error',
            errorTitle: 'Success!',
            errorMessage: `You've made the marketplace with name: ${name ? name : 'lazybymucban'}! \n\nThat's all I needed, thank you very much!`
        })
      }).catch(e => {
          console.log(e);
          this.setState({
              display: 'error',
              errorTitle: 'Error!',
              errorMessage: `Something went wrong making the marketplace. I've failed you Mark.\n\n
              What WAX says: \n\n${e}`
          })
      });
   };
  }*/

  handleBuyBundle = async (salesData: WaxSalesData) => {
    let { selectedWalletAddress } = this.state;
    if (!this.wax || !this.wax.api) {
      this.initOnboard();
  }
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
  if (this.wax && this.wax.api && selectedWalletAddress && salesData) { 
    const isWax = salesData.listing_symbol == 'WAX';
    const numPrice = isWax ? 
        (Number(salesData.listing_price) / 100000000).toFixed(8) :
        (Number(salesData.listing_price) / 100).toFixed(2); // TODO: Is this usd?
    const price = `${numPrice} ${salesData.listing_symbol}`;
    const transactData = {
        actions: [
        {
          account: 'atomicmarket',
          name: 'assertsale',
          authorization: [{
            actor: selectedWalletAddress,
            permission: 'active',
          }],
          data: {
            sale_id: salesData.sale_id,
            asset_ids_to_assert: salesData.assets.map(x => x.asset_id),
            listing_price_to_assert: price,
            settlement_symbol_to_assert: "8,WAX", 
          },
        },
        {
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: selectedWalletAddress,
              permission: 'active',
            }],
            data: {
              from: selectedWalletAddress,
              to: 'atomicmarket',
              quantity: `${Number(salesData.price.amount) / 100000000} WAX`,
              memo: 'deposit',
            },
          },
          {
            account: 'atomicmarket',
            name: 'purchasesale',
            authorization: [{
              actor: selectedWalletAddress,
              permission: 'active',
            }],
            data: {
              buyer: selectedWalletAddress,
              sale_id: salesData.sale_id,
              intended_delphi_median: isWax ? "0" : salesData.price.median,
              taker_marketplace: 'golazydotcom',
            },
          },]
      };
      transactData && this.wax.api.transact(transactData, {
        blocksBehind: 3,
        expireSeconds: 60,
      }).then(x => {
          console.log(x);
        this.setState({
            display: 'error',
            errorTitle: 'Success!',
            errorMessage: "You've purchased this NFT bundle! It will appear as purchased in ~30-90 seconds. \n\nClick this to reload the page for updated details."
        })
      }).catch(e => {
          console.log(e);
          this.setState({
              display: 'error',
              errorTitle: 'Error!',
              errorMessage: `Something went purchasing the NFT. The transaction was reverted and nothing happened.\n\n
              What WAX says: \n\n${e}`
          })
      });
   };
  }


  handleBuy = async (nft: WaxNFTData) => {
    this.setState({
      nft,
    })
    if (!this.wax || !this.wax.api) {
      this.initOnboard();
  }
    this.setState({ display: 'buy' })
   };

  buyContinue = () => {
    let {selectedWalletAddress, nft} = this.state;
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    if (this.wax && this.wax.api && selectedWalletAddress && nft && nft.salesData) { 
        const isWax = nft.salesData.listing_symbol == 'WAX';
        const numPrice = isWax ? 
            (Number(nft.salesData.listing_price) / 100000000).toFixed(8) :
            (Number(nft.salesData.listing_price) / 100).toFixed(2); // TODO: Is this usd?
        const price = `${numPrice} ${nft.salesData.listing_symbol}`;
        const transactData = {
            actions: [
            {
              account: 'atomicmarket',
              name: 'assertsale',
              authorization: [{
                actor: selectedWalletAddress,
                permission: 'active',
              }],
              data: {
                sale_id: nft.salesData.sale_id,
                asset_ids_to_assert: [nft.asset_id],
                listing_price_to_assert: price,
                // TODO: Settlement symbol for delphi
                settlement_symbol_to_assert: "8,WAX", 
              },
            },
            {
                account: 'eosio.token',
                name: 'transfer',
                authorization: [{
                  actor: selectedWalletAddress,
                  permission: 'active',
                }],
                data: {
                  from: selectedWalletAddress,
                  to: 'atomicmarket',
                  quantity: `${Number(nft.salesData.price.amount) / 100000000} WAX`,
                  memo: 'deposit',
                },
              },
              {
                account: 'atomicmarket',
                name: 'purchasesale',
                authorization: [{
                  actor: selectedWalletAddress,
                  permission: 'active',
                }],
                data: {
                  buyer: selectedWalletAddress,
                  sale_id: nft.salesData.sale_id,
                  intended_delphi_median: isWax ? "0" : nft.salesData.price.median,
                  taker_marketplace: 'golazydotcom',
                },
              },]
          };
          transactData && this.wax.api.transact(transactData, {
            blocksBehind: 3,
            expireSeconds: 60,
          }).then(x => {
              console.log(x);
            this.setState({
                display: 'error',
                errorTitle: 'Success!',
                errorMessage: "You've purchased this NFT! It will appear as purchased in ~30-90 seconds. \n\nClick this to reload the page for updated details."
            })
          }).catch(e => {
              console.log(e);
              this.setState({
                  display: 'error',
                  errorTitle: 'Error!',
                  errorMessage: `Something went purchasing the NFT. The transaction was reverted and nothing happened.\n\n
                  What WAX says: \n\n${e}`
              })
          });
    }
  };
  
  emptyDisplay = () => {
    if (this.state.errorMessage.indexOf('reload') > -1) {
       location.reload();
    } 
    this.setState({ display: '' })
  }

  render() {
    const { display } = this.state;
    let { selectedWalletAddress } = this.state;
    if (this.wax && this.wax.user && this.wax.user.account) {
      selectedWalletAddress = this.wax.user.account;
    }
    
    const announceSale = <AnnounceSale
        title={'List for Sale'}
        handleConfirm={this.announceSaleContinue}
        handleClose={this.emptyDisplay}
    />;

    const announceAuction = <AnnounceSale
        title={'List for Auction'}
        isAuction={true}
        handleConfirm={this.announceAuctionContinue}
        handleClose={this.emptyDisplay}
    />;

    var error = (
      <Error
        title={this.state.errorTitle}
        message={this.state.errorMessage}
        handleClose={this.emptyDisplay}
       // shareLink={this.state.shareLink}
      />
    )

    const cancelSale = <GenericModal
        title={'Cancel Sale'}
        message={'Would you like to cancel the sell order for this NFT?'}
        handleClose={this.emptyDisplay}
        handleSubmit={() => this.cancelSaleContinue()}
    />;

    const cancelAuction = <GenericModal
        title={'Cancel Auction'}
        message={'Would you like to cancel the auction for this NFT? It will return to your wallet if so.'}
        handleClose={this.emptyDisplay}
        handleSubmit={() => this.cancelAuctionContinue()}
    />;

    const buy = this.state.nft && <BuyWaxModal
        nft={this.state.nft}
        address={selectedWalletAddress!}
        handleClose={this.emptyDisplay}
        handleSubmit={() => this.buyContinue()}
    />;

    const auction = this.state.nft && <AuctionModal
        //nft={this.state.nft}
        nft={this.state.nft}
        address={selectedWalletAddress!}
        handleClose={this.emptyDisplay}
        handleSubmit={(price: number) => this.handleBidContinue(price)}
    />;

    return (
      <div>
        {display == 'announceSale' && announceSale}
        {display == 'announceAuction' && announceAuction}
        {display == 'cancelSale' && cancelSale}
        {display == 'cancelAuction' && cancelAuction}
        {display == 'buy' && buy}
        {display == 'bid' && auction}
        {display == 'error' && error}
      </div>
    )
  }
}

export default WaxMarketplace
