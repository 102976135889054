import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import Web3 from 'web3'
import { MAINNET_RPC_URL, walletPlugins } from './constants';
const injected: any = injectedModule()

export const doOnboard = () => {
    Onboard({
        appMetadata: {
          name: 'My App',
          icon: 'lazy.png',
          description: 'My app using Onboard'
        },
        chains: [
          {
            id: '0x1',
            token: 'ETH',
            label: 'Ethereum Mainnet',
            rpcUrl: MAINNET_RPC_URL
          }
        ],
          wallets: [injected, ...walletPlugins],
      })
}