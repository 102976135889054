import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkIcon from '@mui/icons-material/Link';
import ImageWSkeleton from '../ImageWSkeleton';
var sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;

export const ProfileDisplay = (props: any) => {
    const { profileData, username, setShowEditModal } = props;
    const imgStyle = {
        borderRadius: '50%',
        height: 140,
        width: 140,
        border: '1px solid black',
      };
    const videoUrl = profileData.videoUrl ? profileData.videoUrl : '';
    const isVideo =
    profileData.profilePictureUrl &&
    profileData.profilePictureUrl.toLowerCase().endsWith('.mp4');

    const isIPFS =
    profileData.profilePictureUrl &&
    profileData.profilePictureUrl.startsWith('Qm');

    const imageUrl = isIPFS
    ? `https://ipfs.io/ipfs/${profileData.profilePictureUrl}`
    : profileData.profilePictureUrl;


    return (
        <div style={{ width: 600, flexDirection: 'column', display: 'flex',alignItems: 'center', textAlign: 'center' }}>
              {setShowEditModal && <button type="submit" style={{ marginBottom: 50 }} onClick={() => setShowEditModal(true)}>
                Edit Profile
              </button>}
              {imageUrl && !isVideo && (
        <ImageWSkeleton style={imgStyle} imgSrc={imageUrl} width={imgStyle.width} height={imgStyle.height} />
      )}
      {imageUrl && isVideo && (
        <video style={imgStyle} className="video" playsInline muted autoPlay loop>
          <source src={imageUrl} />
        </video>
      )}
              <div className="user-label" style={{ fontWeight: 'bold', marginTop: 4}}>@{username}</div>
              <p style={{ marginTop: 10}}>{profileData.bio}</p>
              <div style={{ color: 'rgb(113, 118, 123)', display: 'flex', marginTop: 10, fontSize: 14,  }}>
                {profileData.twitter && profileData.twitter.length > 0 && ( 
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginRight: 8
                  }}>
                      <TwitterIcon />
                      <a href={sanitizeUrl(`https://www.twitter.com/${profileData.twitter}`)} target="_tab" style={{ marginLeft: 5 }}>Twitter</a>
                  </div>  
                )}
                 {profileData.facebook && profileData.facebook.length > 0 && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginRight: 8
                  }}>
                      <FacebookIcon />
                      <a href={sanitizeUrl(`https://www.facebook.com/${profileData.facebook}`)} target="_tab"  style={{ marginLeft: 5 }}>Facebook</a>
                  </div>  
                )}
                {profileData.personalUrl && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginRight: 8
                  }}>
                      <LinkIcon />
                      <a style={{ marginLeft: 5 }} href={sanitizeUrl(profileData.personalUrl)}  target="_tab" >{profileData.personalUrl.replace(/(^\w+:|^)\/\//, '')}</a>
                  </div>  
                )}
              </div>
          </div>
    )
}