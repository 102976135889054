import React from 'react'
import { inputStyle } from '../general/generalStyles'

class SellForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { listingPrice: 0.0 }

    this.handleUpdateListingPrice = this.handleUpdateListingPrice.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
  }

  handleContinue() {
    this.props.handleContinue(this.state.listingPrice)
  }

  handleUpdateListingPrice(e) {
    e.preventDefault()
    if (e.target.value === '') {
      this.setState({ listingPrice: 0.0 })
    } else {
      this.setState({ listingPrice: e.target.value })
    }
  }

  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">Sell NFT</span>
          <span className="description">
            You are about to sell <b>{this.props.name}</b>.
          </span>
          <span className="input-label">Listing Price (in ETH):</span>
          <input
            type="text"
            placeholder="Enter amount in ETH..."
            onChange={this.handleUpdateListingPrice}
            style={inputStyle}
          />
          <span className="price">
            Listing Price: {this.state.listingPrice} ETH
            <br />
            Service Fee: 2.5%
            <br />
            <br />
            You'll Receive: {this.state.listingPrice - this.state.listingPrice * 0.025} ETH
            <br />
            <br />
            NOTE: We use Opensea/Seaport for listing. It will request access to your entire collection. Do not be alarmed.
          </span>
          <button className="theme" onClick={this.handleContinue}>
            Continue
          </button>
          <button className="clear" onClick={this.props.handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    )
  }
}

export default SellForm
