import { useEffect, useState, useCallback } from "react"
import IntersectionObserverComponent from "./IntersectionObserverComponent";

interface VideoWSkeletonProps {
    videoSrc: string;
    imgClassName?: string;
    width?: number | string;
    height?: number | string;
    style?: Object;
    poster?: string
}

// This component should be used with absolute width and height as well as imgClassName
// width and height of 100% only works if you wrap this component in a parent div that dictates the dimensions but this is not recommended
// use imgClassName to give the className for the image
// style is an optional style object which can be used instead of imgClassName


export default function VideoWSkeleton({videoSrc, imgClassName = "video", width, height, style, poster}: VideoWSkeletonProps) {

    const [imageLoaded, setImageLoaded] = useState(false);
    const [beginLoad, setBeginLoad] = useState(false);

    const handleEnterViewport = () => {
        setBeginLoad(true);
        if (poster) {
            var image = new Image();
            image.onload = function () { setImageLoaded(true)};
            image.src = poster;
        } else {
            setImageLoaded(true);
        }
    };


    return (
        <div style={{ width, height}} className={imgClassName}>
            <IntersectionObserverComponent onEnterViewport={handleEnterViewport} />
            {imageLoaded === false && <div className={`${imgClassName} skeleton`} style={{width, height, ...style}}/>}
            <video className={imgClassName} poster={beginLoad ? poster : ""} playsInline muted autoPlay loop style={{ display: imageLoaded ? 'flex' : 'none', width: width || undefined, height: height || undefined, ...style}}>
              {beginLoad && <source src={videoSrc} />}
            </video>
        </div>
    )
}