
import { useEffect, useState } from 'react'
import ConfirmationModal from '../modals/ConfirmationModal'
import firebase from '../../firebase'
import { TezosWallet } from "@rarible/sdk-wallet"
import { createRaribleSdk } from "@rarible/sdk"
import { NetworkType as TezosNetwork } from "@airgap/beacon-sdk"
import { BeaconConnectionProvider, TezosProviderConnectionResult } from "@rarible/connector-beacon"
import {
    Connector,
    IConnectorStateProvider,
    ConnectionProvider,
    AbstractConnectionProvider,
} from "@rarible/connector"

export default function AddTezosWallet(props: any) {
  const [walletProvider, setWalletProvider] = useState()
  const [walletAddress, setWalletAddress] = useState<string | undefined>()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (walletAddress)addWallet(walletAddress);
  }, [walletAddress])

  function mapTezosWallet<O>(provider: AbstractConnectionProvider<O, TezosProviderConnectionResult>): ConnectionProvider<O, any> {
    return provider.map(async (state: any) => {
      const {
        beacon_provider: createBeaconProvider
      } = await import("@rarible/tezos-sdk/dist/providers/beacon/beacon_provider")
      const provider = await createBeaconProvider(state.wallet as any, state.toolkit)

      return {
            wallet: new TezosWallet(provider),
            address: state.address,
        }
    })
}
  
  const initializeOnboard = () => {
    const beacon = mapTezosWallet(new BeaconConnectionProvider({
        appName: "Lazy",
        accessNode: "https://mainnet.api.tez.ie",
        network: TezosNetwork.MAINNET
    }))
    
    //const raribleSdk = createRaribleSdk(wallet, "prod")
    const state: IConnectorStateProvider = {
        async getValue(): Promise<string | undefined> {
            const value = localStorage.getItem("saved_provider")
            return value ? value : undefined
        },
        async setValue(value: string | undefined): Promise<void> {
            localStorage.setItem("saved_provider", value || "")
        },
    }
    
    const connector = Connector
        .create(beacon, state) // use ConnectionState for store connector data (last connected provider, etc)
        .add(beacon)


    connector.connection.subscribe((con) => {
        console.log("connection: ")
        if (con.status === "connected") {
            const sdk = createRaribleSdk((con.connection as any).wallet, "prod")
            addWallet((con.connection as any).address)
            sdk.apis.item.getItemsByOwner({ owner: `TEZOS:${'tz1bBJfyn9KW57SC7oLrgT3EeCiCY1VMF5Pw'}`, blockchains: ['TEZOS' as any] })
                .then(x => x);
            // use sdk here
        }
    })

    connector.getOptions().then(x => connector.connect(x[0]))
  }

  const addWallet = (walletAddress: string) => {
    // check for existing wallet
    try {
      const db = firebase.firestore()
      const username = localStorage.getItem('username')!

      db.collection('users')
        .doc(username)
        .collection('wallets')
        .where('address', '==', walletAddress)
        .where('type', '==', 'Tezos')
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            alert('You have already added this wallet address to your Lazy account.')
          } else {
            // add to Firestore
            try {
              const db = firebase.firestore()
              const username = localStorage.getItem('username')!
              console.log(walletAddress)

              db.collection('users')
                .doc(username)
                .collection('wallets')
                .doc()
                .set({
                  type: 'Tezos',
                  address: walletAddress
                })
                .then(() => {
                  closeModal()
                  props.reloadWallets()
                })
                .catch((err) => {
                  console.log(err)
                })
            } catch (error) {
              console.log(error)
            }
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initializeOnboard()
  }, [])

  const closeModal = () => {
    setShowModal(false)
    props.reset()
  }

  const promptMessage = (
    <p>
      <b>Selected Wallet Address: </b> {walletAddress}
    </p>
  )
  return (
    <div>
      {showModal && (
        <ConfirmationModal
          title={'Connect ' + walletProvider + ' Wallet'}
          message={promptMessage}
          handleConfirm={addWallet}
          confirmLabel="Add Wallet"
          handleClose={closeModal}
          closeLabel="Cancel"
        />
      )}
    </div>
  )
}
