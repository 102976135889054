import React, { useEffect, useRef } from 'react';

const IntersectionObserverComponent = ({ onEnterViewport }) => {
  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver(callback, options);
    const target = targetRef.current;

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [onEnterViewport]);

  const callback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        onEnterViewport();
        observer.unobserve(entry.target);
      }
    });
  };

  return <div ref={targetRef} />;
};

export default IntersectionObserverComponent;