// TopShot Helper Functions
// Interacts directly with Cadence contracts
import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'
import axios from 'axios'
import { REACT_CORS } from '../../util/constants'

export const loadAllMoments = async (walletAddress, page) => {
  const limit = 50
  const offset = page * limit
  let resp = await loadMomentIDs(walletAddress)
  var momentIDs = resp.momentIDs
  momentIDs = momentIDs.slice(offset, limit + offset)

  let saleMomentIDs = resp.saleMomentIDs
  saleMomentIDs = saleMomentIDs.slice(offset, limit + offset)

  var NFTs = []

  var standardMoments = []
  var forSaleMoments = []
  if (momentIDs.length > 0) {
    standardMoments = await loadStandardMoments(walletAddress, momentIDs)
  }
  if (saleMomentIDs.length > 0) {
    forSaleMoments = await loadForSaleMoments(walletAddress, saleMomentIDs)
  }
  let allMoments = standardMoments.concat(forSaleMoments)

  for (const moment of allMoments) {
    let metadata = await loadMomentMetadata(moment)
    if (metadata) {
      NFTs.push({
        type: 'NBA Top Shot',
        data: metadata
      })
    }
  }

  return NFTs
}

const loadMomentIDs = async (walletAddress) => {
  const resp = await fcl.send([
    fcl.script`
  import TopShot from 0x0b2a3299cc857e29
  import Market from 0xc1e4f4f4c4257510
  pub struct TopshotAccount {
    pub var momentIDs: [UInt64]
    pub var saleMomentIDs: [UInt64]
    init(momentIDs: [UInt64], saleMomentIDs: [UInt64]) {
      self.momentIDs = momentIDs
      self.saleMomentIDs = saleMomentIDs
    }
  }
  pub fun main(): TopshotAccount {
  let acct = getAccount(0x${walletAddress})
  let collectionRef = acct.getCapability(/public/MomentCollection)!
                .borrow<&{TopShot.MomentCollectionPublic}>()!
  let momentIDs = collectionRef.getIDs()
  var saleMomentIDs: [UInt64] = []
  let salePublic = acct.getCapability(/public/topshotSaleCollection)
  if salePublic!.check<&{Market.SalePublic}>(){
    let saleCollectionRef = salePublic!.borrow<&{Market.SalePublic}>() ?? panic("Could not borrow capability from public collection")
    saleMomentIDs = saleCollectionRef.getIDs()  
  }
  return TopshotAccount(momentIDs: momentIDs, saleMomentIDs: saleMomentIDs)
}  `
  ])
  return fcl.decode(resp)
}

// load the moment as a standard moment
// if it fails, load it as a sale moment instead
export const loadMoment = async (walletAddress, momentID) => {
  return loadStandardMoments(walletAddress, [momentID])
    .then((moments) => {
      if (moments.length === 0) return
      return moments[0]
    })
    .catch((err) => {
      return loadForSaleMoments(walletAddress, [momentID]).then((moments) => {
        if (moments.length === 0) return
        return moments[0]
      })
    })
}

export const loadMomentMetadata = async (moment) => {
  let endpoint = REACT_CORS + 'https://public-api.nbatopshot.com/graphql'
  let data = {
    query: `query {
      getMintedMoment(momentId: "${moment.id}") {
        data {
          id
          assetPathPrefix
        }
      }
    }`,
    variables: {}
  }

  return await axios
    .post(endpoint, data)
    .then((res) => {
      if (!res.data.errors) {
        let imageURL =
          res.data.data.getMintedMoment.data.assetPathPrefix + 'Hero_2880_2880_Black.jpg'
        let videoURL =
          res.data.data.getMintedMoment.data.assetPathPrefix + 'Animated_1080_1920_Black.mp4'
        let momentData = {
          momentID: res.data.data.getMintedMoment.data.id,
          flowID: moment.id,
          playerName: moment.play.FullName,
          playCategory: moment.play.PlayCategory,
          dateOfMoment: formatDate(moment.play.DateOfMoment),
          setName: moment.setName,
          teamAtMoment: moment.play.TeamAtMoment,
          imageURL,
          videoURL
        }
        return momentData
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const formatDate = (date) => {
  let parts = date.split(' ')
  let d = new Date(Date.parse(parts[0] + 'T' + parts[1]))
  let month = d.getMonth() + 1
  let day = d.getDate()
  let year = d.getFullYear()
  return month + '/' + day + '/' + year
}

const loadStandardMoments = async (walletAddress, momentIDs) => {
  const resp = await fcl.send([
    fcl.script`
    import TopShot from 0x0b2a3299cc857e29
    pub struct Moment {
      pub var id: UInt64?
      pub var playId: UInt32?
      pub var meta: TopShot.MomentData?
      pub var play: {String: String}?
      pub var setId: UInt32?
      pub var setName: String?
      pub var serialNumber: UInt32?
      init(_ moment: &TopShot.NFT?) {
        self.id = moment?.id
        self.meta = moment?.data
        self.playId = moment?.data?.playID
        self.play = nil
        self.play = TopShot.getPlayMetaData(playID: self.playId!)
        self.setId = moment?.data?.setID
        self.setName = nil
        self.setName = TopShot.getSetName(setID: self.setId!)
        self.serialNumber = nil
        self.serialNumber = moment?.data?.serialNumber
      }
    }
    pub fun main(momentIDs: [UInt64]): [Moment] {
    let acct = getAccount(0x${walletAddress})
    let collectionRef = acct.getCapability(/public/MomentCollection)!
                  .borrow<&{TopShot.MomentCollectionPublic}>()!
      var moments: [Moment] = []
      for momentID in momentIDs {
        moments.append(Moment(collectionRef.borrowMoment(id: momentID)))
      }
      return moments
  }  `,
    fcl.args([fcl.arg(momentIDs, t.Array(t.UInt64))])
  ])
  return fcl.decode(resp)
}

const loadForSaleMoments = async (walletAddress, momentIDs) => {
  const resp = await fcl.send([
    fcl.script`
    import TopShot from 0x0b2a3299cc857e29
    import Market from 0xc1e4f4f4c4257510
    pub struct SaleMoment {
      pub var id: UInt64?
      pub var playId: UInt32?
      pub var meta: TopShot.MomentData?
      pub var play: {String: String}?
      pub var setId: UInt32?
      pub var setName: String?
      pub var serialNumber: UInt32?
      pub var price: UFix64
      init(moment: &TopShot.NFT?, price: UFix64) {
        self.id = moment?.id
        self.meta = moment?.data
        self.playId = moment?.data?.playID
        self.play = nil
        self.play = TopShot.getPlayMetaData(playID: self.playId!)
        self.setId = moment?.data?.setID
        self.setName = nil
        self.setName = TopShot.getSetName(setID: self.setId!)
        self.serialNumber = nil
        self.serialNumber = moment?.data?.serialNumber
        self.price = price
      }
    }
  
pub fun main(momentIDs: [UInt64]): [SaleMoment] {
  let acct = getAccount(0x${walletAddress})
        let collectionRef = acct.getCapability(/public/topshotSaleCollection)!.borrow<&{Market.SalePublic}>() ?? panic("Could not borrow capability from public collection")
        var saleMoments: [SaleMoment] = []
        for momentID in momentIDs {
          saleMoments.append(SaleMoment(moment: collectionRef.borrowMoment(id: momentID),price: collectionRef.getPrice(tokenID: momentID)!))
        }
      return saleMoments
      }`,
    fcl.args([fcl.arg(momentIDs, t.Array(t.UInt64))])
  ])
  return fcl.decode(resp)
}

export const loadTopShotPins = async (walletAddress, pins) => {
  let NFTs = []

  let moments = []
  for (const pin of pins) {
    let moment = await loadMoment(walletAddress, pin.tokenID)
    moments.push(moment)
  }

  // load metadata
  for (const moment of moments) {
    let metadata = await loadMomentMetadata(moment)
    let associatedPin = findAssociatedPin(pins, '0x0b2a3299cc857e29', moment.id)
    let nft = {
      type: 'NBA Top Shot',
      index: associatedPin.index,
      data: metadata,
      walletAddress,
      pinID: associatedPin.id,
      caption: associatedPin.caption
    }
    NFTs.push(nft)
  }

  return NFTs
}

const findAssociatedPin = (pins, contractAddress, tokenID) => {
  var foundPin = null
  pins.forEach((pin) => {
    if (pin.contractAddress === contractAddress && pin.tokenID === tokenID) {
      foundPin = pin
    }
  })
  return foundPin
}
