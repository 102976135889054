import { useEffect, useState } from 'react'
import ConfirmationModal from '../modals/ConfirmationModal'
import firebase from '../../firebase'
import * as waxjs from "@waxio/waxjs/dist";

export default function AddWaxWallet(props: any) {
  const [walletProvider, setWalletProvider] = useState()
  const [walletAddress, setWalletAddress] = useState<string | undefined>()
  const [showModal, setShowModal] = useState(false)

  let wax: waxjs.WaxJS;
  
  useEffect(() => {
    if (walletAddress)addWallet(walletAddress);
  }, [walletAddress])
  
  const initializeOnboard = () => {
    wax = new waxjs.WaxJS({
        rpcEndpoint: 'https://wax.greymass.com'
    });
    
    wax.login().then((walletAddress: string) => {
        console.log(walletAddress);
        setWalletAddress(walletAddress);
    });  
  }

  const addWallet = (walletAddress: string) => {
    // check for existing wallet
    try {
      const db = firebase.firestore()
      const username = localStorage.getItem('username')!

      db.collection('users')
        .doc(username)
        .collection('wallets')
        .where('address', '==', walletAddress)
        .where('type', '==', 'Wax')
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            alert('You have already added this wallet address to your Lazy account.')
          } else {
            // add to Firestore
            try {
              const db = firebase.firestore()
              const username = localStorage.getItem('username')!
              console.log(walletAddress)

              db.collection('users')
                .doc(username)
                .collection('wallets')
                .doc()
                .set({
                  type: 'Wax',
                  address: walletAddress
                })
                .then(() => {
                  closeModal()
                  props.reloadWallets()
                })
                .catch((err) => {
                  console.log(err)
                })
            } catch (error) {
              console.log(error)
            }
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initializeOnboard()
  }, [])

  const closeModal = () => {
    setShowModal(false)
    props.reset()
  }

  const promptMessage = (
    <p>
      <b>Selected Wallet Address: </b> {walletAddress}
    </p>
  )
  return (
    <div>
      {showModal && (
        <ConfirmationModal
          title={'Connect ' + walletProvider + ' Wallet'}
          message={promptMessage}
          handleConfirm={addWallet}
          confirmLabel="Add Wallet"
          handleClose={closeModal}
          closeLabel="Cancel"
        />
      )}
    </div>
  )
}
