import React, { useMemo, useState } from 'react'
import firebase from '../../firebase'
import ImageWSkeleton from '../ImageWSkeleton'
import VideoWSkeleton from '../VideoWSkeleton'

export default function SolanaNFT(props) {
  // Update the properties destructured from props.data
  const { name, imageUrl, tokenAddress, externalUrl, description, animationUrl } = props.data
  const { walletAddress } = props
  const [menuShown, setMenuShown] = useState(false)
  const [pinned, setPinned] = useState(props.pinned)

  const nftClass = 'clickable NFT'
  const media = useMemo(() => {
    // Update the conditions to use animationUrl instead of animation_url
    if (animationUrl) {
      return <VideoWSkeleton width={320} height={320} poster={imageUrl} videoSrc={animationUrl} imgClassName='video' />      
    } else {
      return <ImageWSkeleton imgClassName='image' width={320} height={320} imgSrc={imageUrl} />
    }
  }, [imageUrl, externalUrl, animationUrl])

  const checkIfAuthor = () => {
    const username = localStorage.getItem('username')
    if (username && props.username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const isAdmin = () => {
    if (firebase.auth().currentUser) {
      if (firebase.auth().currentUser.uid === 'hhoXubahBuWi9o4ifrKjHIVZe843') {
        return true
      }
    }
    return false
  }

  const feature = () => {
    setMenuShown(false)
    if (window.confirm('Are you sure you want to feature this NFT?')) {
      const db = firebase.firestore()
      db.collection('featured')
        .doc()
        .set({
          type: 'Solana',
          wallet_address: walletAddress,
          contract_address: tokenAddress,
          created_at: new Date(),
          username: props.username.toLowerCase()
        })
        .then(() => {
          alert('Featured successfully!')
        })
    }
  }

  const loadMenu = () => {
    let _menu
    let _menuButton
    if (menuShown) {
      const viewerIsAuthor = checkIfAuthor()
      const viewerIsAdmin = isAdmin()
      _menu = (
        <div className="NFT-menu" style={{ display: 'inline-block' }}>
          {viewerIsAuthor && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleHide}>
              Hide
            </div>
          )}
          {viewerIsAuthor && props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnhide}>
              Unhide
            </div>
          )}

          {viewerIsAuthor && !pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handlePin}>
              Pin
            </div>
          )}
          {viewerIsAuthor && pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnpin}>
              Unpin
            </div>
          )}
          {viewerIsAdmin && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={feature}>
              Feature
            </div>
          )}
          {viewerIsAuthor && <hr />}
          <div className="NFT-menu-option" onClick={() => viewOnRabbithole('rabbithole')}>
            Rabbithole
          </div>
          <div className="NFT-menu-option" onClick={() => viewOnRabbithole('bizarroworld')}>
            Bizarroworld
          </div>
          <div className="NFT-menu-option" onClick={viewOnSolExplorer}>
            View on SolExplorer
          </div>
          {viewerIsAuthor && <div className="NFT-menu-option" onClick={() => props.functions.setAsProfilePicture(image, animation_url)}>
            Set as Profile Picture
          </div>}
        </div>
      )
      _menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-close-icon.png')" }}
        ></button>
      )
    } else {
      _menu = <div className="NFT-menu" style={{ display: 'none' }}></div>
      _menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-more-icon.png')" }}
        ></button>
      )
    }
    return [_menu, _menuButton]
  }

  const viewOnSolExplorer = () => {
    setMenuShown(false)

    // log firebase event

    firebase.analytics().logEvent('solexplorer_click', {
      contract_address: '_' + tokenAddress,
      wallet_address: '_' + walletAddress
    })
    window.open(`https://explorer.solana.com/address/${tokenAddress}`)
  }

  const viewOnRabbithole = (to) => {
    setMenuShown(false)

    window.location.href = `/${to}` + "?" + new URLSearchParams({
      tokenID: tokenAddress, 
      walletAddress: walletAddress,
      url: btoa(image),
      chain: 'solana'
    }).toString()
  };

  const showOptions = () => {
    setMenuShown(!menuShown)
  }

  const handleUnpin = async () => {
    setMenuShown(false)
    props.functions.handleUnpin(props.pinID).then(
      (value) => {
        setPinned(false)
      },
      (reason) => {
        // handleUnpin was not successful
      }
    )
  }

  const handlePin = async () => {
    setMenuShown(false)
    props.functions.handlePin(walletAddress, tokenAddress, 0, 'Solana').then(
      (value) => {
        setPinned(true)
      },
      (reason) => {
        // handlePin was not successful
      }
    )
  }

  const handleHide = async () => {
    setMenuShown(false)
    props.functions.handleHide(walletAddress, tokenAddress, 0, 'Solana').then(
      (value) => {
        // handleHide was successful
      },
      (reason) => {
        // handleHide was not successful
      }
    )
  }

  const handleUnhide = async () => {
    setMenuShown(false)
    props.functions.handleUnhide(props.pinID).then(
      (value) => {
        // handleUnhide was successful
      },
      (reason) => {
        // handleUnhide was not successful
      }
    )
  }

  const viewDetail = () => {
    if (props.isClickable !== false) {
      setMenuShown(false)
      document.location.href = '/solana/' + walletAddress + '/' + tokenAddress
    }
  }
  const [menu, menuButton] = loadMenu()

  return props.data ? (
    <div className={nftClass}>
      {menuButton}
      {menu}
      <div onClick={viewDetail}>
        {media}
        <div className="info">
          <div>
            <span className="name">{name}</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
