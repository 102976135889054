import { useEffect, useState } from 'react';
import { loadSolanaNFT } from './SolanaHelper';
import NavBar from '../general/NavBar';
import firebase from '../../firebase';
import '../general/NFTDetail.css';
import VideoWSkeleton from '../VideoWSkeleton'
import ImageWSkeleton from '../ImageWSkeleton'

export default function SolanaDetail(props) {
  let walletAddress = props.match.params.walletAddress;
  let contractAddress = props.match.params.contractAddress;

  const [NFT, setNFT] = useState(null);
  const [animationURL, setAnimationURL] = useState('');
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const nftResult = await loadSolanaNFT(walletAddress, contractAddress);
      setNFT(nftResult);
  
      // Set the imageURL and animationURL state variables
      let _imageURL = nftResult.imageUrl;
  
      if (_imageURL.toLowerCase().endsWith('.mp4')) {
        setAnimationURL(nftResult.imageUrl);
      } else {
        setImageURL(_imageURL);
      }
    };
  
    fetchData();
  }, []);
  

  const loadAnimationURL = () => {
    if (Object.keys(NFT).length === 0) {
      return null
    }

    // Update the animation_url property
    return NFT.animationUrl
  }

  const loadImageURL = () => {
    if (Object.keys(NFT).length === 0) {
      return null
    }

    let _imageURL = NFT.imageUrl

    if (_imageURL.toLowerCase().endsWith('.mp4')) {
      setAnimationURL(NFT.imageUrl)
      //_imageURL = assets[0].image_thumbnailq_url;
    }
    if (
      (_imageURL === null || _imageURL === undefined) &&
      (animationURL === null || animationURL === undefined)
    ) {
      _imageURL = '/empty-media.png'
    }
    return _imageURL
  }

  const loadMedia = () => {
    if (animationURL) {
      return <VideoWSkeleton videoSrc={animationURL} poster={imageURL} imgClassName='detail-video' />
    } else if (imageURL) {
      return <ImageWSkeleton imgClassName='detail-image' imgSrc={imageURL} />
    }
    return <>Loading...</>
  }

  const loadSolExplorerURL = () => {
    return `https://explorer.solana.com/address/${contractAddress}`
  }

  const viewSolExplorer = (solExplorerURL) => {
    firebase.analytics().logEvent('solexplorer_click', {
      contract_address: '_' + contractAddress,
      wallet_address: '_' + walletAddress
    })
    window.open(solExplorerURL)
  }

  let media = loadMedia()
  let solExplorerURL = loadSolExplorerURL()

  // check if processing
  if (NFT && NFT.name === null && NFT.description === null && NFT.image_url === null) {
    setImageURL('/empty-media.png')
  }


  useEffect(() => {
    if (window.__sharethis__ && props) {
      const address = props.match.params.walletAddress + '/' + props.match.params.contractAddress
      window.__sharethis__.load('inline-share-buttons', {
        id: 'my-inline-buttons',
        alignment: 'center',
        color: 'white',
        enabled: true,
        font_size: 16,
        padding: 12,
        radius: 8,
        networks: ['twitter', 'facebook', 'linkedin', 'email', 'whatsapp'],
        size: 40,
        show_mobile_buttons: true,
        url: 'https://lazy.com/solana/' + address,
        title:
          'Check out this NFT at lazy.com/solana/' +
          address +
          '. The lazy way to show off your NFTs!',
        subject: 'Check out this NFT on Lazy!',
        message:
          'Check out this NFT at lazy.com/solana/' +
          address +
          '. The lazy way to show off your NFTs!'
      })
    }
  }, [])

  return (
    <div>
      <NavBar />
      <div className="nft-detail-media-container">{media}</div>
      <div className="container detail-padding">
        {NFT ? (
          <>
            <span className="detail-name">{NFT.name || 'NFT Still Processing...'}</span>
            <span className="detail-description">{NFT.description || 'Check back later!'}</span>
            <center>
              <button
                onClick={() => viewSolExplorer(solExplorerURL)}
                className="clear"
                style={{ marginBottom: '50px', marginTop: '50px' }}
              >
                View on Sol Explorer
              </button>
            </center>
            <div id="my-inline-buttons"></div>
          </>
        ) : (
          <span>Loading...</span>
        )}
      </div>
    </div>
  );
  
}
