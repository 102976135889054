import React from 'react'
import '../general/NFT.css'
import axios from 'axios'
import firebase from '../../firebase'
import { OpenSeaPort, Network } from 'opensea-js'
import { OrderSide } from 'opensea-js/lib/types'

require('dotenv').config()

class EthereumNFT extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      caption: null,
      pinned: props.pinned,
      listAction: null,
      isAdmin: false,
      menuShown: false
    }
    this.viewNFTDetail = this.viewNFTDetail.bind(this)
    this.handleAddPin = this.handleAddPin.bind(this)
    this.handleRemovePin = this.handleRemovePin.bind(this)
    this.handleBuy = this.handleBuy.bind(this)
    this.handleSell = this.handleSell.bind(this)
    this.handleUnlist = this.handleUnlist.bind(this)
    this.feature = this.feature.bind(this)
    this.showOptions = this.showOptions.bind(this)
    this.viewRabbitHole = this.viewRabbitHole.bind(this)
    this.viewBizarroWorld = this.viewBizarroWorld.bind(this)
    this.viewOnOpenSea = this.viewOnOpenSea.bind(this)
  }

  viewNFTDetail() {
    if (this.state.menuShown) {
      this.setState({ menuShown: false })
    } else {
      if (this.props.onClickUrl) {
        document.location.href = this.props.onClickUrl
      } else {
        document.location.href =
          '/ethereum/' +
          this.props.walletAddress +
          '/' +
          this.props.contractAddress +
          '/' +
          this.props.tokenID
      }
    }
  }

  handleAddPin() {
    this.setState({ menuShown: false })
    try {
      const db = firebase.firestore()
      const username = localStorage.getItem('username')

      db.collection('users')
        .doc(username)
        .collection('pinned')
        .get()
        .then((snapshot) => {
          if (snapshot.docs.length >= 9) {
            alert('You can only pin 9 NFTs.')
          } else {
            var caption = prompt('Enter a caption: ')
            if (caption !== null) {
              // add to Firestore
              try {
                const db = firebase.firestore()
                const username = localStorage.getItem('username')

                db.collection('users')
                  .doc(username)
                  .collection('pinned')
                  .doc()
                  .set({
                    created_at: new Date(),
                    walletAddress: this.props.walletAddress,
                    contractAddress: this.props.contractAddress,
                    tokenID: this.props.tokenID,
                    caption: caption
                  })
                  .then(() => {
                    this.setState({ pinned: true })
                    this.props.reloadPinnedNFTs()
                    window.alert('NFT pinned successfully!')
                  })
                  .catch((err) => {
                    window.alert(err)
                  })
              } catch (error) {
                alert(error)
              }
            }
          }
        })
    } catch (error) {
      alert(error)
    }
  }

  handleRemovePin() {
    this.setState({ menuShown: false })
    if (window.confirm('Are you sure you want to unpin this NFT?')) {
      // remove from Firestore
      try {
        const db = firebase.firestore()
        const username = localStorage.getItem('username')

        db.collection('users')
          .doc(username)
          .collection('pinned')
          .doc(this.props.id)
          .delete()
          .then(() => {
            this.setState({ pinned: false })
            this.props.removePin(this.props.id)
          })
          .catch((err) => {
            window.alert(err)
          })
      } catch (error) {
        alert(error)
      }
    }
  }

  handleBuy() {
    this.setState({ menuShown: false })
    this.props.handleBuy(
      this.props.contractAddress,
      this.props.tokenID,
      this.props.name,
      this.props.currentPrice,
      this.props.walletAddress,
      this.props.paymentToken
    )
  }

  handleSell() {
    this.setState({ menuShown: false })
    this.props.handleSell(
      this.props.contractAddress,
      this.props.tokenID,
      this.props.name,
      this.props.schemaName,
      this.props.walletAddress
    )
  }

  handleUnlist() {
    this.setState({ menuShown: false })
    this.props.handleUnlist(
      this.props.contractAddress,
      this.props.tokenID,
      this.props.name,
      this.props.currentPrice,
      this.props.walletAddress
    )
  }

  async getOrders() {
    // initialize Seaport
    const provider = window.ethereum
    var network = null
    if (process.env.REACT_APP_TESTING) {
      network = Network.Rinkeby
    } else {
      network = Network.main
    }
    const seaport = new OpenSeaPort(provider, {
      networkName: network,
      apiKey: process.env.REACT_APP_OPENSEA_API_KEY
    })

    const { orders, count } = await seaport.api.getOrders({
      asset_contract_address: this.props.contractAddress,
      token_id: this.props.tokenID,
      side: OrderSide.Sell
    })
    return orders
  }

  async getOrdersAPI() {
    const headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
    try {
      var endpointURL = null
      if (process.env.REACT_APP_TESTING) {
        endpointURL =
          'https://rinkeby-api.opensea.io/wyvern/v1/orders?asset_contract_address=' +
          this.props.contractAddress +
          '&side=1&token_id=' +
          this.props.tokenID
      } else {
        endpointURL =
          'https://api.opensea.io/wyvern/v1/orders/asset_contract_address' +
          this.props.contractAddress +
          '&side=1&token_id=' +
          this.props.tokenID
      }
      /*axios.get(endpointURL, {headers: headers}).then(result => {
              console.log(result);
            })*/
    } catch (error) {
      console.log(error)
    }
    //https://rinkeby-api.opensea.io/wyvern/v1/orders/?asset_contract_address=0xee45b41d1ac24e9a620169994deb22739f64f231&limit=20&offset=0&side=1&token_id=22041327321906385311117948295394781693867512592250405501280281122540124897290
  }

  loadListAction() {
    // load list action
    var isAuthor = false
    const username = localStorage.getItem('username')
    if (username) {
      const profileUsername = this.props.username
      isAuthor = username.toLowerCase() === profileUsername.toLowerCase()
    }

    const ordersExist = this.props.currentPrice !== null
    if (isAuthor) {
      if (this.props.schemaName === 'ERC721') {
        if (ordersExist) {
          this.setState({ listAction: 'unlist' })
        } else {
          this.setState({ listAction: 'sell' })
        }
      } else if (this.props.schemaName === 'ERC1155') {
        if (ordersExist) {
          if (this.props.listedByUser) {
            this.setState({ listAction: 'unlist' })
          } else {
            // get orders
            const orders = this.getOrdersAPI()
          }
        } else {
          this.setState({ listAction: 'sell' })
        }
      }
    } else {
      // not the author
      if (this.props.schemaName === 'ERC721') {
        if (ordersExist) {
          this.setState({ listAction: 'buy' })
        }
      } else if (this.props.schemaName === 'ERC1155') {
        if (ordersExist) {
          if (this.props.listedByUser) {
            this.setState({ listAction: 'buy' })
          } else {
            // get orders
          }
        }
      }
    }
  }

  checkIfAdmin() {
    if (firebase.auth().currentUser) {
      if (firebase.auth().currentUser.uid === 'hhoXubahBuWi9o4ifrKjHIVZe843') {
        this.setState({ isAdmin: true })
      }
    }
  }

  componentDidMount() {
    this.loadListAction()
    this.checkIfAdmin()
  }

  componentDidUpdate(pastProps) {
    if (pastProps !== this.props) {
      this.loadListAction()
    }
  }

  feature() {
    this.setState({ menuShown: false })
    if (window.confirm('Are you sure you want to feature this NFT?')) {
      const db = firebase.firestore()
      db.collection('featured')
        .doc()
        .set({
          type: 'Ethereum',
          wallet_address: this.props.walletAddress,
          contract_address: this.props.contractAddress,
          token_id: this.props.tokenID,
          created_at: new Date(),
          username: this.props.username.toLowerCase()
        })
        .then(() => {
          alert('Featured successfully!')
        })
    }
  }

  showOptions() {
    this.setState({ menuShown: !this.state.menuShown })
  }

  viewRabbitHole() {
    this.setState({ menuShown: false })
    document.location.href =
      '/rabbithole/ethereum/' +
      this.props.contractAddress +
      '/' +
      this.props.tokenID
  }

  viewBizarroWorld() {
    this.setState({ menuShown: false })
    document.location.href =
      '/bizarroworld/ethereum/' +
      this.props.contractAddress +
      '/' +
      this.props.tokenID
  }

  viewOnOpenSea = () => {
    this.setState({ menuShown: false })
    // log firebase event
    firebase.analytics().logEvent('opensea_click', {
      contract_address: '_' + this.props.contractAddress.toLowerCase(),
      token_id: '_' + this.props.tokenID.toLowerCase(),
      wallet_address: '_' + this.props.walletAddress.toLowerCase()
    })
    window.open(
      'https://opensea.io/assets/ethereum/' +
        this.props.contractAddress +
        '/' +
        this.props.tokenID +
        '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
    )
  }

  viewOnEtherscan() {
    this.setState({ menuShown: false })
    // log firebase event
    firebase.analytics().logEvent('etherscan_click', {
      contract_address: '_' + this.props.contractAddress.toLowerCase(),
      token_id: '_' + this.props.tokenID.toLowerCase(),
      wallet_address: '_' + this.props.walletAddress.toLowerCase()
    })
    window.open(
      'https://etherscan.io/nft/' +
        this.props.contractAddress +
        '/' +
        this.props.tokenID 
    )
  }

  render() {
    const username = localStorage.getItem('username')
    var viewerIsAuthor = false
    var nftClass = 'NFT'
    if (username) {
      const profileUsername = this.props.username
      viewerIsAuthor = username.toLowerCase() === profileUsername.toLowerCase()
    }

    if (viewerIsAuthor) {
      nftClass = 'NFT-own'
    }

    var media = null
    if (this.props.animation) {
      media = (
        <video className="video" poster={this.props.image} playsInline muted autoPlay loop>
          <source src={this.props.animation} />
        </video>
      )
    } else {
      media = <img className="image" src={this.props.image} alt="" />
    }

    var name = this.props.name ? this.props.name : `Token #${this.props.tokenNo}`
    if (name) {
      if (name.length > 40) {
        name = name.substring(0, 40) + '...'
      }
    }

    // pin actions
    var pinAction = null
    if (this.state.pinned) {
      pinAction = (
        <center>
          <button
            onClick={this.handleRemovePin}
            style={{ marginTop: '15px', marginBottom: '15px' }}
          >
            Unpin
          </button>
        </center>
      )
    } else {
      pinAction = (
        <center>
          <button
            className="clear"
            onClick={this.handleAddPin}
            style={{ marginTop: '15px', marginBottom: '15px' }}
          >
            Pin
          </button>
        </center>
      )
    }

    var caption = '"' + this.props.caption + '"'
    // list actions - Sell or Unlist
    var listAction = null
    const sellButton = (
      <center>
        <button className="clear" onClick={this.handleSell} style={{ marginBottom: '15px' }}>
          Sell
        </button>
      </center>
    )
    const unlistButton = (
      <center>
        <button className="clear" onClick={this.handleUnlist} style={{ marginBottom: '15px' }}>
          Unlist
        </button>
      </center>
    )
    const buyButton = (
      <center>
        <button
          className="clear"
          onClick={this.handleBuy}
          style={{ marginTop: '15px', marginBottom: '15px' }}
        >
          Buy for {parseFloat((this.props.currentPrice / 1000000000000000000).toFixed(2))}{' '}
          {this.props.paymentToken}
        </button>
      </center>
    )
    const featureButton = (
      <center>
        <button
          className="clear"
          onClick={this.feature}
          style={{ marginTop: '15px', marginBottom: '15px' }}
        >
          Feature
        </button>
      </center>
    )

    if (this.state.listAction === 'sell') {
      listAction = sellButton
    } else if (this.state.listAction === 'unlist') {
      listAction = unlistButton
    } else if (this.state.listAction === 'buy') {
      if (this.props.currentPrice) {
        listAction = buyButton
      }
    }

    let menu
    let menuButton
    if (this.state.menuShown) {
      menu = (
        <div className="NFT-menu" style={{ display: 'inline-block' }}>
          {this.state.pinned && viewerIsAuthor && (
            <div className="NFT-menu-option" onClick={this.handleRemovePin}>
              Unpin
            </div>
          )}
          {!this.state.pinned && viewerIsAuthor && (
            <div className="NFT-menu-option" onClick={this.handleAddPin}>
              Pin
            </div>
          )}
          {this.state.listAction === 'sell' && (
            <div className="NFT-menu-option" onClick={this.handleSell}>
              Sell
            </div>
          )}
          {this.state.listAction === 'unlist' && (
            <div className="NFT-menu-option" onClick={this.handleUnlist}>
              Unlist
            </div>
          )}
          {this.state.isAdmin && (
            <div className="NFT-menu-option" onClick={this.feature}>
              Feature
            </div>
          )}
          {viewerIsAuthor && <hr />}
          <div className="NFT-menu-option" onClick={this.viewRabbitHole}>
            RabbitHole
          </div>
          <div className="NFT-menu-option" onClick={this.viewBizarroWorld}>
            BizarroWorld
          </div>
          <div className="NFT-menu-option" onClick={this.viewOnOpenSea}>
            View on OpenSea
          </div>
          <div className="NFT-menu-option" onClick={this.viewOnEtherscan}>
            View on Etherscan
          </div>
        </div>
      )
      menuButton = (
        <button
          className="options-btn"
          onClick={this.showOptions}
          style={{ backgroundImage: "url('/menu-close-icon.png')" }}
        ></button>
      )
    } else {
      menu = <div className="NFT-menu" style={{ display: 'none' }}></div>
      menuButton = (
        <button
          className="options-btn"
          onClick={this.showOptions}
          style={{ backgroundImage: "url('/menu-more-icon.png')" }}
        ></button>
      )
    }

    return (
      <div className={nftClass} style={{ cursor: 'pointer'}}>
        {menuButton}
        {menu}
        <div onClick={this.viewNFTDetail}>
          {media}
          <div className="info">
            <div>
              <span className="name">{name}</span>
              {this.props.caption && caption}
            </div>
          </div>
        </div>
        {this.state.listAction === 'buy' && listAction}
      </div>
    )
  }
}

export default EthereumNFT
