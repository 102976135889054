import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/analytics'

const config = {
  apiKey: 'AIzaSyCJHQIXTBYqAvFhExkPq_ws-xpncehp53g',
  authDomain: 'nft-tree-50435.firebaseapp.com',
  projectId: 'nft-tree-50435',
  storageBucket: 'nft-tree-50435.appspot.com',
  messagingSenderId: '711692696925',
  appId: '1:711692696925:web:4490dd53ae4eeff3bafcb4',
  measurementId: 'G-XCCW4J7928'
}
firebase.initializeApp(config)
firebase.setLogLevel('silent')
firebase.analytics()

export default firebase
