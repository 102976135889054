import React from 'react'
import NavBar from '../general/NavBar'
import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'
import axios from 'axios'
import firebase from '../../firebase'
import { REACT_CORS } from '../../util/constants'
import VideoWSkeleton from '../VideoWSkeleton'

class TopShotDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      NFT: {
        id: null,
        playerName: null,
        playCategory: null,
        dateOfMoment: null,
        setName: null,
        teamAtMoment: null
      }
    }
  }

  componentDidMount() {
    this.loadNFT()
  }

  formatDate(date) {
    let parts = date.split(' ')
    var d = new Date(Date.parse(parts[0] + 'T' + parts[1]))
    var month = d.getMonth() + 1
    var day = d.getDate()
    var year = d.getFullYear()
    return month + '/' + day + '/' + year
  }

  async loadNFT() {
    let walletAddress = this.props.match.params.walletAddress
    let flowID = this.props.match.params.flowID

    await this.loadMoment(walletAddress, flowID)
      .then((moments) => {
        let moment = moments[0]
        axios
          .post(REACT_CORS + 'https://public-api.nbatopshot.com/graphql', {
            query: `query {
                            getMintedMoment(momentId: "${moment.id}") {
                                data {
                                    id
                                    assetPathPrefix
                                }
                            }
                        }`,
            variables: {}
          })
          .then((res) => {
            let id = res.data.data.getMintedMoment.data.id
            let flow_id = moment.id
            let playerName = moment.play.FullName
            let playCategory = moment.play.PlayCategory
            let dateOfMoment = this.formatDate(moment.play.DateOfMoment)
            let setName = moment.setName
            let teamAtMoment = moment.play.TeamAtMoment
            let assetPathPrefix = res.data.data.getMintedMoment.data.assetPathPrefix
            this.setState({
              NFT: {
                id,
                flow_id,
                playerName,
                playCategory,
                dateOfMoment,
                setName,
                teamAtMoment,
                assetPathPrefix
              }
            })
          })
      })
      .catch(() => {
        // not found, try loading sale moments
        this.loadSaleMoment(walletAddress, flowID).then((moments) => {
          let moment = moments[0]
          axios
            .post(REACT_CORS + 'https://public-api.nbatopshot.com/graphql', {
              query: `query {
                                getMintedMoment(momentId: "${moment.id}") {
                                    data {
                                        id
                                        assetPathPrefix
                                    }
                                }
                            }`,
              variables: {}
            })
            .then((res) => {
              let id = res.data.data.getMintedMoment.data.id
              let flow_id = moment.id
              let playerName = moment.play.FullName
              let playCategory = moment.play.PlayCategory
              let dateOfMoment = this.formatDate(moment.play.DateOfMoment)
              let setName = moment.setName
              let teamAtMoment = moment.play.TeamAtMoment
              let assetPathPrefix = res.data.data.getMintedMoment.data.assetPathPrefix
              this.setState({
                NFT: {
                  id,
                  flow_id,
                  playerName,
                  playCategory,
                  dateOfMoment,
                  setName,
                  teamAtMoment,
                  assetPathPrefix
                }
              })
            })
        })
      })
  }

  async loadMoment(walletAddress, momentID) {
    let momentIDs = [parseInt(momentID)]
    const resp = await fcl.send([
      fcl.script`
            import TopShot from 0x0b2a3299cc857e29
            pub struct Moment {
                pub var id: UInt64?
                pub var playId: UInt32?
                pub var meta: TopShot.MomentData?
                pub var play: {String: String}?
                pub var setId: UInt32?
                pub var setName: String?
                pub var serialNumber: UInt32?
          
                init(_ moment: &TopShot.NFT?) {
                    self.id = moment?.id
                    self.meta = moment?.data
                    self.playId = moment?.data?.playID
                    self.play = nil
                    self.play = TopShot.getPlayMetaData(playID: self.playId!)
                    self.setId = moment?.data?.setID
                    self.setName = nil
                    self.setName = TopShot.getSetName(setID: self.setId!)
                    self.serialNumber = nil
                    self.serialNumber = moment?.data?.serialNumber
                }
            }
        
            pub fun main(momentIDs: [UInt64]): [Moment] {
                let acct = getAccount(0x${walletAddress})
                let collectionRef = acct.getCapability(/public/MomentCollection)!.borrow<&{TopShot.MomentCollectionPublic}>()!
                var moments: [Moment] = []
                for momentID in momentIDs {
                    moments.append(Moment(collectionRef.borrowMoment(id: momentID)))
                }
                return moments
            }  `,
      fcl.args([fcl.arg(momentIDs, t.Array(t.UInt64))])
    ])
    return fcl.decode(resp)
  }

  async loadSaleMoment(walletAddress, momentID) {
    let momentIDs = [parseInt(momentID)]
    const resp = await fcl.send([
      fcl.script`
          import TopShot from 0x0b2a3299cc857e29
          import Market from 0xc1e4f4f4c4257510
          pub struct SaleMoment {
            pub var id: UInt64?
            pub var playId: UInt32?
            pub var meta: TopShot.MomentData?
            pub var play: {String: String}?
            pub var setId: UInt32?
            pub var setName: String?
            pub var serialNumber: UInt32?
            pub var price: UFix64
            init(moment: &TopShot.NFT?, price: UFix64) {
              self.id = moment?.id
              self.meta = moment?.data
              self.playId = moment?.data?.playID
              self.play = nil
              self.play = TopShot.getPlayMetaData(playID: self.playId!)
              self.setId = moment?.data?.setID
              self.setName = nil
              self.setName = TopShot.getSetName(setID: self.setId!)
              self.serialNumber = nil
              self.serialNumber = moment?.data?.serialNumber
              self.price = price
            }
          }
        
      pub fun main(momentIDs: [UInt64]): [SaleMoment] {
        let acct = getAccount(0x${walletAddress})
              let collectionRef = acct.getCapability(/public/topshotSaleCollection)!.borrow<&{Market.SalePublic}>() ?? panic("Could not borrow capability from public collection")
              var saleMoments: [SaleMoment] = []
              for momentID in momentIDs {
                saleMoments.append(SaleMoment(moment: collectionRef.borrowMoment(id: momentID),price: collectionRef.getPrice(tokenID: momentID)!))
              }
            return saleMoments
            }`,
      fcl.args([fcl.arg(momentIDs, t.Array(t.UInt64))])
    ])
    return fcl.decode(resp)
  }

  viewOnTopShots() {
    firebase.analytics().logEvent('topshot_click', {
      flow_id: '_' + this.state.NFT.flow_id,
      moment_id: '_' + this.state.NFT.id,
      wallet_address: '_' + this.props.match.params.walletAddress.toLowerCase()
    })

    window.open('https://nbatopshot.com/moment/' + this.state.NFT.id, '_blank')
  }

  render() {
    let description =
      this.state.NFT.playCategory +
      ' - ' +
      this.state.NFT.dateOfMoment +
      ', ' +
      this.state.NFT.setName +
      ', ' +
      this.state.NFT.teamAtMoment
    let imageURL = this.state.NFT.assetPathPrefix + 'Hero_2880_2880_Black.jpg'
    let videoURL = this.state.NFT.assetPathPrefix + 'Animated_1080_1920_Black.mp4'
    let media = (<VideoWSkeleton videoSrc={videoURL} poster={imageURL} imgClassName='detail-video-topshot' />)
    let viewButton = (
      <center>
        <button
          onClick={() => this.viewOnTopShots()}
          className="clear"
          style={{ marginBottom: '50px', marginTop: '50px' }}
        >
          View on Top Shot
        </button>
      </center>
    )

    var address = this.props.match.params.walletAddress + '/' + this.props.match.params.flowID
    // load the buttons
    if (window.__sharethis__) {
      window.__sharethis__.load('inline-share-buttons', {
        id: 'my-inline-buttons',
        alignment: 'center',
        color: 'white',
        enabled: true,
        font_size: 16,
        padding: 12,
        radius: 8,
        networks: ['twitter', 'facebook', 'linkedin', 'email', 'sms', 'messenger', 'whatsapp'],
        size: 40,
        show_mobile_buttons: true,
        url: 'https://lazy.com/topshot/' + address,
        title:
          'Check out this NFT at lazy.com/topshot/' +
          address +
          '. The lazy way to show off your NFTs!',
        subject: 'Check out this NFT on Lazy!',
        message:
          'Check out this NFT at lazy.com/topshot/' +
          address +
          '. The lazy way to show off your NFTs!'
      })
    }
    return (
      <div>
        <NavBar />
        <div className="nft-detail-media-container">{this.state.NFT.assetPathPrefix && media}</div>
        <div className="container detail-padding">
          <span className="detail-name">{this.state.NFT.playerName}</span>
          <span className="detail-description">{this.state.NFT.playerName && description}</span>
          {viewButton}
          <div id="my-inline-buttons"></div>
        </div>
      </div>
    )
  }
}

export default TopShotDetail
