import { FormControl, FormHelperText, Input, InputAdornment } from '@mui/material';
import React from 'react'
import { WaxNFTData } from '../WaxHelper';
import CountdownTimer from '../../../util/CountdownTimer';

type Props = {
    nft: WaxNFTData;
    address: string;
    handleClose: () => void;
    handleSubmit: (price: number) => void;
}

type State = {
    price?: number;
    startingBid: number;
    error?: string;
}

export default class AuctionModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let startingBid = 0, price = 0;
        if (props.nft.auctionsData) {
            const bids = props.nft.auctionsData.bids;
            if (bids && bids.length > 0) {
                const newestBid = bids.reduce(function(prev, current) {
                    return (prev.number > current.number) ? prev : current
                });
                startingBid = parseFloat((Number(newestBid.amount) / 100000000).toFixed(8));
                price = parseFloat((startingBid * 1.1).toFixed(8));
            } else {
                startingBid = Number(props.nft.auctionsData.price.amount);
                startingBid = parseFloat((startingBid / 100000000).toFixed(8));
                price = startingBid;
            }
        }
        this.state = {
            price,
            startingBid,
        };
    }

    setPrice = (value: string) => {
        const countDecimals = function (value: number) {
           if(Math.floor(value) === value) return 0;
           return value.toString().split(".")[1].length || 0; 
       }
       let val = Number(value);
       if (countDecimals(val) > 8) {
         val = Number(val.toFixed(8));
       }
       this.setState({ price: val === 0 ? undefined : val });
      };


    onSubmit = () => {
        const minAmount = parseFloat((this.state.startingBid * 1.1).toFixed(8));
        if (!this.state.price || (this.state.price && minAmount > this.state.price)) {
            // TODO: error
            this.setState({
                error: 'Error: Price must be 10% higher than current bid.'
            })
        } else {
            this.props.handleSubmit(this.state.price);
            if (this.state.error) this.setState({ error: undefined })
        }
    };
      
  render() {
    const { nft, address } = this.props;
    const renderedStartingBid = `${this.state.startingBid} WAX` ;
    const auctionTime = nft.auctionsData && new Date(Number(nft.auctionsData.end_time)).toTimeString();
    const timeRemaining = auctionTime && auctionTime != "Invalid Date" && (
        <React.Fragment>
            {`Time Remaining: `}<b><CountdownTimer date={new Date(Number(nft.auctionsData!.end_time))}/>{`\n\n` }</b>  
        </React.Fragment>
    );
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">{'Bid on WAX Auction'}</span>
          <span style={{ whiteSpace: 'pre-line'}} className="description">
              {`NFT Name: `}<b>{`${nft.name} \n\n` }</b>
              {`Highest Bid: `}<b>{`${renderedStartingBid} \n\n` }</b>
              {timeRemaining}
          </span>
          <FormControl variant="standard" sx={{ marginLeft: 20, m: 1, mt: 1, width: 200 }}>
            <Input
                style={{minWidth: 200 }}
                value={this.state.price}
                type="number"
                onChange={(event: any) => this.setPrice(event.target.value)}
                endAdornment={<InputAdornment position="end">WAX</InputAdornment>}
                inputProps={{
                'aria-label': 'Price',
                }}
            />
            <FormHelperText>Price</FormHelperText>
          </FormControl>
          <span style={{ 
            display: 'block',
            fontSize: 13,
            color: 'red',
            margin: 6 }}>{this.state.error}</span>
          <button className="clear" onClick={() => this.onSubmit()}>
            Submit
          </button>
          <button className="clear" onClick={this.props.handleClose}>
            Close
          </button>
        </div>
      </div>
    )
  }
}

