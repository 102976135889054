import { useEffect, useState } from 'react'
import NavBar from '../general/NavBar'
import firebase from '../../firebase'
import '../general/NFTDetail.css'
import axios from 'axios';
import ImageWSkeleton from '../ImageWSkeleton'
import VideoWSkeleton from '../VideoWSkeleton'

require('dotenv').config()

export default function AvalancheDetail(props) {
  let contractAddress = props.match.params.contractAddress.toLowerCase()
  let tokenID = props.match.params.tokenID.toLowerCase()

  const [NFT, setNFT] = useState({})
  const [animationURL, setAnimationURL] = useState('')
  const [imageURL, setImageURL] = useState('')

  useEffect(() => {
    loadNFT()
  }, [])

  useEffect(() => {
    setAnimationURL(loadAnimationURL())
    setImageURL(loadImageURL())
  }, [NFT])

  const loadAvalancheNFTDetail = async (contractAddress, tokenID) => {
    const headers = {
      'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY
  };

  // Choose the appropriate endpoint for Avalanche chain
  let endpointURL;
      endpointURL = `https://api.opensea.io/api/v2/chain/avalanche/contract/${contractAddress}/nfts/${tokenID}`;

  try {
      const { data } = await axios.get(endpointURL, { headers });
      console.log("NFT DATA", data);

      if (data.nft) {
          const nftWithMetadata =  {
                  type: "Avalanche",
                  data: {
                      identifier: data.nft.identifier,
                      collection: data.nft.collection,
                      contract: data.nft.contract,
                      token_standard: data.nft.token_standard,
                      name: data.nft.name,
                      description: data.nft.description || "",  // Use an empty string if description is null
                      image_url: data.nft.image_url,
                      metadata_url: data.nft.metadata_url,
                      created_at: data.nft.created_at,
                      updated_at: data.nft.updated_at,
                      is_disabled: data.nft.is_disabled,
                      is_nsfw: data.nft.is_nsfw
                  }
              };

          return nftWithMetadata; 
      } else {
          console.error("Error fetching Avalanche NFT data:", data.error);
          return [[], []];
      }
  } catch (error) {
      console.error("Error fetching Avalanche NFT data:", error);
      return [[], []];
  }
};
    
  const loadNFT = async () => {
    let nft = await loadAvalancheNFTDetail(contractAddress, tokenID);
    if (nft) {
      setNFT(nft);
    } else {
      // Handle the case when the NFT data is not found, e.g., show an error message
      console.error("Error: NFT data not found for contract address", contractAddress, "and token ID", tokenID);
    }
  };

  // To do: OpenSea v2 API doesn't have an animation_url. Instead, you need to load the metadata_url and look for an mp4
  const loadAnimationURL = () => {
    if (Object.keys(NFT).length === 0 || !NFT.data) {
      return null;
    }
  
   // let _animationURL = NFT.data.image_url; 
    
   // return _animationURL;

   return null;
};

  
  const loadImageURL = () => {
    if (Object.keys(NFT).length === 0) {
      return null;
    }
    console.log("loadImageURL", NFT)
    let _imageURL;
  
    if (NFT.data) {
      _imageURL = NFT.data.image_url;
    }
  
    if (_imageURL && _imageURL.toLowerCase().endsWith(".mp4")) {
      setAnimationURL(_imageURL);
    }
    if (
      (_imageURL === null || _imageURL === undefined) &&
      (animationURL === null || animationURL === undefined)
    ) {
      _imageURL = "/empty-media.png";
    }
    if (_imageURL && _imageURL.startsWith("ipfs://")) {
      const ipfsHash = _imageURL.replace("ipfs://", "");
      _imageURL = `https://brown-useful-echidna-164.mypinata.cloud/ipfs/${ipfsHash}`;
      _imageURL = _imageURL + "?pinataGatewayToken=iiMhhC50yYQCHORNpXyJpEQq3iBDvctIVck6d33rgaaUaJDHYqTFVDfA3zzm3Uu0";
    }
  

    return _imageURL;
  };
  
  
  const loadMedia = () => {
    if (animationURL) {
      if (imageURL === animationURL || !imageURL) {
        return (<VideoWSkeleton videoSrc={animationURL} imgClassName='detail-video' />)
      }
      return (<VideoWSkeleton poster={imageURL} videoSrc={animationURL} imgClassName='detail-video' />)
    } else if (imageURL) {
      return <ImageWSkeleton imgClassName='detail-image' imgSrc={imageURL} />
    } else {
      return <div className="loader"></div>;
    }
  };
  

  const loadOpenSeaURL = () => {
    if (!process.env.REACT_APP_TESTING) {
      return (
        'https://opensea.io/assets/avalanche/' +
          contractAddress +
          '/' +
          tokenID +
          '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      )
    } else {
      return (
        'https://testnets.opensea.io/assets/avalanche/' +
        contractAddress +
        '/' +
        tokenID +
        '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      )
    }
  }

  const viewOnOpenSea = (openseaURL) => {
    firebase.analytics().logEvent('opensea_click', {
      contract_address: '_' + contractAddress,
      token_id: '_' + tokenID
    })

    window.open(openseaURL)
  }

  const loadJoepegsURL = () => {
   return (
        'https://joepegs.com/item/avalanche/' +
          contractAddress +
          '/' +
          tokenID
      )
  }

  const viewOnJoePegs = (joepegsURL) => {
    firebase.analytics().logEvent('joepegs_click', {
      contract_address: '_' + contractAddress,
      token_id: '_' + tokenID
    })

    window.open(joepegsURL)
  }

  let media = loadMedia()
  let openseaURL = loadOpenSeaURL()
  let joepegsURL = loadJoepegsURL()

  // check if processing
  if (NFT.name === null && NFT.description === null && NFT.image_url === null) {
    setImageURL('/empty-media.png')
  }

  return (
    <div>
      <NavBar />
      <div className="nft-detail-media-container">{media}</div>
      <div className="container detail-padding">
        <span className="detail-name">{NFT.data?.name || ''}</span>
        <span className="detail-description">{NFT.data?.description || ''}</span>
        <center>
          <button
            onClick={() => viewOnOpenSea(openseaURL)}
            className="clear"
            style={{ marginBottom: '50px', marginTop: '50px' }}
          >
            View on OpenSea
          </button>
        </center>
        <center>
          <button
            onClick={() => viewOnJoePegs(joepegsURL)}
            className="clear"
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            View on JoePegs
          </button>
        </center>
      </div>
    </div>
  );  
}