import React, { useEffect, useMemo, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import firebase from '../../firebase'
import ConfirmationModal from '../modals/ConfirmationModal'

const AddSolanaWallet = (props) => {
  const [showModal, setShowModal] = useState(false)
  const { visible } = useWalletModal()
  const { publicKey, wallet, disconnect } = useWallet()
  const walletAddress = useMemo(() => publicKey?.toBase58(), [publicKey])
  const addWallet = () => {
    // check for existing wallet
    try {
      let db = firebase.firestore()
      let username = localStorage.getItem('username')

      db.collection('users')
        .doc(username)
        .collection('wallets')
        .where('address', '==', walletAddress)
        .where('type', '==', 'Solana')
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            alert('You have already added this wallet address to your Lazy account.')
          } else {
            // add to Firestore
            try {
              let db = firebase.firestore()
              let username = localStorage.getItem('username')

              db.collection('users')
                .doc(username)
                .collection('wallets')
                .doc()
                .set({
                  type: 'Solana',
                  address: walletAddress
                })
                .then(() => {
                  closeModal()
                  disconnect()
                  props.reloadWallets()
                })
                .catch((err) => {
                  console.log(err)
                  disconnect()
                })
            } catch (error) {
              console.log(error)
              disconnect()
            }
          }
        })
    } catch (error) {
      console.log(error)
      disconnect()
    }
  }

  const closeModal = () => {
    setShowModal(false)
    props.reset()
  }

  let promptMessage = (
    <p>
      <b>Selected Wallet Address: </b> {walletAddress}
    </p>
  )

  useEffect(() => {
    if (walletAddress) setShowModal(true)
  }, [walletAddress])

  useEffect(() => {
    if (!visible && !wallet) {
      closeModal()
      disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, wallet])
  return (
    <div>
      {showModal && wallet && (
        <ConfirmationModal
          title={'Connect Solana Wallet'}
          message={promptMessage}
          handleConfirm={addWallet}
          confirmLabel="Add Wallet"
          handleClose={closeModal}
          closeLabel="Cancel"
        />
      )}
    </div>
  )
}

export default AddSolanaWallet
