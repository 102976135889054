import { useState } from "react"
import './ExpandableDropdown.css';
import AnimatedChevron from "./AnimatedChevron";

export default function ExpandableBox({ title, children }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div onClick={handleClick} className={`expandable-box`}>
            <div className="header">
                <h2 className="title">{title}</h2>
                <AnimatedChevron open={isExpanded} />
            </div>
            <div className={`content-wrapper ${isExpanded ? 'expanded' : ''}`} style={{ maxHeight: isExpanded ? 1400 : 0 }}>
                <p className="content">{children}</p>
            </div>
        </div>
    );
}