import { useEffect, useState } from 'react'
import ConfirmationModal from '../modals/ConfirmationModal'
import firebase from '../../firebase'
import { MAINNET_RPC_URL, walletPlugins } from '../../util/constants'
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'

const injected = injectedModule()

export default function AddPolygonWallet(props) {
  const [walletProvider, setWalletProvider] = useState()
  const [walletAddress, setWalletAddress] = useState()
  const [showModal, setShowModal] = useState(false)

  let onboard

  const connectWallet = async () => {
    try {
      const wallets = await onboard.connectWallet()
      if (wallets) {
        const address = wallets[0] && wallets[0].accounts && wallets[0].accounts[0] && wallets[0].accounts[0].address;
        if (address) {
          console.log(address);
          setWalletAddress(address);
          addWallet(address);
        }
      }
    } catch (err) {
      console.log(err)
      closeModal()
    }
  }

  const initializeOnboard = () => {
    onboard = Onboard({
      appMetadata: {
        name: 'Lazy',
        icon: 'lazy.png',
        description: 'Lazy wallet select'
      },
      chains: [
        {
          id: '0x89',
          token: 'MATIC',
          label: 'Polygon Matic',
          rpcUrl: 'https://rpc-mainnet.matic.network',
        }
      ],
      wallets: [injected, ...walletPlugins],
    })
  }

  const addWallet = (walletAdd) => {
    // check for existing wallet
    try {
      const db = firebase.firestore()
      const username = localStorage.getItem('username')

      db.collection('users')
        .doc(username)
        .collection('wallets')
        .where('address', '==', walletAdd)
        .where('type', '==', 'Polygon')
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            alert('You have already added this wallet address to your Lazy account.')
          } else {
            // add to Firestore
            try {
              const db = firebase.firestore()
              const username = localStorage.getItem('username')

              db.collection('users')
                .doc(username)
                .collection('wallets')
                .doc()
                .set({
                  type: 'Polygon',
                  address: walletAdd
                })
                .then(() => {
                  closeModal()
                  //props.reloadWallets()
                  location.reload();
                })
                .catch((err) => {
                  console.log(err)
                })
            } catch (error) {
              console.log(error)
            }
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initializeOnboard()
    connectWallet()
  }, [])

  const closeModal = () => {
    setShowModal(false)
    props.reset()
  }

  const promptMessage = (
    <p>
      <b>Selected Wallet Address: </b> {walletAddress}
    </p>
  )
  return (
    <div>
      {showModal && (
        <ConfirmationModal
          title={'Connect ' + walletProvider + ' Wallet'}
          message={promptMessage}
          handleConfirm={addWallet}
          confirmLabel="Add Wallet"
          handleClose={closeModal}
          closeLabel="Cancel"
        />
      )}
    </div>
  )
}
