import { useEffect, useState } from 'react'
import { loadEthereumPins } from '../ethereum/EthereumHelper'

import TopShotNFTNew from '../topshot/TopShotNFTNew'
import PolygonNFT from '../polygon/PolygonNFT'
import EthereumNFTNew from '../ethereum/EthereumNFTNew'
import {
  closestCenter,
  DndContext,
  MouseSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { arrayMove } from 'react-sortable-hoc'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { loadPolygonPins } from '../polygon/PolygonHelper'
import { loadTopShotPins } from '../topshot/TopShotHelper'
import { loadSolanaPins } from '../solana/SolanaHelper'
import SolanaNFT from '../solana/SolanaNFT'
import { loadWaxPins } from '../wax/WaxHelper'
import WaxNFTNew from '../wax/WaxNFTNew'
import { loadTezosPins } from '../tezos/TezosHelper'
import TezosNFTNew from '../tezos/TezosNFTNew'
import { loadAvalanchePins } from '../avalanche/AvalancheHelper'
import AvalancheNFT from '../avalanche/AvalancheNFT'

export const PinnedNFTsNew = (props) => {
  const [items, setItems] = useState([])
  const [NFTs, setNFTs] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [isAuthor, setIsAuthor] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasSorted, setHasSorted] = useState(false);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  )

  useEffect(() => {
    if (props.pins?.length !== NFTs.length) {
      setNFTs([])
      setHasLoaded(false);
    }
  }, [props.pins])


  useEffect(() => {
    setIsAuthor(checkIfAuthor())
    if (!hasLoaded) {
      loadPinData()
    }
  }, [hasLoaded])

  useEffect(() => {
    if (hasLoaded) {
     // console.log('Loaded NFTs:', NFTs);
      // sort NFTs
      const sorted = NFTs.slice()
      sorted.sort((a, b) => (a.index > b.index ? 1 : -1))
      setNFTs(sorted)

      // store references into items
      const tempItems = []
      sorted.forEach( ( nft ) => {
        if (nft) tempItems.push(nft.pinID)
      })
      setItems(tempItems)

      setHasSorted(true)
      props.notifyPinsLoaded()
    }
  }, [hasLoaded])

  useEffect(() => {
    if (hasLoaded) {
      // triggered when NFTs are re-ordered
      savePinsOrder()
    }
  }, [NFTs])

  const savePinsOrder = () => {
    const pinsOrder = []
    NFTs.forEach((nft) => {
      if ( nft ) pinsOrder.push(nft.pinID)
    })

    props.functions.savePinsOrder(pinsOrder)
  }

  const handleDragStart = (event) => {
    setIsDragging(true)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id)
      const newIndex = items.indexOf(over.id)

      setItems((items) => {
        return arrayMove(items, oldIndex, newIndex)
      })

      setNFTs((NFTs) => {
        return arrayMove(NFTs, oldIndex, newIndex)
      })
    }

    setTimeout(() => setIsDragging(false), 0)
  }

  const loadPinData = async () => {
    const pins = new Map(); // Using Map for faster look-up
    //console.log("Pins:", props.pins);
    
    for (const pin of props.pins) { // using for...of for better async-await handling later, if needed
      const pinKey = `${pin.walletAddress}-${pin.type}`;
      const pinData = {
        id: pin.id,
        index: pin.index,
        contractAddress: pin.contractAddress,
        tokenID: pin.tokenID,
        caption: pin.caption,
        walletAddress: pin.walletAddress,
        type: pin.type,
      };
  
      if (pins.has(pinKey)) {
        pins.get(pinKey).push(pinData);
      } else {
        pins.set(pinKey, [pinData]);
      }
    }
  
    // Parallelize the NFT loading using Promise.allSettled
    await Promise.allSettled(
      Array.from(pins.keys()).map(async key => {
        const [walletAddress, walletType] = key.split('-');
        try {
          await loadNFTs(walletType, walletAddress, pins.get(key));
        } catch (err) {
          console.log(err);
        }
      })
    );
  
    setHasLoaded(true);
  };
  

  const loadNFTs = async (walletType, walletAddress, pins) => {
    const loaders = {
      'Ethereum': loadEthereumPins,
      'Polygon': loadPolygonPins,
      'NBA Top Shot': loadTopShotPins,
      'Solana': loadSolanaPins,
      'Wax': loadWaxPins,
      'Tezos': loadTezosPins,
      'Avalanche': loadAvalanchePins,
    };
  
    const loadFunction = loaders[walletType];
    
    if (loadFunction) {
      const nfts = await loadFunction(walletAddress, pins);
      setNFTs((prev) => prev.concat(nfts));
    }
  };
  
  
  const handleUnpin = async (pinID) => {
    return await props.functions.handleUnpin(pinID)
  }

  const handleBuy = (data) => {
    props.functions.handleBuy({
      contractAddress: data.contractAddress,
      tokenID: data.tokenID,
      listingPrice: data.listingPrice,
      listingCurrency: data.listingCurrency,
      name: data.name,
      ownerWalletAddress: data.walletAddress,
      order: data.order,
    })
  }

  const handleSell = (data) => {
    props.functions.handleSell({
      contractAddress: data.contractAddress,
      tokenID: data.tokenID,
      name: data.name,
      schemaName: data.schemaName,
      ownerWalletAddress: data.walletAddress
    })
  }

  const handleUnlist = (data) => {
    props.functions.handleUnlist({
      contractAddress: data.contractAddress,
      tokenID: data.tokenID,
      name: data.name,
      listingPrice: data.listingPrice,
      ownerWalletAddress: data.walletAddress,
      order: data.order,
    })
  }

  const functions = {
    ...props.functions,
    handleUnpin: handleUnpin,
    handleBuy: handleBuy,
    handleSell: handleSell,
    handleUnlist: handleUnlist,
    handleWaxSell: props.functions.handleWaxSell,
    handleWaxCancelSell: props.functions.handleWaxCancelSell,
    handleWaxAuction: props.functions.handleWaxAuction,
    handleWaxCancelAuction: props.functions.handleWaxCancelAuction,
  }

  const isDraggable = isAuthor

  const nftComponents = NFTs.map((nft, idx) => {
   // console.log(`Rendering NFT: Type=${nft.type}, Index=${idx}`, nft);
    if (!nft || !nft.data) return <></>
    if (nft.type === 'Ethereum') {
      return (
        <EthereumNFTNew
          key={nft.pinID}
          data={nft.data.nft}
          username={props.username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={!isDragging}
          isDraggable={isDraggable}
        />
      )
    } else if (nft.type === 'NBA Top Shot') {
      return (
        <TopShotNFTNew
          key={nft.pinID}
          data={nft.data}
          username={props.username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={!isDragging}
          isDraggable={isDraggable}
        />
      )
    } else if (nft.type === 'Polygon') {
      return (
        <PolygonNFT
          key={nft.pinID}
          data={nft.data.nft} // nft.data.nft is necessary because of OpenSea v2 API
          username={props.username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={!isDragging}
          isDraggable={isDraggable}
        />
      )
    } else if (nft.type === 'Solana') {
      return (
        <SolanaNFT
          key={nft.pinID}
          data={nft.data}
          username={props.username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={!isDragging}
          isDraggable={isDraggable}
        />
      )
    } else if (nft.type === 'Wax') {
      return (
        <WaxNFTNew
          key={nft.pinID}
          data={nft.data}
          username={props.username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          functions={functions}
          caption={nft.caption}
          isClickable={!isDragging}
          isDraggable={isDraggable}
        />
      )
    } else if (nft.type === 'Tezos') {
      return (
        <TezosNFTNew
          key={nft.pinID}
          data={nft.data}
          username={props.username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          functions={functions}
          caption={nft.caption}
          isClickable={!isDragging}
          isDraggable={isDraggable}
        />
      )
    } else if (nft.type === 'Avalanche') {
      return (
        <AvalancheNFT
          key={nft.pinID}
          data={nft.data}
          username={props.username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={!isDragging}
          isDraggable={isDraggable}
        />
      )
    }
  })

  const checkIfAuthor = () => {
    const username = localStorage.getItem('username')
    if (username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const pinnedAuthor = (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <div className="NFT-container">{nftComponents}</div>
      </SortableContext>
    </DndContext>
  )

  const pinnedVisitor = <div className="NFT-container">{nftComponents}</div>

  const pinnedNFTs = <div>{isAuthor ? pinnedAuthor : pinnedVisitor}</div>

  return (
    <div>
      <h1 style={{ marginLeft: '20px', marginBottom: '10px' }}>Pinned NFTs</h1>
      {NFTs.length > 0 && hasSorted ? (
        pinnedNFTs
      ) : hasLoaded ? (
        'No Pinned NFTs'
      ) : (
        <div style={{ textAlign: 'center' }}>
          <img src="spin.svg" alt="Loading spinner" style={{ width: '150px' }} />
        </div>
      )}
    </div>
  )
}
