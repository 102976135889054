import React, { useMemo } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet
} from '@solana/wallet-adapter-wallets'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl } from '@solana/web3.js'
import Wallets from './components/general/Wallets'
import Login from './components/general/Login'
import SignUp from './components/general/SignUp'
import Home from './components/general/Home'
import ResetPassword from './components/general/ResetPassword'
//import Explore from './components/general/Explore'
import EthereumDetail from './components/ethereum/EthereumDetail'
import WaxDetail from './components/wax/WaxDetail'
import TopShotDetail from './components/topshot/TopShotDetail'
import { AuthProvider } from './Auth'
import PrivateRoute from './PrivateRoute'
import PrivateRoute2 from './PrivateRoute2'
import Mint from './components/general/Mint'
import EthereumRabbitHole from './components/ethereum/EthereumRabbitHole'
import EthereumBizarroWorld from './components/ethereum/EthereumBizarroWorld'
import PolygonDetail from './components/polygon/PolygonDetail'
import NewPortfolio from './components/general/NewPortfolio'
import HiddenNFTs from './components/general/HiddenNFTs'
import SolanaDetail from './components/solana/SolanaDetail'
import RabbitHoleGeneric from './components/general/RabbitholeGeneric'
import BizarroworldGeneric from './components/general/BizarroworldGeneric'
import TezosDetail from './components/tezos/TezosDetail'
import AvalancheDetail from './components/avalanche/AvalancheDetail'
import PrivacyPolicy from './components/general/PrivacyPolicy'
import TermsOfService from './components/general/TermsOfService'
import About from './components/general/About'
import FAQ from './components/general/FAQ'

const App = () => {
  const network = WalletAdapterNetwork.Devnet

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getTorusWallet({
        options: { clientId: 'Get a client ID @ https://developer.tor.us' }
      }),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network })
    ],
    [network]
  )
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider logo="/favicon.ico">
          <AuthProvider>
            <Router>
              <div>
                <Switch>
                  <PrivateRoute exact path="/" component={Home} />
                  <PrivateRoute2 exact path="/wallets" component={Wallets} />
                  {
                    //<PrivateRoute2 exact path="/mint" component={Mint} />
                  }
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/signup" component={SignUp} />
                  <Route exact path="/reset" component={ResetPassword} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/faq" component={FAQ} />
                  <Route exact path="/privacypolicy" component={PrivacyPolicy} />
                  <Route exact path="/terms" component={TermsOfService} />
                  <Route
                    exact
                    path="/rabbithole"
                    component={RabbitHoleGeneric}
                  />
                  <Route
                    exact
                    path="/bizarroworld"
                    component={BizarroworldGeneric}
                  />
                  <Route
                    exact
                    path="/rabbithole/ethereum/:contractAddress/:tokenID"
                    component={EthereumRabbitHole}
                  />
                  <Route
                    exact
                    path="/bizarroworld/ethereum/:contractAddress/:tokenID"
                    component={EthereumBizarroWorld}
                  />
                  <PrivateRoute2 exact path="/explore" component={Home} hideIntroBanner={true} />
                  <Route
                    path="/nft/:walletAddress/:contractAddress/:tokenID"
                    component={EthereumDetail}
                  />
                  <Route
                    path="/nft/:contractAddress/:tokenID"
                    render={(props) => <EthereumDetail {...props} legacy={true} />}
                  />
                  <Route
                    path="/ethereum/:walletAddress/:contractAddress/:tokenID"
                    component={EthereumDetail}
                  />
                  <Route path="/topshot/:walletAddress/:flowID" component={TopShotDetail} />
                  <Route
                    path="/polygon/:walletAddress/:contractAddress/:tokenID"
                    component={PolygonDetail}
                  />
                  <Route
                    path="/wax/:assetID"
                    component={WaxDetail}
                  />
                  <Route
                    path="/tezos/:assetID"
                    component={TezosDetail}
                  />
                  <Route path="/solana/:walletAddress/:contractAddress" component={SolanaDetail} />
                  <Route
                    path="/avalanche/:contractAddress/:tokenID"
                    component={AvalancheDetail}
                  />
                  <Route path="/:username/hiddenNfts" component={HiddenNFTs} />
                  <Route path="/:username" component={NewPortfolio} />

                </Switch>
              </div>
            </Router>
          </AuthProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

export default App
