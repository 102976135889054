import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type LazyModalProps = {
    open: boolean;
    title: string;
    description: string;
    onClose?: () => void;
};

type LazyModalState = {

}

export class LazyAlert extends React.Component<LazyModalProps, LazyModalState> {
    constructor (props: LazyModalProps) {
        super(props);
    }

    render() {
        return (
            <Dialog
                open={ this.props.open }
                onClose={ this.props.onClose }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.description}
                    </DialogContentText>
                </DialogContent>
                <div style={{
                    display: 'flex',
                    padding: 10,
                    justifyContent: 'center',
                }}>
                    <button onClick={this.props.onClose} type="submit" >
                        Close
                    </button>
                </div>
                
            </Dialog>
        )
    }
}