import ExpandableDropdown from './ExpandableDropdown';
import './FAQ.css'
import NavBar from './NavBar'

const listStyle = {
    listStyleType: 'disc',
    marginLeft: '4rem',
};

const QUESTIONS = [
    {
        title: 'Q: Is it safe to connect my wallets to Lazy?',
        text: `A: Absolutely safe! To see why, it is important to understand the three different components that work together whenever you access a blockchain application. 
        \n \nFirst, there is your wallet provider, such as MetaMask, Rainbow or Coinbase. The wallet provider stores your private key and keeps a record of your various public wallet addresses.
        \n \nSecond, there is the website you are interacting with. These websites can ask your wallet provider to do three things: a) tell it your public address b) sign a transaction or c) submit a transaction.
        \n \nThird, there is the blockchain where transactions are received and recorded. If you allow your wallet provider to sign or submit a transaction, it will ultimately end up being recorded on the blockchain. If you simply “connect” your wallet to Lazy.com then all you’ve done is let us know your public address. Therefore, it is perfectly safe! Lazy will only ask you to sign or approve a transaction if you attempt to buy or sell an NFT. TLDR: Simply connecting a wallet to Lazy to display your NFTs is 100% safe.`
    },
    {
        title: 'Q: I store my NFTs in two different Ethereum wallets. Is there a way to add both wallets to my Lazy profile?',
        text: 'A: Yes! You can easily add multiple wallets to your Lazy profile. Simply go to the My Wallets page at lazy.com/wallets and click Add Wallet. Choose Ethereum and follow the prompts to add your next wallet.'
    },
    {
        title: 'Q: What are NFTs?',
        text: 'A: NFTs, or non-fungible tokens, are unique digital assets that are recorded on a blockchain and can represent a wide range of items including art, music, videos, and collectibles.'
    },
    {
        title: 'Q: How can I buy an NFT?',
        text: 'A: NFTs can be purchased online on Lazy.com and marketplaces such as OpenSea, Rarible or Uniswap. You will need to set up a wallet that supports NFTs and have a cryptocurrency, usually Ethereum, to make the purchase.'
    },
    {
        title: 'Q: Can I resell my NFT?',
        text: 'A: Yes, you can resell your NFT on the same marketplace or a different one. The value of the NFT may increase or decrease depending on the demand for it.'
    },
    {
        title: 'Q: How do I know if an NFT is authentic?',
        text: 'A: One way to verify the authenticity of an NFT is to check the blockchain it is recorded on by using a blockchain explorer, such as etherscan.com. You can see the entire transaction history of the NFT, including the creator and previous owners.'
    },
    {
        title: 'Q: Can NFTs be counterfeited?',
        text: 'A: It is technically possible to create a counterfeit NFT, but it would be difficult to pass it off as genuine since the transaction history and ownership can be easily traced on the blockchain.'
    },
    {
        title: 'Q: Can NFTs be used for more than just art and collectibles?',
        text: <p>A: NFTs have the potential to be used for a wide range of applications beyond art and collectibles. They can be used for:
            <ul style={listStyle}>
                <li>Real estate: NFTs can be used to represent ownership of physical property, such as a house or land.</li>
                <li>Tickets: NFTs can be used as a secure and easily transferable ticket for events, concerts, or sports games.</li>
                <li>Digital content: NFTs can be used to represent ownership of digital content such as ebooks, music, or videos.</li>
                <li>Gaming: NFTs can be used to represent in-game items or achievements that can be bought, sold, or traded.</li>
                <li>Identity verification: NFTs can be used as a secure and verifiable way to represent identity information such as a driver's license or passport.</li>
            </ul>
        </p>
    },
    {
        title: 'Q: Are NFTs a good investment?',
        text: 'A: It is difficult to predict the value of NFTs as they are a relatively new and emerging market. Some NFTs have sold for millions of dollars, while others have not gained much value. It is important to do your own research and understand the risks before buying any NFTs. Never assume that an NFT will go up in value.'
    }
]


export default function FAQ() {

    return (
        <div className='top-container-faq' >
            <NavBar showGetStarted={true} />
            <div className="home-container-faq">
                <p className='faq-header-faq'>Frequently asked questions</p>
                {QUESTIONS.map(ques => (
                    <ExpandableDropdown key={ques.title} title={ques.title} >
                        <p>{ques.text}</p>
                    </ExpandableDropdown>
                ))}
            </div>
        </div>
    )
}