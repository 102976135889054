import React from 'react'
import { WaxNFTData } from '../WaxHelper';

type Props = {
    nft: WaxNFTData;
    address: string;
    handleClose: () => void;
    handleSubmit: () => void;
}

export default class BuyWaxModal extends React.Component<Props> {
  render() {
      const { nft, address } = this.props;
    let listingPrice = 0, listingCurrency, showPrice: any = 0;
    if (nft.salesData) {
        listingPrice = Number(nft.salesData.listing_price);
        listingCurrency = nft.salesData.listing_symbol.toUpperCase();
        if (listingCurrency == 'WAX') {
            showPrice = parseFloat((listingPrice / 100000000).toFixed(2));
        } else if (listingCurrency == 'USD') {
            showPrice = (listingPrice / 100).toFixed(2);
        }
    }
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">{'Buy WAX NFT'}</span>
          <span style={{ whiteSpace: 'pre-line'}} className="description">
              {`You are about to purchase: `}<b>{`\n${nft.name} \n\n` }</b>
              {`Your WAX Address: \n`}<b>{`${address} \n\n`}</b>
              {`Total Price: \n`}<b>{`${showPrice} ${listingCurrency}`}</b>
          </span>
          <button className="clear" onClick={this.props.handleSubmit}>
            Submit
          </button>
          <button className="clear" onClick={this.props.handleClose}>
            Close
          </button>
        </div>
      </div>
    )
  }
}

