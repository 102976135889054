export const inputStyle = {
  border: 'none',
  borderRadius: 17,
  paddingLeft: 20,
  paddingRight: 20,
  fontSize: '12pt',
  outline: 'none',
  display: 'block',
  marginBottom: 10,
  minWidth: 300,
  borderRadius: 25,
  boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.05)',
  height: 50,
};

export const simpleInputStyle = {
  border: '1px solid #888888',
  borderRadius: 100,
  paddingLeft: 24,
  color: '#646464',
  height: 45
}