import React from 'react'

import { Item } from '@rarible/api-client/build/models/Item'

type Props = {
    nft: Item;
    address: string;
    handleClose: () => void;
    handleSubmit: () => void;
}

export default class BuyTezosModal extends React.Component<Props> {
  render() {
      const { nft, address } = this.props;
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">{'Buy Tezos NFT'}</span>
          <span style={{ whiteSpace: 'pre-line'}} className="description">
              {`You are about to purchase: `}<b>{`\n${nft.meta!.name} \n\n` }</b>
              {`Total Price: \n`}<b>{`${Number((nft as any).ownership.bestSellOrder!.makePrice).toFixed(2)} XTZ`}</b>
          </span>
          <button className="clear" onClick={this.props.handleSubmit}>
            Submit
          </button>
          <button className="clear" onClick={this.props.handleClose}>
            Close
          </button>
        </div>
      </div>
    )
  }
}

