import React from 'react'
import TopShotNFTNew from '../topshot/TopShotNFTNew'
import PolygonNFT from '../polygon/PolygonNFT'
import './AllNFTs.css'
import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'
import { loadMoment, loadAllMoments } from '../topshot/TopShotHelper'
import { loadEthereumNFTs } from '../ethereum/EthereumHelper'
import { loadPolygonNFTs } from '../polygon/PolygonHelper'
import { loadSolanaNFTs } from '../solana/SolanaHelper'
import EthereumNFTNew from '../ethereum/EthereumNFTNew'
import SolanaNFT from '../solana/SolanaNFT'
import WaxNFTNew from '../wax/WaxNFTNew'
import { loadWaxNFTs } from '../wax/WaxHelper'
import { loadTezosNFTs } from '../tezos/TezosHelper'
import { loadAvalancheNFTs } from '../avalanche/AvalancheHelper';
import AvalancheNFT from '../avalanche/AvalancheNFT'
import TezosNFTNew from '../tezos/TezosNFTNew'

const nftAirdropCache = {};

class AllNFTs extends React.Component {
  constructor(props) {
    super(props)

    var selectedWallet = null
    if (this.props.wallets.length > 0) {
      if (
        this.props.username.toLowerCase() === 'dehiscenceart' ||
        this.props.username.toLowerCase() === 'kwame' ||
        this.props.username.toLowerCase() === 'lambertchu' ||
        this.props.username.toLowerCase() === 'icinsight' ||
        this.props.username.toLowerCase() === 'mannymoe' ||
        this.props.username.toLowerCase() === 'monkhouse'
      ) {
        // get Polygon wallet
        let index = 0
        let i = 0
        this.props.wallets.forEach((wallet) => {
          if (wallet.type === 'Polygon') {
            index = i
          }
          i += 1
        })
        selectedWallet = this.props.wallets[index]
      } else {
        selectedWallet = this.props.wallets[0]
      }
    }

    const ethWallets = [],
      polygonWallets = [],
      topShotWallets = [],
      solanaWallets = [],
      waxWallets = [],
      tezosWallets = [],
      avalancheWallets = [];

    this.props.wallets.forEach((wallet) => {
      if (wallet.type === 'Ethereum') {
        ethWallets.push(wallet.address)
      } else if (wallet.type === 'Polygon') {
        polygonWallets.push(wallet.address)
      } else if (wallet.type === 'NBA Top Shot') {
        topShotWallets.push(wallet.address)
      } else if (wallet.type === 'Solana') {
        solanaWallets.push(wallet.address)
      } else if (wallet.type === 'Wax') {
        waxWallets.push(wallet.address);
      } else if (wallet.type === 'Tezos') {
        tezosWallets.push(wallet.address);
      } else if (wallet.type === 'Avalanche') {
        avalancheWallets.push(wallet.address);
      }

    })

    this.state = {
      NFTs: [],
      hasMoreItems: {},
      ethCursors: {},
      pageKey: 0,
      selectedWallet: selectedWallet,
      polygonCursor: null,
      tezosCursor: null,
      waxCursor: null,
      solanaCursor: null,
      avalancheCursor: null,
      currentPage: 0,
      isEthereumLoading: false,
      isPolygonLoading: false,
      isTopShotLoading: false,
      isSolanaLoading: false,
      isWaxLoading: false,
      isTezosLoading: false,
      isAvalancheLoading: false,
      showEthereum: true,
      showPolygon: true,
      showSolana: true,
      showTopShot: true,
      showWax: true,
      showTezos: true,
      ethCurrentPage: 0,
      polygonCurrentPage: 0,
      solanaCurrentPage: 1,
      topShotCurrentPage: 0,
      tezosCurrentPage: 0,
      waxCurrentPage: 1, //0 does not work for Wax
      avalancheCurrentPage: 1,
      featuredEthereumNFTs: [],
      featuredPolygonNFTs: [],
      featuredSolanaNFTs: [],
      featuredTopShotNFTs: [],
      featuredWaxNFTs: [],
      featuredTezosNFTs: [],
      featuredAvalancheNFTs: [],
      showAvalanche: JSON.parse(localStorage.getItem('ShowAvalancheAllNFT')) || true,
      ethWallets,
      polygonWallets,
      topShotWallets,
      solanaWallets,
      waxWallets,
      tezosWallets,
      avalancheWallets,
    }

    this.loadEthereum = this.loadEthereum.bind(this)
    this.loadPolygon = this.loadPolygon.bind(this)
    this.loadNBATopShot = this.loadNBATopShot.bind(this)
    this.loadSolana = this.loadSolana.bind(this)
    this.loadWax = this.loadWax.bind(this);
    this.loadTezos = this.loadTezos.bind(this);
    this.loadAvalanche = this.loadAvalanche.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleBuy = this.handleBuy.bind(this)
    this.handleSell = this.handleSell.bind(this)
    this.handleUnlist = this.handleUnlist.bind(this)
    this.handlePin = this.handlePin.bind(this)
    this.handleHide = this.handleHide.bind(this)
    this.handleUnpin = this.handleUnpin.bind(this);

  }

  async isAirdroppedNFT(contractAddress, tokenId, retryCount = 0) {
    // Exempt token details
    const exemptContract = "0xd9b78a2f1dafc8bb9c60961790d2beefebee56f4";
    const exemptIdentifier = "1279";

    // Check if the NFT is the exempted one
    if (contractAddress === exemptContract && tokenId === exemptIdentifier) {
        return false; // Assume exempt NFT is not airdropped
    }

    const cacheKey = `${contractAddress}-${tokenId}`;
    
    // Check cache first
    if(nftAirdropCache.hasOwnProperty(cacheKey)) {
      return nftAirdropCache[cacheKey];
    }

    const options = { method: 'GET', headers: { accept: 'application/json' } };
    const url = `https://eth-mainnet.g.alchemy.com/nft/v3/cgSzAPsNnsg4lvQIvEXHQxMgY6iQR2FX/isAirdropNFT?contractAddress=${contractAddress}&tokenId=${tokenId}`;

    try {
      const response = await fetch(url, options);
      if (response.status === 429 && retryCount < 3) {
        const delay = Math.pow(2, retryCount) * 1000; // Exponential backoff
        await new Promise(resolve => setTimeout(resolve, delay));
        return this.isAirdroppedNFT(contractAddress, tokenId, retryCount + 1);
      } else if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      const jsonResponse = await response.json();
      // Store result in cache
      nftAirdropCache[cacheKey] = jsonResponse.isAirdrop;
      return jsonResponse.isAirdrop;
    } catch (err) {
      console.error('Error fetching data:', err.message);
      return false;
    }
}

  
  componentWillReceiveProps(nextProps) {
    const { hiddenItems, isLoadingHiddenItem, lastHideActionType } = nextProps

    if (hiddenItems.length > 0 && !isLoadingHiddenItem) {
      if (lastHideActionType === 'Ethereum') {
        const _featuredEthereumNFTs = this.state.featuredEthereumNFTs.filter((nft) => {
          return (
            hiddenItems.findIndex(
              (item) => item.tokenID === nft.tokenID && item.contractAddress === nft.contractAddress
            ) < 0
          )
        })
        this.setState({
          featuredEthereumNFTs: _featuredEthereumNFTs
        })
      } else if (lastHideActionType === 'Polygon') {
        const _featuredPolygonNFTs = this.state.featuredPolygonNFTs.filter((nft) => {
          return (
            hiddenItems.findIndex(
              (item) =>
                item.tokenID === nft.data.token_id &&
                item.contractAddress === nft.data.asset_contract.address &&
                item.walletAddress === nft.ownerWalletAddress
            ) < 0
          )
        })
        this.setState({
          featuredPolygonNFTs: _featuredPolygonNFTs
        })
      } else if (lastHideActionType === 'Solana') {
        const _featuredSolanaNFTs = this.state.featuredSolanaNFTs.filter((nft) => {
          return (
            hiddenItems.findIndex(
              (item) =>
                item.contractAddress === nft.mint && item.walletAddress === nft.ownerWalletAddress
            ) < 0
          )
        })
        this.setState({
          featuredSolanaNFTs: _featuredSolanaNFTs
        })
      } else if (lastHideActionType === 'NBA Top Shot') {
        const _featuredTopShotNFTs = this.state.featuredTopShotNFTs.filter((nft) => {
          return hiddenItems.findIndex((item) => item.tokenID === nft.flowID) < 0
        })
        this.setState({
          featuredTopShotNFTs: _featuredTopShotNFTs
        })
      } else if (lastHideActionType === 'Wax') {
        const _featuredWaxNFTs = this.state.featuredWaxNFTs.filter((nft) => {
          return hiddenItems.findIndex((item) => item.tokenID === nft.flowID) < 0
        })
        this.setState({
          featuredWaxNFTs: _featuredWaxNFTs
        })
      } else if (lastHideActionType === 'Tezos') {
        const _featuredTezosNFTs = this.state.featuredTezosNFTs.filter((nft) => {
          return hiddenItems.findIndex((item) => item.tokenID === nft.flowID) < 0
        })
        this.setState({
          featuredTezosNFTs: _featuredTezosNFTs
        })
      }
      else if (lastHideActionType === 'Avalanche') {
        const _featuredAvalancheNFTs = this.state.featuredAvalancheNFTs.filter((nft) => {
          return hiddenItems.findIndex((item) => item.tokenID === nft.flowID) < 0
        })
        this.setState({
          featuredAvalancheNFTs: _featuredAvalancheNFTs
        })
      }
    }
  }
  

  componentDidMount() {
    this.props.wallets.forEach((wallet) => {
      if (wallet.type === 'Ethereum') {
        this.loadEthereum(this.state.ethCurrentPage, wallet.address)
      } else if (wallet.type === 'Polygon') {
        this.loadPolygon(this.state.polygonCurrentPage, wallet.address)
      } else if (wallet.type === 'NBA Top Shot') {
        this.loadNBATopShot(this.state.topShotCurrentPage, wallet.address)
      } else if (wallet.type === 'Solana') {
        this.loadSolana(wallet.address)
      } else if (wallet.type === 'Wax') {
        this.loadWax(wallet.address)
      } else if (wallet.type === 'Tezos') {
        this.loadTezos(wallet.address)
      } else if (wallet.type === 'Avalanche') {
        this.loadAvalanche(wallet.address)
      }
    })
  }

  loadAssociatedPin(walletAddress, contractAddress, tokenID) {
    const foundPins = this.props.pins.filter((pin) => {
      return (
        pin.walletAddress === walletAddress &&
        pin.contractAddress === contractAddress &&
        pin.tokenID === tokenID
      )
    })
    return foundPins
  }

  loadAssociatedSolanaPin(walletAddress, contractAddress) {
    const foundPins = this.props.pins.filter((pin) => {
      return pin.walletAddress === walletAddress && pin.contractAddress === contractAddress
    })
    return foundPins
  }

  increasePageNumber() {
    this.setState({ currentPage: this.state.currentPage + 1 })
  }
  checkHasMoreItems(walletType) {
    if (walletType === 'Ethereum') {
      let res = false
      for (let i = 0; i < this.state.ethWallets.length; i++) {
        res = res || this.state.hasMoreItems[`eth-${this.state.ethWallets[i]}`]
      }
      return res
    } else if (walletType === 'Polygon') {
      let res = false
      for (let i = 0; i < this.state.polygonWallets.length; i++) {
        res = res || this.state.hasMoreItems[`polygon-${this.state.polygonWallets[i]}`]
      }
      return res
    } else if (walletType === 'NBA Top Shot') {
      let res = false
      for (let i = 0; i < this.state.topShotWallets.length; i++) {
        res = res || this.state.hasMoreItems[`topshot-${this.state.topShotWallets[i]}`]
      }
      return res
    } else if (walletType === 'Tezos') {
      let res = false
      for (let i = 0; i < this.state.tezosWallets.length; i++) {
        res = res || this.state.hasMoreItems[`tezos-${this.state.tezosWallets[i]}`]
      }
      return res
    } else if (walletType === 'Wax') {
      let res = false
      for (let i = 0; i < this.state.waxWallets.length; i++) {
        res = res || this.state.hasMoreItems[`wax-${this.state.waxWallets[i]}`]
      }
      return res
    } else if (walletType === 'Solana') {
      let res = false
      for (let i = 0; i < this.state.solanaWallets.length; i++) {
        res = res || this.state.hasMoreItems[`solana-${this.state.solanaWallets[i]}`]
      }
      return res
    } else if (walletType === 'Avalanche') {
      let res = false
      for (let i = 0; i < this.state.avalancheWallets.length; i++) {
        res = res || this.state.hasMoreItems[`avalanche-${this.state.avalancheWallets[i]}`]
      }
      return res
    }
  }

  loadMoreNFTs(walletType) {
    const loadFunctions = {
      'Ethereum': this.loadEthereum,
      // Add other wallets here as necessary...
    };
    if (walletType === 'Ethereum') {
      const promiseQueue = [];
  
      this.state.ethWallets.forEach((walletAddress) => {
        const hasMoreItems = this.state.hasMoreItems[`eth-${walletAddress}`];
        const cursor = this.state.ethCursors[walletAddress];
  
        // Only initiate a load if there are more items and the cursor is defined.
        if (hasMoreItems && cursor) {
          const loadPromise = this.loadEthereum(this.state.ethCurrentPage + 1, walletAddress, cursor);
          promiseQueue.push(loadPromise);
        }
      });
  
      // After initiating all load requests, wait for them all to complete.
      Promise.all(promiseQueue).then(() => {
        // After all load requests have been completed, safely increment the page count.
        this.setState((prevState) => ({
          ethCurrentPage: prevState.ethCurrentPage + 1
        }));
      }).catch(error => {
        console.error("Error occurred while loading more NFTs: ", error);
      });
    } else if (walletType === 'Polygon') {
      this.state.polygonWallets.forEach((walletAddress) => {
        if (this.state.hasMoreItems[`polygon-${walletAddress}`]) {
          this.loadPolygon(this.state.polygonCurrentPage + 1, walletAddress)
        }
      })
      this.setState({ polygonCurrentPage: this.state.polygonCurrentPage + 1 })
    } else if (walletType === 'Tezos') {
      this.state.tezosWallets.forEach((walletAddress) => {
        if (this.state.hasMoreItems[`tezos-${walletAddress}`]) {
          this.loadTezos(walletAddress)
        }
      })
      this.setState({ tezosCurrentPage: this.state.tezosCurrentPage + 1 })
    } else if (walletType === 'Wax') {
      this.state.waxWallets.forEach((walletAddress) => {
        if (this.state.hasMoreItems[`wax-${walletAddress}`]) {
          this.loadWax(walletAddress)
        }
      })
      this.setState({ waxCurrentPage: this.state.waxCurrentPage + 1 })
    } else if (walletType === 'Solana') {
      this.state.solanaWallets.forEach((walletAddress) => {
        if (this.state.hasMoreItems[`solana-${walletAddress}`]) {
          this.loadSolana(walletAddress)
        }
      })
      this.setState({ solanaCurrentPage: this.state.solanaCurrentPage + 1 })
    } else if (walletType === 'Avalanche') {
      this.state.avalancheWallets.forEach((walletAddress) => {
        if (this.state.hasMoreItems[`avalanche-${walletAddress}`]) {
          this.loadAvalanche(walletAddress)
        }
      })
      this.setState({ avalancheCurrentPage: this.state.avalancheCurrentPage + 1 })
    } else if (walletType === 'NBA Top Shot') {
      this.state.topShotWallets.forEach((walletAddress) => {
        if (this.state.hasMoreItems[`topshot-${walletAddress}`]) {
          this.loadNBATopShot(this.state.topShotCurrentPage + 1, walletAddress)
        }
      })
      this.setState({ topShotCurrentPage: this.state.topShotCurrentPage + 1 })
    }
  }

  async loadSolana(walletAddress) {
    this.setState({ isSolanaLoading: true }, async () => {
      if (walletAddress) {
        try {
          const NFTs = await loadSolanaNFTs(walletAddress, this.state.solanaCurrentPage)
          const OwnerWithNFT = NFTs.map((data) => {
            return { ...data, ownerWalletAddress: walletAddress }
          }).filter((nft) => {
            return (
              this.props.hiddenItems.findIndex(
                (item) =>
                  item.contractAddress === nft.mint && item.walletAddress === nft.ownerWalletAddress
              ) < 0
            )
          })

          this.setState({
            featuredSolanaNFTs: [...this.state.featuredSolanaNFTs, ...OwnerWithNFT],
            isSolanaLoading: false,
            hasMoreItems: {
              ...this.state.hasMoreItems,
              [`solana-${walletAddress}`]: NFTs.length === 40
            }
          })
        } catch (err) {
          this.setState({ isSolanaLoading: false })
        }
      }
    })
  }

  async loadWax(walletAddress) {
    this.setState({ isWaxLoading: true }, async () => {
      if (walletAddress) {
        try {
          const NFTs = await loadWaxNFTs({ owner: walletAddress}, this.state.waxCurrentPage);
          const OwnerWithNFT = NFTs.map((data) => {
            return { ...data, ownerWalletAddress: walletAddress }
          }).filter((nft) => {
            return (
              this.props.hiddenItems.findIndex(
                (item) =>
                  item.walletAddress === nft.owner && item.tokenID === nft.asset_id
              ) < 0
            )
          })

          this.setState({
            featuredWaxNFTs: [...this.state.featuredWaxNFTs, ...OwnerWithNFT],
            isWaxLoading: false,
            hasMoreItems: {
              ...this.state.hasMoreItems,
              [`wax-${walletAddress}`]: NFTs.length === 50
            }
          })
        } catch (err) {
          this.setState({ isWaxLoading: false })
        }
      }
    })
  }

  async loadTezos(walletAddress) {
    this.setState({ isTezosLoading: true }, async () => {
      if (walletAddress) {
        try {
          const [NFTs, cursor] = await loadTezosNFTs({ owner: walletAddress, cursor: this.state.tezosCursor});
          const OwnerWithNFT = NFTs ? NFTs.map((data) => {
            return { ...data, ownerWalletAddress: walletAddress }
          }).filter((nft) => {
            return (
              this.props.hiddenItems.findIndex(
                (item) =>
                  item.walletAddress === nft.ownerWalletAddress && item.tokenID === `${nft.collection}:${nft.tokenId}`
              ) < 0
            )
          }) : [];


          this.setState({
            featuredTezosNFTs: [...this.state.featuredTezosNFTs, ...OwnerWithNFT],
            isTezosLoading: false,
            tezosCursor: cursor,
            hasMoreItems: {
              ...this.state.hasMoreItems,
              [`tezos-${walletAddress}`]: NFTs.length === 50
            }
          })
        } catch (err) {
          this.setState({ isTezosLoading: false })
        }
      }
    })
  }

  async loadPolygon(page, walletAddress) {
    this.setState({ isPolygonLoading: true }, async () => {
      if (walletAddress) {
        try {
          const [NFTs, cursor] = await loadPolygonNFTs(walletAddress, page, this.state.polygonCursor)
          const NFTWithOwner = NFTs.map((data) => {
            return { ...data, ownerWalletAddress: walletAddress }
          }).filter((nft) => {
            return (
              this.props.hiddenItems.findIndex(
                (item) =>
                  item.tokenID === nft.data.identifier &&
                  item.contractAddress === nft.data.contract &&
                  item.walletAddress === nft.ownerWalletAddress
              ) < 0
            )
          })
          this.setState({
            featuredPolygonNFTs: [...this.state.featuredPolygonNFTs, ...NFTWithOwner],
            isPolygonLoading: false,
            polygonCursor: cursor,
            hasMoreItems: {
              ...this.state.hasMoreItems,
              [`polygon-${walletAddress}`]: NFTs.length === 50
            }
          })
          if (!cursor) {
            this.setState({
              hasMoreItems: {
                ...this.state.hasMoreItems,
                [`polygon-${walletAddress}`]: false
              }
            })
          }
        } catch (err) {
          this.setState({ isPolygonLoading: false, hasMoreItems: false })
        }
      }
    })
  }

  async loadAvalanche(walletAddress) {
    this.setState({ isAvalancheLoading: true }, async () => {
        try {
          const [avalancheNFTs, cursor] = await loadAvalancheNFTs(walletAddress, this.state.avalancheCursor);
  
          const NFTWithOwner = avalancheNFTs
          .map((data) => {
            return { ...data, ownerWalletAddress: walletAddress };
          })
          .filter((nft) => {
            return (
              this.props.hiddenItems.findIndex(
                (item) =>
                  item.contract === nft.data.address && // Update this condition based on the new structure
                  item.walletAddress === nft.ownerWalletAddress
              ) < 0
            );
          });
  
          this.setState({
            featuredAvalancheNFTs: [...this.state.featuredAvalancheNFTs, ...NFTWithOwner],
            isAvalancheLoading: false,
            avalancheCursor: cursor,
            hasMoreItems: {
              ...this.state.hasMoreItems,
              [`avalanche-${walletAddress}`]: avalancheNFTs.length === 50
            }
          });
        } catch (error) {
          this.setState({ isAvalancheLoading: false, hasMoreItems: false });
          console.error('Error fetching Avalanche NFT data:', error);
        }
      }
    );
  }
  

  handleShowNetworkChanged = (network) => {
    const networkStateKey = `show${network}`;
    const localStorageKey = `Show${network}AllNFT`;
    const updatedState = !this.state[networkStateKey];
  
    this.setState({ [networkStateKey]: updatedState });
    localStorage.setItem(localStorageKey, JSON.stringify(updatedState));
  };
  
  // Usage example
  // onClick={() => this.handleShowNetworkChanged('Ethereum')}
  

  formatDate(date) {
    const parts = date.split(' ')
    const d = new Date(Date.parse(parts[0] + 'T' + parts[1]))
    const month = d.getMonth() + 1
    const day = d.getDate()
    const year = d.getFullYear()
    return month + '/' + day + '/' + year
  }

  async getTopshotAccount(address) {
    const resp = await fcl.send([
      fcl.script`
    import TopShot from 0x0b2a3299cc857e29
    import Market from 0xc1e4f4f4c4257510
    pub struct TopshotAccount {
      pub var momentIDs: [UInt64]
      pub var saleMomentIDs: [UInt64]
      init(momentIDs: [UInt64], saleMomentIDs: [UInt64]) {
        self.momentIDs = momentIDs
        self.saleMomentIDs = saleMomentIDs
      }
    }
    pub fun main(): TopshotAccount {
    let acct = getAccount(0x${address})
    let collectionRef = acct.getCapability(/public/MomentCollection)!
                  .borrow<&{TopShot.MomentCollectionPublic}>()!
    let momentIDs = collectionRef.getIDs()
    var saleMomentIDs: [UInt64] = []
    let salePublic = acct.getCapability(/public/topshotSaleCollection)
    if salePublic!.check<&{Market.SalePublic}>(){
      let saleCollectionRef = salePublic!.borrow<&{Market.SalePublic}>() ?? panic("Could not borrow capability from public collection")
      saleMomentIDs = saleCollectionRef.getIDs()  
    }
    return TopshotAccount(momentIDs: momentIDs, saleMomentIDs: saleMomentIDs)
  }  `
    ])
    return fcl.decode(resp)
  }

  async getMoments(address, momentIDs) {
    if (momentIDs && momentIDs.length === 0) {
      return []
    }
    const resp = await fcl.send([
      fcl.script`
    import TopShot from 0x0b2a3299cc857e29
    pub struct Moment {
      pub var id: UInt64?
      pub var playId: UInt32?
      pub var meta: TopShot.MomentData?
      pub var play: {String: String}?
      pub var setId: UInt32?
      pub var setName: String?
      pub var serialNumber: UInt32?
      init(_ moment: &TopShot.NFT?) {
        self.id = moment?.id
        self.meta = moment?.data
        self.playId = moment?.data?.playID
        self.play = nil
        self.play = TopShot.getPlayMetaData(playID: self.playId!)
        self.setId = moment?.data?.setID
        self.setName = nil
        self.setName = TopShot.getSetName(setID: self.setId!)
        self.serialNumber = nil
        self.serialNumber = moment?.data?.serialNumber
      }
    }
    pub fun main(momentIDs: [UInt64]): [Moment] {
    let acct = getAccount(0x${address})
    let collectionRef = acct.getCapability(/public/MomentCollection)!
                  .borrow<&{TopShot.MomentCollectionPublic}>()!
      var moments: [Moment] = []
      for momentID in momentIDs {
        moments.append(Moment(collectionRef.borrowMoment(id: momentID)))
      }
      return moments
  }  `,
      fcl.args([fcl.arg(momentIDs, t.Array(t.UInt64))])
    ])
    return fcl.decode(resp)
  }

  async loadNBATopShot(page, walletAddress) {
    this.setState({ isTopShotLoading: true }, () => {
      if (walletAddress) {
        loadAllMoments(walletAddress, page).then((moments) => {
          moments.forEach((moment) => {
            if (this.props.hiddenItems.findIndex((item) => item.tokenID === moment.flowID) < 0)
              this.setState({
                featuredTopShotNFTs: [
                  ...this.state.featuredTopShotNFTs,
                  { ...moment, ownerWalletAddress: walletAddress }
                ],
                isTopShotLoading: false
              })
          })
          if (moments.length < 50) {
            this.setState({
              hasMoreItems: {
                [`topshot-${walletAddress}`]: false
              }
            })
          }
        })
      } else {
        this.setState({ isTopShotLoading: false })
      }
    })
  }


  async loadEthereum(page, walletAddress, cursor) {
    this.setState({ isEthereumLoading: true }, async () => {
      if (walletAddress) {
        const responseCursor = this.state.ethCursors[walletAddress] || cursor;
        const resp = await loadEthereumNFTs(walletAddress, responseCursor);
        const _ethereumNFTs = resp.nfts;
        const nextCursor = resp.next;
  
        for (const asset of _ethereumNFTs) {
          const isHidden = this.props.hiddenItems.findIndex(
            (item) => item.tokenID === asset.identifier && item.contractAddress === asset.contract
          ) >= 0;
  
          if (isHidden) continue;
  

      const isAirdrop = await this.isAirdroppedNFT(asset.contract, asset.identifier);
      if (isAirdrop) {
          console.log(`Contract: ${asset.contract}, Identifier: ${asset.identifier}`);
          continue;
      }

  
          let name = asset.name || asset.collection?.name;
          let description = asset.description || asset.collection?.description;
          let imageUrl = asset.image_url || '/empty-media.png';
          let animationUrl = asset.animation_url;
  
          if (imageUrl.toLowerCase().endsWith('.mp4')) {
            animationUrl = imageUrl;
            imageUrl = asset.image_thumbnail_url;
          }
  
          const schemaName = asset.token_standard;
          let currentPrice = null;
          let quantity = null;
          let listedByUser = false;
          let paymentToken = null;
  
          if (asset.sell_orders && asset.sell_orders.length > 0) {
            currentPrice = asset.sell_orders[0].current_price;
            paymentToken = asset.sell_orders[0].payment_token_contract.symbol;
            quantity = asset.sell_orders[0].quantity;
            listedByUser = asset.sell_orders[0].maker.address.toLowerCase() === walletAddress.toLowerCase();
          }
  
          this.setState((prevState) => ({
            featuredEthereumNFTs: [
              ...prevState.featuredEthereumNFTs,
              {
                type: 'Ethereum',
                data: asset,
                contractAddress: asset.contract,
                tokenID: asset.identifier,
                name: name,
                description: description,
                image: imageUrl,
                animation: animationUrl,
                schemaName: schemaName,
                currentPrice: currentPrice,
                quantity: quantity,
                listedByUser: listedByUser,
                paymentToken: paymentToken,
                ownerWalletAddress: walletAddress
              }
            ],
          }));
        }
  
        this.setState((prevState) => ({
          isEthereumLoading: false,
          hasMoreItems: {
            ...prevState.hasMoreItems,
            [`eth-${walletAddress}`]: _ethereumNFTs.length === 50
          },
          ethCursors: {
            ...prevState.ethCursors,
            [walletAddress]: nextCursor
          }
        }));
      } else {
        this.setState({ isEthereumLoading: false });
      }
    });
  }
  
  
  handleFilterChange(e) {
    this.setState({
      pageKey: this.state.pageKey + 1,
      filter: e.target.value
    })
  }

  componentDidUpdate(pastProps) {
    if (pastProps.pins !== this.props.pins) {
      this.setState({ pageKey: this.state.pageKey + 1 })
    }
  }

  handleBuy(data) {
    this.props.functions.handleBuy({
      contractAddress: data.contractAddress,
      tokenID: data.tokenID,
      listingPrice: data.listingPrice,
      listingCurrency: data.listingCurrency,
      name: data.name,
      ownerWalletAddress: data.walletAddress,
      order: data.order,
    })
  }

  handleSell(data) {
    this.props.functions.handleSell({
      contractAddress: data.contractAddress,
      tokenID: data.tokenID,
      name: data.name,
      schemaName: data.schemaName,
      ownerWalletAddress: data.walletAddress
    })
  }

  handleUnlist(data) {
    this.props.functions.handleUnlist({
      contractAddress: data.contractAddress,
      tokenID: data.tokenID,
      name: data.name,
      listingPrice: data.listingPrice,
      ownerWalletAddress: data.walletAddress,
      order: data.order,
    })
  }

  async handlePin(walletAddress, contractAddress, tokenID, type) {
    return await this.props.functions.handlePin(type, { walletAddress, contractAddress, tokenID })
  }

  async handleUnpin(pinID) {
    return await this.props.functions.handleUnpin(pinID)
  }

  async handleHide(walletAddress, contractAddress, tokenID, type) {
    return await this.props.functions.handleHide(type, { walletAddress, contractAddress, tokenID })
  }

  render() {
    const functions = {
      ...this.props.functions,
      handlePin: this.handlePin,
      handleUnpin: this.handleUnpin,
      handleBuy: this.handleBuy,
      handleSell: this.handleSell,
      handleUnlist: this.handleUnlist,
      handleHide: this.handleHide,
      handleWaxSell: this.props.functions.handleWaxSell,
      handleWaxCancelSell: this.props.functions.handleWaxCancelSell,
      handleWaxAuction: this.props.functions.handleWaxAuction,
      handleWaxCancelAuction: this.props.functions.handleWaxCancelAuction,
    }

    const renderNetworkCheckbox = (id, name, handler, isChecked) => (
      <>
        <input
          type="checkbox"
          id={id}
          name={id}
          onClick={handler}
          checked={isChecked}
          style={id === 'ethereum' ? { marginTop: '40px' } : {}}
        />
        <label htmlFor={id} style={{ marginRight: '30px', marginLeft: '5px' }}>{name}</label>
      </>
    );
    
    const networkSelector = (
      <div className="container">
        <center>
          {this.state.featuredEthereumNFTs.length > 0 && renderNetworkCheckbox('ethereum', 'Ethereum', () => this.handleShowNetworkChanged('Ethereum'), this.state.showEthereum)}
          {this.state.featuredTopShotNFTs.length > 0 && renderNetworkCheckbox('topshot', 'TopShot NBA', () => this.handleShowNetworkChanged('TopShot'), this.state.showTopShot)}
          {this.state.featuredPolygonNFTs.length > 0 && renderNetworkCheckbox('polygon', 'Polygon', () => this.handleShowNetworkChanged('Polygon'), this.state.showPolygon)}
          {this.state.featuredSolanaNFTs.length > 0 && renderNetworkCheckbox('solana', 'Solana', () => this.handleShowNetworkChanged('Solana'), this.state.showSolana)}
          {this.state.featuredWaxNFTs.length > 0 && renderNetworkCheckbox('wax', 'Wax', () => this.handleShowNetworkChanged('Wax'), this.state.showWax)}
          {this.state.featuredTezosNFTs.length > 0 && renderNetworkCheckbox('tezos', 'Tezos', () => this.handleShowNetworkChanged('Tezos'), this.state.showTezos)}
          {this.state.featuredAvalancheNFTs.length > 0 && renderNetworkCheckbox('avalanche', 'Avalanche', () => this.handleShowNetworkChanged('Avalanche'), this.state.showAvalanche)}
        </center>
      </div>
    );
    

    // TODO: Broken selections / filters for ETH NFTs
    /*let filters = (
      <select
        style={{ color: '#00a8ff', display: 'inline-block', textAlign: 'center' }}
        onChange={this.handleFilterChange}
      >
        <option key="option1" value="pk">
          Most Recent
        </option>
        <option key="option2" value="sale_date">
          For Sale
        </option>
        <option key="option3" value="sale_count">
          Number of Sales
        </option>
        <option key="option4" value="sale_price">
          Sale Price
        </option>
        <option key="option5" value="visitor_count">
          Most Viewed
        </option>
      </select>
    )
    let selections = (
      <div className="filters">
        <center>{this.state.selectedWallet.type === 'Ethereum' && filters}</center>
      </div>
    )*/

    const featuredEthereumNFTComponent = this.state.featuredEthereumNFTs.length > 0 && (
      <div className="container">
        <div className="heading">
          <span className="title">Ethereum NFTs</span>
        </div>
        {/*selections*/}
        {this.state.isEthereumLoading && <>Loading Ethereum NFTs...</>}
        {this.props.isLoadingHiddenItem && this.props.lastHideActionType === 'Ethereum' ? (
          <>Update NFT List...</>
        ) : (
          <div className="NFT-container">
            {this.state.featuredEthereumNFTs.map((nft, idx) => {
              const associatedPin = this.loadAssociatedPin(
                nft.ownerWalletAddress,
                nft.contractAddress,
                nft.tokenID
              )
              var pinned = false
              var id = null
              if (associatedPin.length > 0) {
                pinned = true
                id = associatedPin[0].id
              }
              return (
                <EthereumNFTNew
                  key={idx}
                  data={nft.data}
                  username={this.props.username}
                  pinned={pinned}
                  pinID={id}
                  walletAddress={nft.ownerWalletAddress}
                  index={idx}
                  functions={functions}
                />
              )
            })}
          </div>
        )}
        {this.state.featuredEthereumNFTs.length > 0 && this.checkHasMoreItems('Ethereum') && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px' }}
              onClick={() => this.loadMoreNFTs('Ethereum')}
            >
              {this.state.isEthereumLoading ? 'Loading...' : 'Load More'}
            </button>
          </center>
        )}
      </div>
    )

    const featuredPolygonNFTComponent = this.state.featuredPolygonNFTs.length > 0 && (
      <div className="container">
        <div className="heading">
          <span className="title">Polygon NFTs</span>
        </div>
        {this.state.isPolygonLoading && <>Loading Polygon NFTs...</>}
        {this.props.isLoadingHiddenItem && this.props.lastHideActionType === 'Polygon' ? (
          <>Update NFT List...</>
        ) : (
          <div className="NFT-container">
            {this.state.featuredPolygonNFTs.map((nft, idx) => {
              const associatedPin = this.loadAssociatedPin(
                //nft.ownerWalletAddress,
                nft.data.contract,
                nft.data.identifier
              )
              var pinned = false
              var id = null
              if (associatedPin.length > 0) {
                pinned = true
                id = associatedPin[0].id
              }
              return (
                <PolygonNFT
                  key={idx}
                  data={nft.data}
                  username={this.props.username}
                  pinned={pinned}
                  pinID={id}
                  walletAddress={nft.ownerWalletAddress}
                  index={idx}
                  functions={functions}
                />
              )
            })}
          </div>
        )}
        {this.state.featuredPolygonNFTs.length > 0 && this.checkHasMoreItems('Polygon') && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px' }}
              onClick={() => this.loadMoreNFTs('Polygon')}
            >
              Load More
            </button>
          </center>
        )}
      </div>
    )

    const featuredTopShotNFTComponent = this.state.featuredTopShotNFTs.length > 0 && (
      <div className="container">
        <div className="heading">
          <span className="title">TopShotNBA NFTs</span>
        </div>
        {this.state.isTopShotLoading && <>Loading TopShotNBA NFTs...</>}
        {this.props.isLoadingHiddenItem && this.props.lastHideActionType === 'NBA Top Shot' ? (
          <>Update NFT List...</>
        ) : (
          <div className="NFT-container">
            {this.state.featuredTopShotNFTs.map((nft, idx) => {
              const associatedPin = this.loadAssociatedPin(
                nft.ownerWalletAddress,
                '0x0b2a3299cc857e29',
                nft.flow_id
              )
              var pinned = false
              var pinID = null
              if (associatedPin.length > 0) {
                pinned = true
                pinID = associatedPin[0].id
              }

              return (
                <TopShotNFTNew
                  key={idx}
                  data={nft.data}
                  username={this.props.username}
                  pinned={pinned}
                  pinID={pinID}
                  walletAddress={nft.ownerWalletAddress}
                  index={idx}
                  functions={functions}
                />
              )
            })}
          </div>
        )}
        {this.state.featuredTopShotNFTs.length > 0 && this.checkHasMoreItems('NBA Top Shot') && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px' }}
              onClick={() => this.loadMoreNFTs('NBA Top Shot')}
            >
              Load More
            </button>
          </center>
        )}
      </div>
    )

    const featuredSolanaNFTComponent = this.state.featuredSolanaNFTs.length > 0 && (
      <div className="container">
        <div className="heading">
          <span className="title">Solana NFTs</span>
        </div>
        {this.state.isSolanaLoading === 0 && <>Loading Solana NFTs...</>}
        {this.props.isLoadingHiddenItem && this.props.lastHideActionType === 'Solana' ? (
          <>Update NFT List...</>
        ) : (
          <div className="NFT-container">
            {this.state.featuredSolanaNFTs.map((nft, idx) => {
              const associatedPin = this.loadAssociatedSolanaPin(nft.ownerWalletAddress, nft.mint)
              var pinned = false
              var id = null
              if (associatedPin.length > 0) {
                pinned = true
                id = associatedPin[0].id
              }
              if ( !nft.imageUrl ) return;
              return (
                <SolanaNFT
                  key={idx}
                  data={nft}
                  username={this.props.username}
                  walletAddress={nft.ownerWalletAddress}
                  index={idx}
                  functions={functions}
                  pinned={pinned}
                  pinID={id}
                />
              )
            })}
          </div>
        )}
        {this.state.featuredSolanaNFTs.length > 0 && this.checkHasMoreItems('Solana') && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px' }}
              onClick={() => this.loadMoreNFTs('Solana')}
            >
              Load More
            </button>
          </center>
        )}
      </div>
    )

    const featuredWaxNFTComponent = this.state.featuredWaxNFTs.length > 0 && (
      <div className="container">
        <div className="heading">
          <span className="title">Wax NFTs</span>
        </div>
        {this.state.isWaxLoading === 0 && <>Loading Wax NFTs...</>}
        {this.props.isLoadingHiddenItem && this.props.lastHideActionType === 'Wax' ? (
          <>Update NFT List...</>
        ) : (
          <div className="NFT-container">
            {this.state.featuredWaxNFTs.map((nft, idx) => {
              const associatedPin = this.props.pins.filter((pin) => {
                return pin.walletAddress === nft.owner && pin.tokenID === nft.asset_id
              })
              var pinned = false
              var id = null
              if (associatedPin.length > 0) {
                pinned = true
                id = associatedPin[0].id
              }
              if ( !nft.data.img && !nft.data.video) return;
              return (
                <WaxNFTNew
                  key={idx}
                  data={nft}
                  walletAddress={nft.owner}
                  username={this.props.username}
                  functions={functions}
                />
              )
            })}
          </div>
        )}
        {this.state.featuredWaxNFTs.length > 0 && this.checkHasMoreItems('Wax') && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px' }}
              onClick={() => this.loadMoreNFTs('Wax')}
            >
              Load More
            </button>
          </center>
        )}
      </div>
    )

    const featuredTezosNFTComponent = this.state.featuredTezosNFTs.length > 0 && (
      <div className="container">
        <div className="heading">
          <span className="title">Tezos NFTs</span>
        </div>
        {this.state.isTezosLoading === 0 && <>Loading Tezos NFTs...</>}
        {this.props.isLoadingHiddenItem && this.props.lastHideActionType === 'Tezos' ? (
          <>Update NFT List...</>
        ) : (
          <div className="NFT-container">
            {this.state.featuredTezosNFTs.map((nft, idx) => {
              const associatedPin = this.props.pins.filter((pin) => {
                return pin.walletAddress === nft.ownerWalletAddress && pin.tokenID === nft.id
              })
              var pinned = false
              var id = null
              if (associatedPin.length > 0) {
                pinned = true
                id = associatedPin[0].id
              }
              return (
                <TezosNFTNew
                  key={idx}
                  data={nft}
                  walletAddress={nft.ownerWalletAddress}
                  username={this.props.username}
                  functions={functions}
                  pinned={pinned}
                  pinID={id}
                  index={idx}
                />
              )
            })}
          </div>
        )}
        {this.state.featuredTezosNFTs.length > 0 && this.checkHasMoreItems('Tezos') && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px' }}
              onClick={() => this.loadMoreNFTs('Tezos')}
            >
              Load More
            </button>
          </center>
        )}
      </div>
    )

    const featuredAvalancheNFTComponent = this.state.featuredAvalancheNFTs.length > 0 && (
      <div className="container">
        <div className="heading">
          <span className="title">Avalanche NFTs</span>
        </div>
        {this.state.isAvalancheLoading && <>Loading Avalanche NFTs...</>}
        {this.props.isLoadingHiddenItem && this.props.lastHideActionType === 'Avalanche' ? (
          <>Update NFT List...</>
        ) : (
          <div className="NFT-container">
            {this.state.featuredAvalancheNFTs.map((nft, idx) => {
              const associatedPin = this.loadAssociatedPin(
                //nft.ownerWalletAddress,
                nft.data.address,
                nft.data.tokenid
              )
              var pinned = false
              var id = null
              if (associatedPin.length > 0) {
                pinned = true
                id = associatedPin[0].id
              }
              return (
                <AvalancheNFT
                  key={idx}
                  data={nft.data}
                  username={this.props.username}
                  pinned={pinned}
                  pinID={id}
                  walletAddress={nft.ownerWalletAddress}
                  index={idx}
                  functions={functions}
                  isClickable={true}
                />
              )
            })}
          </div>
        )}
        {this.state.featuredAvalancheNFTs.length > 0 && this.checkHasMoreItems('Avalanche') && (
          <center>
            <button
              className="clear"
              style={{ marginTop: '20px' }}
              onClick={() => this.loadMoreNFTs('Avalanche')}
            >
              Load More
            </button>
          </center>
        )}
      </div>
    )

    const content = (
      <div>
        <h1
          style={{
            marginTop: '100px',
            marginLeft: '20px',
            marginBottom: '10px'
          }}
        >
          All NFTs
        </h1>
      </div>
    )
    return (
      <div>
        {content}

        {networkSelector}
        {this.state.showEthereum && featuredEthereumNFTComponent}
        {this.state.showTopShot && featuredTopShotNFTComponent}
        {this.state.showPolygon && featuredPolygonNFTComponent}
        {this.state.showSolana && featuredSolanaNFTComponent}
        {this.state.showWax && featuredWaxNFTComponent}
        {this.state.showTezos && featuredTezosNFTComponent}
        {this.state.showAvalanche && featuredAvalancheNFTComponent}
      </div>
    )
  }
}

export default AllNFTs
