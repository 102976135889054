import NavBar from './NavBar'
import './About.css'
import { inputStyle } from './generalStyles'
import { useState } from 'react'


export default function About() {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');


    return (
        <div className='top-container-about' >
            <NavBar showGetStarted={true} />
            <div className="about-container">
                <div className="about-left-hand-side">
                    <div className="landing-box-container">
                        <h1 className='header-text'>About Lazy</h1>
                        <p className='paragraph-text'>Lazy.com is the easiest way to display your NFTs — that is our laser focused mission. We promise to make it absolutely simple to connect your wallets, regardless the blockchain, and display your NFTs in a safe and secure way.
                            <br /><br />
                            Founded by Mark Cuban in 2021, Lazy.com serves tens of thousands of NFT collectors around the world.</p>
                        <div className="email-started-container">
                            <button onClick={() => (document.location.href = '/signup')} type="submit" style={{ paddingInline: 40, width: '100%', marginRight: 20 }}>
                                Show off your NFT collection
                            </button>
                        </div>
                    </div>
                </div>
                <div className="about-right-hand-side">
                    {form()}
                </div>
            </div>
        </div>
    )

    function form() {
        return (
            <div className='contact-us-box'>
                <p className='contact-us-text'>Contact Us</p>
                <div className='gray-contact-form'>
                    <div className='name-email-div'>
                        <input value={username} onChange={e => setUsername(e.target.value)} name="username" type="text" placeholder="Name" style={{ width: '100%', ...inputStyle, minWidth: 100, marginBottom: 0 }} />
                        <input value={email} onChange={e => setEmail(e.target.value)} name="email" type="email" placeholder="Email" style={{ width: '100%', ...inputStyle, minWidth: 100, marginBottom: 0 }} />
                    </div>
                    <textarea value={message} onChange={e => setMessage(e.target.value)} name="message" type="text" placeholder="Let us know how we can help" style={{ width: '100%', flexGrow: 1, ...inputStyle, paddingTop: 16, minWidth: 100, marginBottom: 0 }} />
                    <button onClick={openEmailWindow} type="submit" style={{ width: '100%' }}>
                        Send Message
                    </button>
                </div>

            </div>
        )
    }

    function openEmailWindow() {
        window.location = `mailto:info@lazy.com?subject=${username}&body=${message}`
    }
}
