import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import firebase from '../../firebase'

class Sidebar extends React.Component {
  handleSignOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear()
        window.location.href = '/'
      })
  }

  render() {
    const username = localStorage.getItem('username')
    const portfolioLink = '/' + username
    const hiddenNfts = `/${username}/hiddenNfts`

    if (this.props.currentUser) {
      return (
        <Menu disableAutoFocus right {...this.props}>
          <a className="menu-item" href="/wallets">
            My Settings
          </a>
          <a className="menu-item" href={portfolioLink}>
            My Portfolio
          </a>
          <a className="menu-item" href={hiddenNfts}>
            Hidden NFTs
          </a>
          <a className="menu-item" href="/faq">
            FAQ
          </a>
          <a className="menu-item" onClick={this.handleSignOut} style={{ cursor: 'pointer' }}>
            Sign Out
          </a>
        </Menu>
      )
    }
    return (
      <Menu disableAutoFocus right {...this.props}>
        <a
          className='menu-item'
          href="/faq"
        >
          FAQ
        </a>
        <a
          className='menu-item'
          href="/about"
        >
          About
        </a>
        <a
          className='menu-item'
          onClick={() => (document.location.href = '/signup')}
        >
          Create your showcase
        </a>
        <a
          className='menu-item'
          onClick={() => (document.location.href = '/login')}
        >
          Sign In
        </a>
      </Menu>
    )
  }
}

export default Sidebar
