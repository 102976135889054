import React from 'react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
class PromptModal extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    var options = null
    if (this.props.options) {
      options = this.props.options.map((action, idx) => {
        if (action === 'Solana') {
          return <WalletMultiButton key={idx} onClick={this.props.handlers[idx]}>Solana</WalletMultiButton>
        } else {
          return (
            <button key={idx} onClick={this.props.handlers[idx]}>
              {action}
            </button>
          )
        }
      })
    }
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">{this.props.title}</span>
          <span className="description">{this.props.message}</span>
          {options}
          <button className="clear" onClick={this.props.handleClose}>
            {this.props.closeLabel}
          </button>
        </div>
      </div>
    )
  }
}

PromptModal.defaultProps = {
  closeLabel: 'Close'
}

export default PromptModal
