import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useEffect, useState } from 'react'
import firebase from '../../firebase'
import ImageWSkeleton from '../ImageWSkeleton'
import VideoWSkeleton from '../VideoWSkeleton'

export default function AvalancheNFTWrapper(props) {
  const dataExists = props.data;

  // Check if either imageUrl or animationURL exist
  const imageExists = dataExists && (props.data.image_url || props.data.animation_url);

  if (!dataExists || dataExists.message === 'NFT not found.' || !imageExists) {
    return null;
  }
  
  return <AvalancheNFT {...props} />;
}


function AvalancheNFT(props) {
  
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.pinID
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const walletAddress = props.walletAddress;
  const contractAddress = props.data.contract;
  const tokenID = props.data.identifier;
  const caption = props.caption
  const schemaName = props.data.token_standard

  const [name, setName] = useState('')
  const [animationURL, setAnimationURL] = useState('')
  const [imageURL, setImageURL] = useState('')
  const [isAuthor, setIsAuthor] = useState(false)
  const [menuShown, setMenuShown] = useState(false)
  const [pinned, setPinned] = useState(props.pinned)
  const [pinnedNFTs, setPinnedNFTs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    const tokenID = props.data.identifier;

    const isAuthor = checkIfAuthor();
    const name = loadName();
    const animationURL = loadAnimationURL();
    const imageURL = loadImageURL();
  
    setIsAuthor(isAuthor);
    setName(name);
    setAnimationURL(animationURL);
    setImageURL(imageURL);
    setIsLoading(false);
}, [props.data, props.pinned, props.caption]);
  
  const joepegsUrl = `https://joepegs.com/item/avalanche/${contractAddress}/${tokenID}/`;

  const loadName = () => {
    let _name = props.data.name;
    if (_name === null || _name === undefined) {
      _name = props.data.contract ? props.data.name : tokenID;
    }
    //console.log('Loaded name:', _name);  // Log the loaded name
    return _name;
  };
  
  const loadAnimationURL = () => {
    let _animationURL = props.data.animation_url || props.data.animation;
    if (_animationURL === null && props.data.contract) {
      _animationURL = props.data.image_url;
    }
  
    // If the animation URL starts with 'ipfs://', replace it with the Cloudflare IPFS gateway URL
    if (_animationURL && _animationURL.startsWith('ipfs://')) {
      _animationURL = _animationURL.replace('ipfs://', 'https://brown-useful-echidna-164.mypinata.cloud/ipfs/');
      _animationURL = _animationURL + "?pinataGatewayToken=iiMhhC50yYQCHORNpXyJpEQq3iBDvctIVck6d33rgaaUaJDHYqTFVDfA3zzm3Uu0";
  }
  
  
    if (props.data.image_url && typeof props.data.image_url === 'string' && props.data.image_url.toLowerCase().endsWith('.mp4')) {
      _animationURL = props.data.image_url;
    }

    // If the image URL is the specified URL, set it as the animation URL
 //   if (props.data.image && props.data.metadata.image === 'https://campfire.mypinata.cloud/ipfs/QmRwgxQuwXAi3m8Li9xey81B7uTMpopSUdj2QSVT3WNcbS') {
   //   _animationURL = props.data.metadata.image;
   // }
    setAnimationURL(_animationURL); 
    //console.log('Loaded animation URL:', _animationURL);  // Log the loaded animation URL
    return _animationURL;
  };
    
  const loadImageURL = () => {
    if (Object.keys(props.data).length === 0) {
      return null;
    }
  
    let _imageURL = props.data.image_url;
    if (_imageURL === null || _imageURL === undefined) {
      _imageURL = props.data.image_preview_url;
    }
  
    if (_imageURL === null && props.data.asset_contract) {
      _imageURL = props.data.asset_contract.image_url;
    }
  
    if (
      (_imageURL === null || _imageURL === undefined) &&
      (animationURL === null || animationURL === undefined)
    ) {
      _imageURL = '/empty-media.png';
    }

    if (_imageURL && _imageURL.includes("http://api.rektmonkeez.com/api/image/")) {
      let imagePath = _imageURL.split('http://api.rektmonkeez.com/api/image/')[1];
      _imageURL = `https://brown-useful-echidna-164.mypinata.cloud/ipfs/bafybeigqcdcrro5vij4lf43mweekmqwcnc6nylcipeyirrwzoox4h7xbsu/${imagePath}.png?pinataGatewayToken=iiMhhC50yYQCHORNpXyJpEQq3iBDvctIVck6d33rgaaUaJDHYqTFVDfA3zzm3Uu0`;
    }
  
    if (_imageURL && _imageURL.startsWith("ipfs://")) {
      const ipfsHash = _imageURL.replace("ipfs://", "");
      _imageURL = `https://brown-useful-echidna-164.mypinata.cloud/ipfs/${ipfsHash}`;
      _imageURL = _imageURL + "?pinataGatewayToken=iiMhhC50yYQCHORNpXyJpEQq3iBDvctIVck6d33rgaaUaJDHYqTFVDfA3zzm3Uu0";
    }

    if (_imageURL && _imageURL.includes('campfire.mypinata') ) {
      const ipfsHash = _imageURL.split('ipfs/')[1];
      _imageURL = `https://brown-useful-echidna-164.mypinata.cloud/ipfs/${ipfsHash}`;
      _imageURL = _imageURL + "?pinataGatewayToken=iiMhhC50yYQCHORNpXyJpEQq3iBDvctIVck6d33rgaaUaJDHYqTFVDfA3zzm3Uu0";
    }
    setImageURL(_imageURL)
   // console.log('Loaded image URL:', _imageURL);  // Log the loaded image URL
    return _imageURL;
  };

  
  
  
  const loadMedia = () => {
    let validAnimationURL = animationURL;
    
    if (validAnimationURL && validAnimationURL.startsWith('ipfs://')) {
      validAnimationURL = validAnimationURL.replace('ipfs://', 'https://brown-useful-echidna-164.mypinata.cloud/ipfs/');
      validAnimationURL = validAnimationURL + "?pinataGatewayToken=iiMhhC50yYQCHORNpXyJpEQq3iBDvctIVck6d33rgaaUaJDHYqTFVDfA3zzm3Uu0";
    }
    if (validAnimationURL && validAnimationURL.includes('campfire.mypinata')) {
      validAnimationURL = validAnimationURL.replace('campfire.mypinata', 'brown-useful-echidna-164.mypinata');
      validAnimationURL = validAnimationURL + "?pinataGatewayToken=iiMhhC50yYQCHORNpXyJpEQq3iBDvctIVck6d33rgaaUaJDHYqTFVDfA3zzm3Uu0";
    }
    if (isLoading) {
      // Return gradient if NFT is still loading
      return <div className="gradient-placeholder"></div>;
    } else if (validAnimationURL) {
      if (imageURL === validAnimationURL || !imageURL) {
        return <VideoWSkeleton width={320} height={320} videoSrc={validAnimationURL} imgClassName='video' />
      }
      //console.log('Loading video for NFT:', props.data);  // Log when a video is being loaded
      return <VideoWSkeleton width={320} height={320} poster={imageURL} videoSrc={validAnimationURL} imgClassName='video' />
    } else {
      //console.log('Loading image for NFT:', props.data);  // Log when an image is being loaded
      return <ImageWSkeleton imgClassName='image' width={320} height={320} imgSrc={imageURL} />
    }
  }
  

  const loadMenu = () => {
    let _menu
    let _menuButton
    if (menuShown) {
      const viewerIsAuthor = checkIfAuthor()
      const viewerIsAdmin = isAdmin()
      _menu = (
        <div className="NFT-menu" style={{ display: 'inline-block' }}>
          {viewerIsAuthor && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleHide}>
              Hide
            </div>
          )}
          {viewerIsAuthor && props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnhide}>
              Unhide
            </div>
          )}
          {viewerIsAuthor && !pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handlePin}>
              Pin
            </div>
          )}
          {viewerIsAuthor && pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnpin}>
              Unpin
            </div>
          )}
          {viewerIsAdmin && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={feature}>
              Feature
            </div>
          )}
          {viewerIsAuthor && <hr />}
          <div className="NFT-menu-option" onClick={() => viewOnRabbithole('rabbithole')}>
            Rabbithole
          </div>
          <div className="NFT-menu-option" onClick={() => viewOnRabbithole('bizarroworld')}>
            Bizarroworld
          </div>
          <div className="NFT-menu-option" onClick={viewOnOpenSea}>
            View on OpenSea
          </div>
          <div className="NFT-menu-option" onClick={() => window.open(joepegsUrl, "_blank")}>
          View on JoePegs
          </div>

          {viewerIsAuthor && <div className="NFT-menu-option" onClick={() => props.functions.setAsProfilePicture(imageURL, animationURL)}>
            Set as Profile Picture
          </div>}
        </div>
      )
      _menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-close-icon.png')" }}
        ></button>
      )
    } else {
      _menu = <div className="NFT-menu" style={{ display: 'none' }}></div>
      _menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-more-icon.png')" }}
        ></button>
      )
    }
    return [_menu, _menuButton]
  }

  const showOptions = () => {
    setMenuShown(!menuShown)
  }

  const viewDetail = () => {
    if (props.isClickable !== false) {
      setMenuShown(false);
      document.location.href =
        '/avalanche/' +
        contractAddress +
        '/' +
        tokenID;
    }
  };
  

  const viewOnRabbithole = (to) => {
    setMenuShown(false)

    window.location.href = `/${to}` + "?" + new URLSearchParams({
      tokenID: tokenID, 
      contractAddress: contractAddress,
      url: btoa(imageURL),
      animationUrl: btoa(animationURL),
      chain: 'Avalanche'
    }).toString()
  };

  const viewOnOpenSea = () => {
    setMenuShown(false)
  
    // log firebase event
    let contract_address = props.data.contract ? '_' + props.data.contract.toLowerCase() : '';
    let token_id = props.data.tokenId ? '_' + props.data.tokenId.toLowerCase() : '';
    let wallet_address = props.data.metadata && props.data.metadata.owner ? '_' + props.data.metadata.owner.toLowerCase() : '';
  
    firebase.analytics().logEvent('opensea_click', {
      contract_address: contract_address,
      token_id: token_id,
      wallet_address: wallet_address
    })
  
    if (!process.env.REACT_APP_TESTING) {
      window.open(
        'https://opensea.io/assets/avalanche/' +
          props.data.contract +
          '/' +
          props.data.identifier +
          '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      )
    } else {
      window.open(
        'https://testnets.opensea.io/assets/mumbai/' +
          props.data.contract +
          '/' +
          props.data.tokenId +
          '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      )
    }
  }
  

  const feature = () => {
    setMenuShown(false)
    if (window.confirm('Are you sure you want to feature this NFT?')) {
      const db = firebase.firestore()
      db.collection('featured')
        .doc()
        .set({
          type: 'Avalanche',
          wallet_address: walletAddress,
          contract_address: contractAddress,
          token_id: tokenID,
          created_at: new Date(),
          username: props.username.toLowerCase()
        })
        .then(() => {
          alert('Featured successfully!')
        })
    }
  }

  const checkIfAuthor = () => {
    const username = localStorage.getItem('username')
    if (username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const isAdmin = () => {
    if (firebase.auth().currentUser) {
      if (firebase.auth().currentUser.uid === 'hhoXubahBuWi9o4ifrKjHIVZe843') {
        return true
      }
    }
    return false
  }

  const handlePin = () => {
    setMenuShown(false);
    props.functions.handlePin(walletAddress, contractAddress, tokenID, 'Avalanche').then(
      (value) => {
        setPinned(true);
      },
      (reason) => {
        // handlePin was not successful
      }
    );
  };
  

  const handleHide = async () => {
    setMenuShown(false)
    if (props.functions && props.functions.handleHide) {
      props.functions.handleHide(walletAddress, contractAddress, tokenID, 'Avalanche');
    }
  }
  const handleUnhide = async () => {
    setMenuShown(false)
    props.functions.handleUnhide(props.pinID).then(
      (value) => {
        // handleUnhide was successful
      },
      (reason) => {
        // handleUnhide was not successful
      }
    )
  }

  const handleUnpin = async () => {
    setMenuShown(false)
    props.functions.handleUnpin(props.pinID).then(
      (value) => {
        setPinned(false)
      },
      (reason) => {
        // handleUnpin was not successful
      }
    )
  }

  const media = loadMedia()
  const [menu, menuButton] = loadMenu()
  
  // check if processing
  if (
    props.data.name === null &&
    props.data.description === null &&
    props.data.image_url === null &&
    loadName() === null &&
    loadImageURL() === null
  ) {
    if (name != 'NFT Still Processing...') {
      setName('NFT Still Processing...')
    }
    if (imageURL != '/empty-media.png') {
      setImageURL('/empty-media.png')
    }
  }

  let classes
  const NFTClass = isAuthor ? 'NFT-own' : 'NFT'
  if (props.isDraggable === true) {
    classes = `draggable ${NFTClass}`
  } else {
    classes = `clickable ${NFTClass}`
  }

  //console.log('Rendering NFT with state:', { name, imageURL, animationURL, isAuthor, pinned });

  if (schemaName === 'erc20') return null;

  return (
    <div ref={setNodeRef} className={classes} style={style} {...attributes} {...listeners}>
      {menuButton}
      {menu}
      <div onClick={viewDetail}>
        {media}
        <div className="info">
          <div>
            <span className="name">{name}</span>
            {caption && <div className="caption">"{caption}"</div>}
          </div>
        </div>
      </div>
    </div>
  )
}
