import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useEffect, useState } from 'react'
import ImageWSkeleton from '../ImageWSkeleton'

export default function TopShotNFTNew(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.pinID
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const {
    flowID,
    momentID,
    playerName,
    playCategory,
    dateOfMoment,
    setName,
    teamAtMoment,
    videoURL,
    imageURL
  } = props.data
  const walletAddress = props.walletAddress
  const contractAddress = '0x0b2a3299cc857e29'
  const caption = props.caption

  const [pinned, setPinned] = useState(props.pinned)
  const [isAuthor, setIsAuthor] = useState(false)
  const [menuShown, setMenuShown] = useState(false)

  useEffect(() => {
    setIsAuthor(checkIfAuthor())
  }, [])

  const checkIfAuthor = () => {
    const username = localStorage.getItem('username')
    if (username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const handleMenuClick = () => {
    setMenuShown(!menuShown)
  }

  const handlePin = () => {
    setMenuShown(false)
    props.functions.handlePin(walletAddress, contractAddress, flowID, 'NBA Top Shot').then(
      (value) => {
        setPinned(true)
      },
      (reason) => {
        // handlePin was not successful
      }
    )
  }

  const handleHide = () => {
    setMenuShown(false)
    props.functions.handleHide(walletAddress, contractAddress, flowID, 'NBA Top Shot').then(
      (value) => {
        setPinned(true)
      },
      (reason) => {
        // handlePin was not successful
      }
    )
  }

  const handleUnhide = async () => {
    setMenuShown(false)
    props.functions.handleUnhide(props.pinID).then(
      (value) => {
        // handleUnhide was successful
      },
      (reason) => {
        // handleUnhide was not successful
      }
    )
  }
  const handleUnpin = () => {
    setMenuShown(false)
    props.functions.handleUnpin(props.pinID).then(
      (value) => {
        setPinned(false)
      },
      (reason) => {
        // handleUnpin was not successful
      }
    )
  }

  const handleViewOnTopShot = () => {
    window.open('https://nbatopshot.com/moment/' + momentID)
  }

  const viewOnRabbithole = (to) => {
    setMenuShown(false)

    window.location.href = `/${to}` + "?" + new URLSearchParams({
      tokenID: momentID, 
      //contractAddress: contractAddress,
      walletAddress: walletAddress,
      url: btoa(imageURL),
      chain: 'topshot'
    }).toString()
  };

  const handleViewDetail = () => {
    if (props.isClickable !== false) {
      setMenuShown(false)
      document.location.href = '/topshot/' + walletAddress + '/' + flowID
    }
  }

  const loadMenu = () => {
    let menu
    let menuButton

    if (menuShown) {
      const viewerIsAuthor = checkIfAuthor()
      menu = (
        <div className="NFT-menu" style={{ display: 'inline-block' }}>
          {viewerIsAuthor && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleHide}>
              Hide
            </div>
          )}
          {viewerIsAuthor && props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnhide}>
              Unhide
            </div>
          )}
          {viewerIsAuthor && !pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handlePin}>
              Pin
            </div>
          )}
          {viewerIsAuthor && pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnpin}>
              Unpin
            </div>
          )}
          <div className="NFT-menu-option" onClick={() => viewOnRabbithole('rabbithole')}>
            Rabbithole
          </div>
          <div className="NFT-menu-option" onClick={() => viewOnRabbithole('bizarroworld')}>
            Bizarroworld
          </div>
          <div className="NFT-menu-option" onClick={handleViewOnTopShot}>
            View on NBA Top Shot
          </div>
          {viewerIsAuthor && <div className="NFT-menu-option" onClick={() => props.functions.setAsProfilePicture(imageURL)}>
            Set as Profile Picture
          </div>}
        </div>
      )
      menuButton = (
        <button
          className="options-btn"
          onClick={handleMenuClick}
          style={{ backgroundImage: "url('/menu-close-icon.png')" }}
        ></button>
      )
    } else {
      menu = <div className="NFT-menu" style={{ display: 'none' }}></div>
      menuButton = (
        <button
          className="options-btn"
          onClick={handleMenuClick}
          style={{ backgroundImage: "url('/menu-more-icon.png')" }}
        ></button>
      )
    }

    return [menu, menuButton]
  }

  const [menu, menuButton] = loadMenu()

  let classes
  const NFTClass = isAuthor ? 'NFT-own' : 'NFT'
  if (props.isDraggable === true) {
    classes = `draggable ${NFTClass}`
  } else {
    classes = `clickable ${NFTClass}`
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={classes}>
      {menuButton}
      {menu}
      <div onClick={handleViewDetail}>
        <ImageWSkeleton imgClassName='image' imgSrc={imageURL} width={320} height={320} />
        <div className="info">
          <div>
            <span className="name">{playerName}</span>
            <span style={{ display: 'block' }}>
              {playCategory} - {dateOfMoment}, {setName}, {teamAtMoment}
            </span>
            {caption && <div className="caption">"{caption}"</div>}
          </div>
        </div>
      </div>
    </div>
  )
}
