import React from 'react'
import NavBar from '../general/NavBar'
import Marketplace from '../general/Marketplace'
import { OpenSeaPort, Network } from 'opensea-js'
import { OrderSide } from 'opensea-js/lib/types'
import firebase from '../../firebase'
import '../general/NFTDetail.css'
import './EthereumShowSimilar'
import EthereumShowSimilar from './EthereumShowSimilar'
import { openseaGetAssetsByContractv2 } from '../../util/api'
import VideoWSkeleton from '../VideoWSkeleton'
import ImageWSkeleton from '../ImageWSkeleton'

require('dotenv').config()

class EthereumDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      NFT: null,
      wallets: null,
      listAction: null,
      price: null,
      isAuthor: false
    }

    this.handleBuy = this.handleBuy.bind(this)
    this.handleSell = this.handleSell.bind(this)
    this.handleUnlist = this.handleUnlist.bind(this)
    this.marketplaceRef = React.createRef()
  }

  async loadNFT() {
    const { walletAddress, contractAddress, tokenID } = this.props.match.params;

    await this.loadOpensea(contractAddress, tokenID).then((assets) => {
      
        const contractAddress = assets.contract
        const tokenID = assets.identifier
        var name = assets.name
        if (name === null) {
          name = assets.identifier
        }
        var description = assets.description
        if (description === null) {
          description = assets.collection
        }
        var image_url = assets.image_url
        var iframe_url
        var animation_url = assets.animation_url

        // show default image if no media is found
        if (
          (image_url === null || image_url === undefined) &&
          (animation_url === null || animation_url === undefined)
        ) {
          image_url = '/empty-media.png'
        }

        // make sure image is not mp4
        if (image_url.toLowerCase().endsWith('.mp4')) {
          animation_url = image_url
        }

        // check if processing
        if (
          assets.name === null &&
          assets.description === null &&
          assets.image_url === null
        ) {
          name = 'NFT Still Processing...'
          description = 'Check back later!'
          image_url = '/empty-media.png'
        }

        var owner = null
        var schemaName = null
        var currentPrice = null
        var listedByUser = null
        var paymentToken = null
        if (!this.props.legacy) {
          owner = assets.owners;
          schemaName = assets.token_standard
          currentPrice = null
          listedByUser = false
          /*if (assets[0].sell_orders) {
            if (assets[0].sell_orders.length > 0) {
              currentPrice = assets[0].sell_orders[0].current_price
              paymentToken = assets[0].sell_orders[0].payment_token_contract.symbol
              if (
                assets[0].sell_orders[0].maker.address.toLowerCase() === walletAddress.toLowerCase()
              ) {
                listedByUser = true
              }
            }
          }*/
          if (assets.seaport_sell_orders && assets.seaport_sell_orders.length > 0) {
            const order = assets.seaport_sell_orders;
            if (order.cancelled == false) {
              const makerAddr = order.maker.address;
              if (makerAddr.toLowerCase() === walletAddress) {
                listedByUser = true
              } else {
                listedByUser = false
              }
              currentPrice = Number(order.current_price);
              paymentToken = 'ETH'
            }
          }
        }

        // show default image if no media is found
        if (image_url === null && animation_url === null) {
          image_url = '/empty-media.png'
        }

        // Add exception
        if (contractAddress === '0xeb113c5d09bfc3a7b27a75da4432fb3484f90c6a') {
          iframe_url = animation_url
          animation_url = undefined
        }
          this.setState({
            NFT: {
              contractAddress: contractAddress,
              tokenID: tokenID,
              name: name,
              description: description,
              image: image_url,
              animation: animation_url,
              owner: owner,
              currentPrice: currentPrice,
              iframe: iframe_url,
              schemaName,
              listedByUser,
              paymentToken,
            }
        });
      
    })
  }

  async getOrders() {
    const walletAddress = this.props.match.params.walletAddress
    const contractAddress = this.props.match.params.contractAddress
    const tokenID = this.props.match.params.tokenID

    // initialize Seaport
    const provider = window.ethereum
    var network = null
    if (process.env.REACT_APP_TESTING) {
      network = Network.Rinkeby
    } else {
      network = Network.main
    }
    const seaport = new OpenSeaPort(provider, {
      networkName: network,
      apiKey: process.env.REACT_APP_OPENSEA_API_KEY
    })

    const { orders, _ } = await seaport.api.getOrders({
      asset_contract_address: contractAddress,
      token_id: tokenID,
      side: OrderSide.Sell,
      maker: walletAddress
    })
    return orders
  }

  async loadOpensea(contractAddress, tokenID) {
    return await openseaGetAssetsByContractv2( contractAddress, tokenID ).then( ( result ) => {
      console.log("Result from openseaGetAssetsByContractv2: ", result);
      const assets = result.data.nft
      return assets
    } )
  }

  componentDidMount() {
    if (this.props.legacy) {
      // log firebase event
      firebase.analytics().logEvent('nft_view', {
        contract_address: '_' + this.props.match.params.contractAddress.toLowerCase(),
        token_id: '_' + this.props.match.params.tokenID.toLowerCase()
      })
    } else {
      // log firebase event
      firebase.analytics().logEvent('nft_view', {
        contract_address: '_' + this.props.match.params.contractAddress.toLowerCase(),
        token_id: '_' + this.props.match.params.tokenID.toLowerCase(),
        wallet_address: '_' + this.props.match.params.walletAddress.toLowerCase()
      })
    }

    this.loadNFT().then(() => {
      if (!this.props.legacy) {
        this.isAuthor().then((isAuthor) => {
          this.setState({ isAuthor })

          // load actions
          if (isAuthor) {
            // author
            if (this.state.NFT.schemaName === 'ERC721') {
              if (this.state.NFT.currentPrice) {
                this.setState({ listAction: 'unlist', price: this.state.NFT.currentPrice })
              } else {
                this.setState({ listAction: 'sell' })
              }
            } else if (this.state.NFT.schemaName === 'ERC1155') {
              if (this.state.NFT.currentPrice) {
                if (this.state.NFT.listedByUser) {
                  this.setState({ listAction: 'unlist', price: this.state.NFT.currentPrice })
                } else {
                  // get orders
                  this.getOrders().then((orders) => {
                    if (orders.length > 0) {
                      this.setState({ listAction: 'unlist', price: orders[0].currentPrice })
                    } else {
                      this.setState({ listAction: 'sell' })
                    }
                  })
                }
              } else {
                this.setState({ listAction: 'sell' })
              }
            }
          } else {
            // not author
            if (this.state.NFT.schemaName === 'ERC721') {
              if (this.state.NFT.currentPrice) {
                this.setState({
                  listAction: 'buy',
                  price: this.state.NFT.currentPrice
                })
              }
            } else if (this.state.NFT.schemaName === 'ERC1155') {
              if (this.state.NFT.currentPrice) {
                if (this.state.NFT.listedByUser) {
                  this.setState({
                    listAction: 'buy',
                    price: this.state.NFT.currentPrice
                  })
                } else {
                  // get orders
                  this.getOrders().then((orders) => {
                    if (orders.length > 0) {
                      this.setState({
                        listAction: 'buy',
                        price: orders[0].currentPrice
                      })
                    }
                  })
                }
              }
            }
          }
        })
      }
    })
  }

  viewOnOpenSea(openSeaURL) {
    // log firebase event
    if (this.props.legacy) {
      // log firebase event
      firebase.analytics().logEvent('opensea_click', {
        contract_address: '_' + this.props.match.params.contractAddress.toLowerCase(),
        token_id: '_' + this.props.match.params.tokenID.toLowerCase()
      })
    } else {
      // log firebase event
      firebase.analytics().logEvent('opensea_click', {
        contract_address: '_' + this.props.match.params.contractAddress.toLowerCase(),
        token_id: '_' + this.props.match.params.tokenID.toLowerCase(),
        wallet_address: '_' + this.props.match.params.walletAddress.toLowerCase()
      })
    }
    window.open(openSeaURL, '_blank')
  }

  viewOnEtherscan = () => {
    if (this.state.NFT) {
      window.open(
        'https://etherscan.io/nft/' +
          this.state.NFT.contractAddress +
          '/' +
          this.state.NFT.tokenID 
      )
    }
  }

  async loadWallets() {
    const db = firebase.firestore()
    const username = localStorage.getItem('username')
    const snapshot = await db.collection('users').doc(username).collection('wallets').get()
    var wallets = []
    snapshot.docs.forEach((doc) => {
      const data = doc.data()
      wallets.push(data.address.toLowerCase())
    })
    return wallets
  }

  async isAuthor() {
    const walletAddress = this.props.match.params.walletAddress
    var isAuthor = false
    if (firebase.auth().currentUser) {
      await this.loadWallets().then((wallets) => {
        if (wallets.includes(walletAddress.toLowerCase())) {
          isAuthor = true
        }
      })
    }
    return isAuthor
  }

  async handleBuy(contractAddress, tokenID, name, price, ownerWalletAddress, paymentToken) {
    this.marketplaceRef.current.handleBuy(
      this.props.match.params.contractAddress,
      this.props.match.params.tokenID,
      this.state.NFT.name,
      this.state.price,
      this.props.match.params.walletAddress,
      this.state.NFT.paymentToken
    )
  }

  async handleSell(contractAddress, tokenID, name, schemaName, ownerWalletAddress) {
    this.marketplaceRef.current.handleSell(
      this.props.match.params.contractAddress,
      this.props.match.params.tokenID,
      this.state.NFT.name,
      this.state.NFT.schemaName,
      this.props.match.params.walletAddress
    )
  }

  async handleUnlist(contractAddress, tokenID, name, price, walletAddress) {
    this.marketplaceRef.current.handleUnlist(
      this.props.match.params.contractAddress,
      this.props.match.params.tokenID,
      this.state.NFT.name,
      this.state.price,
      this.props.match.params.walletAddress
    )
  }

  render() {
    var media = null
    var name = null
    var description = null
    var externalURL = null
    var NFT = this.state.NFT
    var viewButton = null
    if (NFT) {
      name = NFT.name
      description = NFT.description
      if (process.env.REACT_APP_TESTING) {
        externalURL =
          'https://testnets.opensea.io/assets/' +
          NFT.contractAddress +
          '/' +
          NFT.tokenID +
          '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      } else {
        externalURL =
          'https://opensea.io/assets/ethereum/' +
          NFT.contractAddress +
          '/' +
          NFT.tokenID +
          '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      }
      viewButton = (
        <center style={{ display: 'flex', flexDirection: 'column', width: 240, margin: '0 auto' }}>
          <button
            onClick={() => this.viewOnOpenSea(externalURL)}
            className="clear"
            style={{ marginBottom: '5px', marginTop: '50px' }}
          >
            View on OpenSea
          </button>
          <button
            onClick={() => this.viewOnEtherscan()}
            className="clear"
            style={{ marginBottom: '50px', marginTop: '10px' }}
          >
            View on Etherscan
          </button>
        </center>
      )
      if (NFT.iframe) {
        media = (
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="100%"
            sandbox="allow-scripts"
            src={NFT.iframe}
            width="600px"
            style={{ minHeight: 500, maxWidth: '100%' }}
            title="Iframe"
          ></iframe>
        )
      } else if (NFT.animation) {
        media = (<VideoWSkeleton videoSrc={NFT.animation} poster={NFT.image} imgClassName='detail-video' />)
      } else {
        media = <ImageWSkeleton imgClassName='detail-image' imgSrc={NFT.image} />
      }
    }

    var address = ''
    if (this.props.legacy) {
      address = this.props.match.params.contractAddress + '/' + this.props.match.params.tokenID
    } else {
      address =
        this.props.match.params.walletAddress +
        '/' +
        this.props.match.params.contractAddress +
        '/' +
        this.props.match.params.tokenID
    }

    // load the buttons
    if (window.__sharethis__) {
      window.__sharethis__.load('inline-share-buttons', {
        id: 'my-inline-buttons',
        alignment: 'center',
        color: 'white',
        enabled: true,
        font_size: 16,
        padding: 12,
        radius: 8,
        networks: ['twitter', 'facebook', 'linkedin', 'email', 'whatsapp'],
        size: 40,
        show_mobile_buttons: true,
        url: 'https://lazy.com/nft/' + address,
        title:
          'Check out this NFT at lazy.com/nft/' + address + '. The lazy way to show off your NFTs!',
        subject: 'Check out this NFT on Lazy!',
        message:
          'Check out this NFT at lazy.com/nft/' + address + '. The lazy way to show off your NFTs!'
      })
    }
    var listAction = null
    if (this.state.listAction === 'buy') {
      listAction = (
        <center>
          <button className="clear" style={{ marginTop: '80px' }} onClick={this.handleBuy}>
            Buy for {parseFloat((this.state.price / 1000000000000000000).toFixed(2))}{' '}
            {this.state.NFT.paymentToken}
          </button>
        </center>
      )
    } else if (this.state.listAction === 'sell') {
      listAction = (
        <center>
          <button className="clear" style={{ marginTop: '80px' }} onClick={this.handleSell}>
            Sell
          </button>
        </center>
      )
    } else if (this.state.listAction === 'unlist') {
      listAction = (
        <center>
          <button className="clear" style={{ marginTop: '80px' }} onClick={this.handleUnlist}>
            Unlist
          </button>
        </center>
      )
    }

    return (
      <div>
        <NavBar />
        <div className="nft-detail-media-container">{media}</div>
        <div className="container detail-padding" style={{ paddingBottom: 0}}>
          <span className="detail-name">{name}</span>
          <span className="detail-description">{description}</span>
          {listAction}
          {viewButton}
        </div>
        {NFT && NFT.image && <EthereumShowSimilar
          walletAddress={this.props.match.params.walletAddress}
          contractAddress={this.props.match.params.contractAddress}
          tokenID={this.props.match.params.tokenID}
          url={NFT.image}
          view="similar"
          limit={3}
          chain="ethereum"
        />}
        <div className="container detail-padding">
          <div id="my-inline-buttons"></div>
        </div>
        <Marketplace ref={this.marketplaceRef} />
      </div>
    )
  }
}

export default EthereumDetail
