import React, { useState } from 'react';
import firebase from '../../firebase';
import { Item } from '@rarible/api-client/build/models/Item';
import { TezosItem } from './TezosHelper';
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import ImageWSkeleton from '../ImageWSkeleton';
import VideoWSkeleton from '../VideoWSkeleton';

type TezosNFTProps = {
  data: Item;
  walletAddress: string;
  key: number;
  username: string;
  isTiny?: boolean;
  caption?: string;
  pinID: string;
  functions?: any;
  hiddenItem?: boolean;
  isAuthor?: boolean;
  reloadPinnedNFTs?: () => void;
  removePin?: (id: string) => void;
  pinned: boolean;
  isDraggable: boolean;
  isClickable: boolean;
};

const TezosNFTNew: React.FC<TezosNFTProps> = (props) => {

  const tezosNft = props.data as TezosItem;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.pinID
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const [menuShown, setMenuShown] = useState(false);
  const [pinned, setPinned] = useState(props.pinned);
  const [owner, setOwner] = useState(tezosNft.ownerWalletAddress);


  const isOnSale = tezosNft.ownership && tezosNft.ownership.bestSellOrder && tezosNft.ownership.owner == owner;
  const contractId = tezosNft.contract && tezosNft.contract.split(':')[1];
  const tzktUrl = `https://tzkt.io/${contractId}/tokens/${tezosNft.tokenId}/transfers`;
  const objktUrl = `https://objkt.com/asset/${contractId}/${tezosNft.tokenId}`;

  let media,
  animation_url: string | undefined,
  img_url: string | undefined,
  poster;

    const preview = tezosNft.meta && 
    tezosNft.meta.content.find(x => x.representation == 'PREVIEW')!;
    const original = tezosNft.meta && 
    tezosNft.meta.content.find(x => x.representation == 'ORIGINAL')!;
    let isVideo = false;
    if (original) {
      isVideo = original.mimeType === "video/mp4"
      if (isVideo) {
        if (preview && preview.url && preview.url.length) {
          img_url = preview.url.split('ipfs/')[1];
          poster = `https://ipfs.ledgerwise.io/ipfs/${img_url}`;
          media = (<VideoWSkeleton width={320} height={320} videoSrc={original.url} poster={poster} imgClassName='video' />)
        } else {
            media = (<VideoWSkeleton width={320} height={320} videoSrc={original.url} imgClassName='video' />)
        }
      } else if (preview && preview.url) {
        const originalCid = preview.url.split('ipfs/')[1];
        const url = `https://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=${originalCid}&size=370`;
        media = (<ImageWSkeleton width={320} height={320} imgClassName='image' imgSrc={url} />)
      } else if (original && original.url) {
          const originalCid = original.url.split('ipfs/')[1];
          const url = `https://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=${originalCid}&size=370`;
          media = (<ImageWSkeleton width={320} height={320} imgClassName='image' imgSrc={url} />)
      }

    } else if (preview) {
      const originalCid = preview.url.split('ipfs/')[1];
      const url = `https://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=${originalCid}&size=370`;
      media = (<ImageWSkeleton width={320} height={320} imgClassName='image' imgSrc={url} />)
    } else {
      media =  (<div style={{width: 320, height: 320, backgroundColor: 'hsl(200, 20%, 80%)'}} />)
    }


  const loadMenu = () => {
    let menu;
    let menuButton;

    if (menuShown) {
        const viewerIsAuthor = checkIfAuthor();
        const viewerIsAdmin = isAdmin();

        menu = (
            <div className="NFT-menu" style={{ display: 'inline-block' }}>
          {viewerIsAuthor && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleHide}>
              Hide
            </div>
          )}
          {viewerIsAuthor && props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnhide}>
              Unhide
            </div>
          )}
          {viewerIsAuthor && !pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleAddPin}>
              Pin
            </div>
          )}
          {viewerIsAuthor && pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleRemovePin}>
              Unpin
            </div>
          )}
          {viewerIsAuthor && !props.hiddenItem && !isOnSale && (
            <div className="NFT-menu-option" onClick={handleSell}>
              Sell
            </div>
          )}
          {viewerIsAuthor && !props.hiddenItem && isOnSale && (
            <div className="NFT-menu-option" onClick={handleSellCancel}>
              Unlist
            </div>
          )}
          {viewerIsAuthor && <hr />}
          {!props.hiddenItem && isOnSale && (
            <div className="NFT-menu-option" onClick={() => viewOnTezos(`${tezosNft.collection}:${tezosNft.tokenId}`)}>
              View Sale on Rarible
            </div>
          )}
          <div className="NFT-menu-option" onClick={() => viewOnTezos(`${tezosNft.collection}:${tezosNft.tokenId}`)}>
            View on Rarible
          </div>
          <div className="NFT-menu-option" onClick={() => viewOn(objktUrl)}>
            View on Objkt
          </div>
          <div className="NFT-menu-option" onClick={() => viewOn(tzktUrl)}>
            View on Tzkt
          </div>
          {viewerIsAuthor && <div className="NFT-menu-option" onClick={() => props.functions.setAsProfilePicture(img_url, animation_url)}>
            Set as Profile Picture
          </div>}
        </div>
      )
      menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-close-icon.png')" }}
        ></button>
      )
    } else {
        menu = <div className="NFT-menu" style={{ display: 'none' }}></div>
        menuButton = (
          <button
            className="options-btn"
            onClick={showOptions}
            style={{ backgroundImage: "url('/menu-more-icon.png')" }}
          ></button>
        )
  }
  return [menu, menuButton]
}

const checkIfAuthor = () => {
    const username = localStorage.getItem('username')
    if (username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const isAdmin = () => {
    if (firebase.auth().currentUser?.uid === 'hhoXubahBuWi9o4ifrKjHIVZe843') {
      return true
    }
    return false
  }

  const handleAddPin = () => {
    setMenuShown(false);
    props.functions.handlePin(owner, tezosNft.contract?.replace(`${tezosNft.blockchain}:`, ''), `${tezosNft.collection}:${tezosNft.tokenId}`,'Tezos')
    .then(() =>setPinned(true));
  };

  const handleRemovePin = () => {
      setMenuShown(false);
      props.functions.handleUnpin(props.pinID)
      .then(() => setPinned(false));
  };

  const handleHide = () => {
    setMenuShown(false);
    props.functions.handleHide(owner, tezosNft.contract?.replace(`${tezosNft.blockchain}:`, ''), `${tezosNft.collection}:${tezosNft.tokenId}`,'Tezos');
  }

  const handleUnhide = async () => {
    setMenuShown(false);
    props.functions.handleUnhide(props.pinID).then(
      (value: any) => {
        // handleUnhide was successful
      },
      (reason: any) => {
        // handleUnhide was not successful
      }
    )
  }

  const viewNFTDetail = () => {
    if (props.isClickable !== false) {
      setMenuShown(false);
      document.location.href = '/tezos/' + `${tezosNft.collection}:${tezosNft.tokenId}`;
    }
  };
  
  const isAuthor = () => {
    const username = localStorage.getItem('username')!;
    if (username && props.username) {
      return username.toLowerCase() === props.username.toLowerCase();
    }
    return false;
  };
  
  const showOptions = () => {
    setMenuShown(!menuShown);
  };
  
  const viewOnTezos = (assetId: string) => {
    setMenuShown(false);
    window.open(`https://rarible.com/token/tezos/${assetId.replace('TEZOS:', '')}`);
  };
  
  const viewOn = (url: string) => {
    setMenuShown(false);
    window.open(url);
  };
  
  const viewOnObjkt = () => {
    setMenuShown(false);
    window.open();
  };
  
  const handleSell = () => {
    props.functions.handleTezosSell(`${tezosNft.collection}:${tezosNft.tokenId}`, owner);
  };
  
  const handleSellCancel = () => {
    props.functions.handleTezosCancelSell(tezosNft.ownership);
  };
  
  const handleBuy = () => {
    props.functions.handleTezosBuy(tezosNft, owner);
  };
  
  
  const getImgUrl = (item: Item) => {
    const content = item.meta && item.meta.content.find(x => x.representation === 'PREVIEW');
    if (content) {
      return content.url;
    }
    return '';
  };
  
  const getFullImgUrl = (item: Item) => {
    const content = item.meta && item.meta.content.find(x => x.representation === 'ORIGINAL');
    if (content) {
      return content.url;
    }
    return '';
  };

  const [menu, menuButton] = loadMenu()

  let classes
  const viewerIsAuthor = props.isAuthor == undefined ? isAuthor() : props.isAuthor;
  const nftClass = viewerIsAuthor ? 'NFT-own' : 'NFT'
  if (props.isDraggable === true) {
    classes = `draggable ${nftClass}`
  } else {
    classes = `clickable ${nftClass}`
  }
//   if ( !tezosNft.image_url || tezosNft.image_url.length == 0) return <div/>

let listingPrice = 0, listingCurrency, showPrice: any = 0;
if (isOnSale) {
    listingPrice = Number((tezosNft as TezosItem).ownership!.bestSellOrder!.makePrice || 0);
    listingCurrency = 'XTZ'
    showPrice = listingPrice.toFixed(2);
}

const bottomButtons = (
  <React.Fragment>
    {isOnSale && !viewerIsAuthor && (
        <div style={{ textAlign: 'center'}}>
            <button
                className="clear"
                onClick={handleBuy}
                style={{ marginTop: '15px', marginBottom: '15px' }}
            >
                Buy for {showPrice} {listingCurrency}
            </button>
        </div>
    )}
     {isOnSale && viewerIsAuthor && isOnSale  && (
        <div style={{ textAlign: 'center'}}>
            <button
                className="clear"
                onClick={handleSellCancel}
                style={{ marginTop: '15px', marginBottom: '15px' }}
            >
                Cancel Listing
            </button>
        </div>
    )}
  </React.Fragment>
)


return (
    <div ref={setNodeRef} className={classes} style={style as any} {...attributes} {...listeners}>
      {menuButton}
      {menu}
      <div style={{ textAlign: 'center'}}  onClick={viewNFTDetail}>
        {media}
        <div className="info">
          <div>
          <span className="name">{tezosNft.meta ? tezosNft.meta.name : 'Untitled'}</span>
            <span style={{ display: 'block' }}>
              {tezosNft.collection ? '' : ''}
            </span>
            <span style={{top: 10, position: 'relative'}}>{props.caption && props.caption}</span>
          </div>
        </div>
      </div>
      {!props.isTiny && bottomButtons}
    </div>
  )
}

export default TezosNFTNew;