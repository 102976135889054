import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useEffect, useMemo, useState } from 'react';
import firebase from '../../firebase';
import ImageWSkeleton from '../ImageWSkeleton';
import VideoWSkeleton from '../VideoWSkeleton';

export default function PolygonNFT(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.pinID
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const walletAddress = props.walletAddress || props.data.walletAddress;
  const contractAddress = props.data.asset_contract ? props.data.asset_contract.address : props.data.contractAddress;
  const tokenID = props.data.identifier || props.data.tokenID;
  const caption = props.caption;

  const [name, setName] = useState('');
  const [animationURL, setAnimationURL] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [isAuthor, setIsAuthor] = useState(false);
  const [menuShown, setMenuShown] = useState(false);
  const [pinned, setPinned] = useState(props.pinned);
  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    if (props.data && props.data.token_standard === "erc20") {
      setShouldRender(false);
    } else {
      setIsAuthor(checkIfAuthor());
      setName(loadName());
      setAnimationURL(loadAnimationURL());
      setImageURL(loadImageURL());
    }
  }, [props.data]);

  const loadName = () => {
    let _name = props.data.name;
    if (!_name && props.data.asset_contract) {
      _name = props.data.asset_contract.name;
    }
    //console.log("Polygon data:", props.data);
    if (!_name && !props.data.asset_contract) {
      //console.warn("Missing asset_contract in data:", props.data);
    }
    
    //console.log("NAME:", _name )
    return _name
  }

  const loadAnimationURL = () => {
    let _animationURL = props.data.animation_url || props.data.animation
    if (_animationURL === null && props.data.asset_contract) {
      _animationURL = props.data.asset_contract.animation_url
    }
    return _animationURL
  }
  const loadImageURL = () => {
    const data = props.data;
    
    // Early return for no data
    if (!data || Object.keys(data).length === 0) return '/empty-media.png';
    //console.log("PINS:", data)
    let potentialURLs = [
      data.image_url, 
      data.image, 
      data.image_preview_url, 
      data.nfts?.image_url
    ];
    
    // Filter out any undefined or null values
    potentialURLs = potentialURLs.filter(url => url);
  
    if (potentialURLs.length === 0) return '/empty-media.png';
  
    const _imageURL = potentialURLs[0];
  
    if (_imageURL.toLowerCase().endsWith('.mp4')) {
      setAnimationURL(_imageURL);
      // Add a placeholder for MP4 if necessary
      return "/placeholder-for-mp4.png"; 
    }
  
    return _imageURL;
  }
  
  
  

  const media = useMemo(() => {
    if (animationURL) {
      return (
        <VideoWSkeleton width={320} height={320} poster={imageURL} videoSrc={animationURL} imgClassName='video' />
      )
    } else {
      return <ImageWSkeleton width={320} height={320} imgClassName="image" imgSrc={imageURL} />
    }
  }, [animationURL, imageURL]);

  const loadMenu = () => {
    let _menu
    let _menuButton
    if (menuShown) {
      const viewerIsAuthor = checkIfAuthor()
      const viewerIsAdmin = isAdmin()
      _menu = (
        <div className="NFT-menu" style={{ display: 'inline-block' }}>
          {viewerIsAuthor && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleHide}>
              Hide
            </div>
          )}
          {viewerIsAuthor && props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnhide}>
              Unhide
            </div>
          )}
          {viewerIsAuthor && !pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handlePin}>
              Pin
            </div>
          )}
          {viewerIsAuthor && pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnpin}>
              Unpin
            </div>
          )}
          {viewerIsAdmin && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={feature}>
              Feature
            </div>
          )}
          {viewerIsAuthor && <hr />}
          <div className="NFT-menu-option" onClick={() => viewOnRabbithole('rabbithole')}>
            Rabbithole
          </div>
          <div className="NFT-menu-option" onClick={() => viewOnRabbithole('bizarroworld')}>
            Bizarroworld
          </div>
          <div className="NFT-menu-option" onClick={viewOnOpenSea}>
            View on OpenSea
          </div>
          {viewerIsAuthor && <div className="NFT-menu-option" onClick={() => props.functions.setAsProfilePicture(imageURL, animationURL)}>
            Set as Profile Picture
          </div>}
        </div>
      )
      _menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-close-icon.png')" }}
        ></button>
      )
    } else {
      _menu = <div className="NFT-menu" style={{ display: 'none' }}></div>
      _menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-more-icon.png')" }}
        ></button>
      )
    }
    return [_menu, _menuButton]
  }

  const showOptions = () => {
    setMenuShown(!menuShown)
  }

  const viewDetail = () => {
    if (props.isClickable !== false) {
      setMenuShown(false)
      document.location.href =
        '/polygon/' +
        props.walletAddress +
        '/' +
        props.data.contract +
        '/' +
        props.data.identifier
    }
  }

  const viewOnRabbithole = (to) => {
    setMenuShown(false)

    window.location.href = `/${to}` + "?" + new URLSearchParams({
      tokenID: tokenID, 
      contractAddress: contractAddress,
      url: btoa(imageURL),
      animationUrl: btoa(animationURL),
      chain: 'polygon'
    }).toString()
  };

  const viewOnOpenSea = () => {
    setMenuShown(false)

    // log firebase event
    firebase.analytics().logEvent('opensea_click', {
      contract_address: '_' + props.data.contract.toLowerCase(),
      identifier: '_' + props.data.identifier.toLowerCase(),
      wallet_address: '_' + props.walletAddress.toLowerCase()
    })

    if (!process.env.REACT_APP_TESTING) {
      window.open(
        'https://opensea.io/assets/matic/' +
          props.data.contract +
          '/' +
          props.data.identifier +
          '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      )
    } else {
      window.open(
        'https://testnets.opensea.io/assets/mumbai/' +
          props.data.asset_contract.address +
          '/' +
          props.data.identifier +
          '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      )
    }
  }

  const feature = () => {
    setMenuShown(false)
    if (window.confirm('Are you sure you want to feature this NFT?')) {
      const db = firebase.firestore()
      db.collection('featured')
        .doc()
        .set({
          type: 'Polygon',
          wallet_address: walletAddress,
          contract_address: contractAddress,
          identifier: tokenID,
          created_at: new Date(),
          username: props.username.toLowerCase()
        })
        .then(() => {
          alert('Featured successfully!')
        })
    }
  }

  const checkIfAuthor = () => {
    const username = localStorage.getItem('username')
    if (username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const isAdmin = () => {
    if (firebase.auth().currentUser) {
      if (firebase.auth().currentUser.uid === 'hhoXubahBuWi9o4ifrKjHIVZe843') {
        return true
      }
    }
    return false
  }

  const handlePin = async () => {
    setMenuShown(false)
    props.functions.handlePin(walletAddress, contractAddress, tokenID, 'Polygon').then(
      (value) => {
        setPinned(true)
      },
      (reason) => {
        // handlePin was not successful
      }
    )
  }

  const handleHide = async () => {
    setMenuShown(false)
    props.functions.handleHide(walletAddress, contractAddress, tokenID, 'Polygon');
  }
  const handleUnhide = async () => {
    setMenuShown(false)
    props.functions.handleUnhide(props.pinID).then(
      (value) => {
        // handleUnhide was successful
      },
      (reason) => {
        // handleUnhide was not successful
      }
    )
  }

  const handleUnpin = async () => {
    setMenuShown(false)
    props.functions.handleUnpin(props.pinID).then(
      (value) => {
        setPinned(false)
      },
      (reason) => {
        // handleUnpin was not successful
      }
    )
  }

  const [menu, menuButton] = loadMenu()

  // check if processing
  if (
    props.data.name === null &&
    props.data.description === null &&
    props.data.image_url === null &&
    loadName() === null &&
    loadImageURL() === null
  ) {
    if (name != 'NFT Still Processing...') {
      setName('NFT Still Processing...')
    }
    if (imageURL != '/empty-media.png') {
      setImageURL('/empty-media.png')
    }
  }

  let classes
  const NFTClass = isAuthor ? 'NFT-own' : 'NFT'
  if (props.isDraggable === true) {
    classes = `draggable ${NFTClass}`
  } else {
    classes = `clickable ${NFTClass}`
  }

  if (!shouldRender) {
    return null;
  }

  return (
    <div ref={setNodeRef} className={classes} style={style} {...attributes} {...listeners}>
      {menuButton}
      {menu}
      <div onClick={viewDetail}>
        {media}
        <div className="info">
          <div>
            <span className="name">{name}</span>
            {caption && <div className="caption">"{caption}"</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
