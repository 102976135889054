import axios from 'axios';

require('dotenv').config()

const loadAvalancheNFTs = async (walletAddress, cursor) => {
  const headers = {
      'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY
  };

  // Choose the appropriate endpoint for Avalanche chain
  let endpointURL;
  if (!process.env.REACT_APP_TESTING) {
      endpointURL = `https://api.opensea.io/api/v2/chain/avalanche/account/${walletAddress}/nfts?limit=50`;
  } else {
      // Assuming there's a testing version for Avalanche
      endpointURL = `https://api.opensea.io/api/v2/chain/avalanche-test/account/${walletAddress}/nfts?limit=50`;
  }

  if (cursor) {
      endpointURL += `&next=${cursor}`;
  }

  try {
      const { data } = await axios.get(endpointURL, { headers });

      if (data.nfts) {
        console.log("CURSOR", data.next);
          // Map OpenSea's nfts structure to the desired output format
          const nftsWithMetadata = data.nfts.map(asset => {
              return {
                  type: "Avalanche",
                  data: {
                      identifier: asset.identifier,
                      collection: asset.collection,
                      contract: asset.contract,
                      token_standard: asset.token_standard,
                      name: asset.name,
                      description: asset.description || "",  // Use an empty string if description is null
                      image_url: asset.image_url,
                      metadata_url: asset.metadata_url,
                      created_at: asset.created_at,
                      updated_at: asset.updated_at,
                      is_disabled: asset.is_disabled,
                      is_nsfw: asset.is_nsfw
                  }
              };
          });

          return [nftsWithMetadata, data.next]; // Assuming there's still a cursor in OpenSea's response
      } else {
          console.error("Error fetching Avalanche NFT data:", data.error);
          return [[], []];
      }
  } catch (error) {
      console.error("Error fetching Avalanche NFT data:", error);
      return [[], []];
  }
};

 
  export const loadAvalanchePins = async (walletAddress, pins) => {
    let NFTs = [];
    const [allNfts,] = await loadAvalancheNFTs(walletAddress);
  
    for (const pin of pins) {
      const asset = allNfts.find(
        (nft) =>
          nft.data.contract === pin.contractAddress &&
          nft.data.identifier === pin.tokenID
      );
  
      if (asset) {
        let nft = {
          type: 'Avalanche',
          index: pin.index,
          data: asset.data,
          walletAddress,
          pinID: pin.id,
          caption: pin.caption,
        };
        NFTs.push(nft);
      }
    }
  
    return NFTs;
  };
  
  export const loadFeaturedAvalancheNFTs = async (
    walletAddresses,
    contractAddresses,
    tokenIDs,
    owners
  ) => {
    let NFTs = [];
    let i = 0;
    for (const walletAddress of walletAddresses) {
      const [allNfts,] = await loadAvalancheNFTs(walletAddress);
      const contractAddress = contractAddresses[i];
      const tokenID = tokenIDs[i];
  
      const NFT = allNfts.find(
        (nft) =>
          nft.data.contract === contractAddress && nft.data.identifier === tokenID
      );
  
      if (NFT) {
        let metadata = owners[contractAddress + ':' + tokenID];
        let id = metadata['id'];
        let createdAt = metadata['createdAt'];
        let username = metadata['username'];
  
        let nft = {
          type: 'Avalanche',
          createdAt: createdAt,
          username: username,
          data: {
            id: id,
            walletAddress: walletAddress,
            contractAddress: contractAddress,
            tokenID: tokenID,
            name: NFT.data.name,
            image: NFT.data.image_url,
            animation: NFT.data.metadata.animation_url,
          },
        };
  
        NFTs.push(nft);
      }
      i += 1;
    }
  
    return NFTs;
  };
  

  export { loadAvalancheNFTs };
