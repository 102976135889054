import React from 'react'

class Error extends React.Component {
  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex', whiteSpace: 'pre-line' }}>
        <div className="modal">
          <span className="title">{this.props.title}</span>
          <span className="description">{this.props.message && this.props.message.substr(0, 300)}</span>
          <button className="clear" onClick={this.props.handleClose}>
            Close
          </button>
        </div>
      </div>
    )
  }
}

export default Error
