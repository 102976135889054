import { createRef, useEffect, useState } from 'react'
import { loadWaxNFT, WaxNFTData, WAX_IPFS_URL } from './WaxHelper'
import NavBar from '../general/NavBar'
import '../general/NFTDetail.css'
import { isArray, isNil } from 'lodash'
import WaxBundleModal from './waxModals/BundleModal'
import WaxMarketplace from './WaxMarketplace'
import VideoWSkeleton from '../VideoWSkeleton'
import ImageWSkeleton from '../ImageWSkeleton'

export default function WaxDetail(props: any) {
  const assetID = props.match.params.assetID.toLowerCase()
  const waxMarketRef: any = createRef()

  const [NFT, setNFT] = useState<WaxNFTData | undefined>()
  const [showBundle, setShowBundle] = useState<boolean>(false);

  useEffect(() => {
    loadNFT()
  }, [])


  const loadNFT = async () => {
    const _NFT = await loadWaxNFT(assetID);
    if (_NFT && !isArray(_NFT)) {
        setNFT(_NFT)
    }
  }

  if (!NFT) return <div/>


  const handleBidAuction = () => {
    waxMarketRef.current.handleBidAuction(NFT);
  };

  const handleClaimNFT = () => {
    waxMarketRef.current.handleClaimAuctionNFT(NFT);
  };

  const handleClaimMoney = () => {
    waxMarketRef.current.handleClaimMoney(NFT);
  };

  const handleBuy = () => {
    waxMarketRef.current.handleBuy(NFT, NFT.owner);
  };

  const handleBuyBundle = () => {
    waxMarketRef.current.handleBuyBundle(NFT.salesData);
  };

  const viewOnWax = (url: string) => {
    window.open(url)
  }

  const finalNFT: WaxNFTData = NFT || {} as any;
  const url = 'https://wax.atomichub.io/explorer/asset/' + (NFT ? NFT.asset_id : '');
  let media,
    animation_url = finalNFT.data ? finalNFT.data.video : undefined;
    if (animation_url) {
      media = (<VideoWSkeleton poster={NFT.data.img ? WAX_IPFS_URL + NFT.data.img : ''} videoSrc={WAX_IPFS_URL + animation_url} imgClassName='detail-video' />)
    } else {
    media = <ImageWSkeleton imgClassName='detail-image' imgSrc={WAX_IPFS_URL + (NFT ? NFT.data.img ? NFT.data.img : '' : '')} />
    }

    const onAuctionButOver = NFT.auctionsData && NFT.auctionsData.state === 4;
    const onAuctionButSold = NFT.auctionsData && NFT.auctionsData.state === 3;
    const auctionBoughtByUser = onAuctionButSold && !isNil(props.walletAddress) &&
      props.walletAddress === NFT.auctionsData!.buyer;
    const auctionMoneyClaimable = onAuctionButSold && props.walletAddress === NFT.auctionsData!.seller;
    const isSellable = !onAuctionButOver && (!NFT.salesData && NFT.auctions.length == 0);
    const isOnSale = NFT.salesData;
    const isOnAuction = NFT.auctionsData;
    const isAuctionLive = NFT.auctionsData && NFT.auctionsData.state === 1;
    const isSaleBundle = NFT.salesData && NFT.salesData.assets.length > 1;

    let listingPrice = 0, listingCurrency, showPrice: any = 0;
    if (NFT.salesData) {
        listingPrice = Number(NFT.salesData.listing_price);
        listingCurrency = NFT.salesData.listing_symbol.toUpperCase();
        if (listingCurrency == 'WAX') {
            showPrice = parseFloat((listingPrice / 100000000).toFixed(2));
        } else if (listingCurrency == 'USD') {
          showPrice = (listingPrice / 100).toFixed(2);
        }
    }

    const renderBundle = showBundle && (
      <WaxBundleModal
        price={`${showPrice} ${listingCurrency}`}
        username={props.username} 
        walletAddress={props.walletAddress}
        data={NFT}
        handleBuy={() => handleBuyBundle()}
        handleClose={() => setShowBundle(false)}
      />
    )

  return (
    <div>
      {renderBundle}
      <NavBar />
      <div className="nft-detail-media-container">
       {media}
      </div>
      <div className="container detail-padding">
        <span className="detail-name">{finalNFT.name || 'NFT Still Processing...'}</span>
        <span className="detail-description">{finalNFT.collection? finalNFT.collection.name : 'Check back later!'}</span>
        <div style={{ textAlign: 'center'}}>
          
          {NFT.salesData && !isSaleBundle && (
            <div style={{ textAlign: 'center'}}>
                <button
                    className="clear"
                    onClick={handleBuy}
                    style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                    Buy for {showPrice} {listingCurrency}
                </button>
            </div>
        )}
        {NFT.auctionsData && isAuctionLive && (
            <div style={{ textAlign: 'center'}}>
                <button
                    className="clear"
                    onClick={handleBidAuction}
                    style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                    See Auction
                </button>
            </div>
        )}
        {
          auctionBoughtByUser && (
            <div style={{ textAlign: 'center'}}>
                <button
                    className="clear"
                    onClick={handleClaimNFT}
                    style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                    Claim Purchased NFT
                </button>
            </div>
          )
        }
        {
          auctionMoneyClaimable && (
            <div style={{ textAlign: 'center'}}>
                <button
                    className="clear"
                    onClick={handleClaimMoney}
                    style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                    Claim WAX From Auction
                </button>
            </div>
          )
        }
        {
          isSaleBundle && (
            <div style={{ textAlign: 'center'}}>
                <button
                    className="clear"
                    onClick={() => setShowBundle(true)}
                    style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                    See NFT Bundle for Sale
                </button>
            </div>
          )
        }
        <button
            onClick={() => viewOnWax(url)}
            className="clear"
            style={{ marginBottom: '50px', marginTop: '50px' }}
          >
            View on AtomicHub
          </button>
        </div>
      </div>
      <WaxMarketplace ref={waxMarketRef as any} />
    </div>
  )
}
