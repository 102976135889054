import { ReactEventHandler, useCallback, useState } from "react"
import IntersectionObserverComponent from "./IntersectionObserverComponent";

interface ImageWSkeletonProps {
    imgSrc: string;
    width?: number | string;
    height?: number | string;
    imgClassName?: string;
    style?: Object;
    onError?: ReactEventHandler<HTMLImageElement>;
}

// This component should be used with absolute width and height as well as imgClassName
// width and height of 100% only works if you wrap this component in a parent div that dictates the dimensions but this is not recommended
// use imgClassName to give the className for the image
// style is an optional style object which can be used instead of imgClassName

export default function ImageWSkeleton({imgSrc, imgClassName = "image", width, height, style, onError}: ImageWSkeletonProps) {

    const [imageLoaded, setImageLoaded] = useState(false);
    const [beginLoad, setBeginLoad] = useState(false);
    const [error, setError] = useState(false);

    const handleEnterViewport = () => setBeginLoad(true);

    return (
        <div style={{ width, height}} className={imgClassName}>
            <IntersectionObserverComponent onEnterViewport={handleEnterViewport} />
             {imageLoaded === false && <div className={`${imgClassName} skeleton`} style={{width, height, ...style}}/>}
             {error === true ? <div style={{width, height}} /> :<img onLoad={e => setImageLoaded(true)} style={{ display: imageLoaded ? 'flex' : 'none', width: width || undefined, height: height || undefined, ...style}} className={imgClassName} src={beginLoad ? imgSrc : ''} alt="" onError={(e) => { if (beginLoad) {setError(true); setImageLoaded(true)}}} />}
        </div>
    )
}