import './FAQ.css'
import NavBar from './NavBar'

const listStyle = {
    listStyleType: 'disc',
    marginLeft: '4rem',
};

const TERMS_TEXT = <p>{`Last Updated: May 19, 2023



Lazy.com, owned by Radical Investments LP (“Lazy.com,” “we”, “us”, or “our”) is devoted to safeguarding your privacy. This Privacy Policy outlines our methods concerning the Personal Data (as specified below) we amass, utilize, and distribute in connection with the Lazy.com website, mobile application, and other software provided on or in association with our services, as described in our Terms of Service (collectively, the “Service”). The term "NFT" in this Privacy Policy denotes a non-fungible token or similar digital item implemented on a blockchain (such as the Ethereum blockchain), which uses smart contracts to connect to or otherwise associate with particular content or data.



Data We Collect: "Personal Data" pertains to data that permits someone to identify you individually, including your name, email address, and any other non-public information about you that is associated with or linked to any of the previous data. "Anonymous Data" signifies data, including aggregated and de-identified data, that is not associated with or linked to your Personal Data; Anonymous Data does not, on its own, permit the identification of individual persons. We collect Personal Data and Anonymous Data as elaborated below.



Information You Provide Us:

As you use our Service, modify your account profile, or reach out to us, we may collect Personal Data from you such as email address, first and last name, user name, and other information you provide. We also amass your blockchain address, which may be associated with Personal Data when you use our Service.

Our Service allows you to store preferences such as how your content is displayed, notification settings, and favorites. These choices may be associated with your ID, browser, or mobile device.



If you give us feedback or contact us, we collect your name and contact information, as well as any other content included in the message.

We may also gather Personal Data at other points in our Service where you voluntarily provide it, or where we specify that Personal Data is being collected.



Information Collected via Technology:

As you traverse through and interact with our Service, we may employ automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns. This information collection includes details obtained by our servers, log files, cookies, pixel tags, do not track signals, analytics services, information collected from third-party companies, and public information observed from blockchains.



Use of Your Personal Data:

We process your Personal Data to operate our business, deliver our Service, personalize your experience, and enhance our Service. Specifically, we use your Personal Data to:`}
    <ul style={listStyle}>
        <li color='black'>Operate and secure your account;</li>
        <li>Identify you as a user in our system;</li>
        <li>Provide our Service, including helping you view, explore, and create NFTs, and at your discretion, connect directly with others to buy, sell, or transfer NFTs on public blockchains;</li>
        <li>Improve our Service administration and user interaction;</li>
        <li>Provide customer support and respond to your requests and inquiries;</li>
        <li>Investigate and address any violation of our Terms of Service;</li>
        <li>Detect and address fraud, violations of our terms or policies, and other potentially harmful or unlawful activities;</li>
        <li>Display your username next to your NFTs and NFTs on which you have interacted;</li>
        <li>Send administrative notifications, promotional materials, newsletters, and other related communications.</li>
    </ul>

    {`

We may create Anonymous Data records from Personal Data for the purpose of analyzing request and usage patterns to improve our Services and enhance Service navigation.



Disclosure of Your Personal Data:

We disclose your Personal Data as described below and elsewhere in this Privacy Policy to third party service providers, affiliates, during corporate restructuring, for legal rights, and other disclosures.



Please note that by using our Service, you consent to the collection, use, and sharing`}
</p>

export default function TermsOfService() {
    return (
        <div className='top-container-faq'>
            <NavBar showGetStarted={true} />
            <div className="home-container-faq">
                <p className='terms-header'>Terms of Service</p>
                <p className='terms-text'>{TERMS_TEXT}</p>
            </div>
        </div>
    )
}