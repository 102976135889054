import React from 'react'
import Error from '../modals/Error'
import GenericModal from '../modals/GenericModal'
import { Item } from '@rarible/api-client/build/models/Item'
import { TezosWallet } from "@rarible/sdk-wallet"
import { createRaribleSdk } from "@rarible/sdk"
import { NetworkType as TezosNetwork } from "@airgap/beacon-sdk"
import { BeaconConnectionProvider, TezosProviderConnectionResult } from "@rarible/connector-beacon"
import {
    Connector,
    IConnectorStateProvider,
    ConnectionProvider,
    AbstractConnectionProvider,
} from "@rarible/connector"
import { IRaribleSdk } from '@rarible/sdk/build/domain'
import { toItemId } from '@rarible/types/build/item-id'
import { toOrderId } from '@rarible/types/build/order-id'
import { toUnionAddress } from '@rarible/types/build/union-address'
import BuyTezosModal from './TezosModals/BuyTezosModal'
import AnnounceTezosSale from './TezosModals/AnnounceTezosSale'
import LoadingModal from './TezosModals/LoadingModal'

type Props = {}
type State = {
    display: string;
    errorTitle: string;
    errorMessage: string;
    name?: string;
    price?: string;
    contractAddress?: string;
    tokenID?: string;
    ownerWalletAddress?: string;
    selectedWalletName?: string;
    selectedWalletAddress?: string;
    saleID?: string;
    nft?: Item;
    auctionsData?: Item;
};

class TezosMarketplace extends React.Component<Props,State> {
  provider = undefined
  sdk: IRaribleSdk | undefined  = undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      display: '',
      errorTitle: 'Error',
      errorMessage: '',
    }
  }

  initOnboard = (afterFunction?: any) => {
    if (this.state.selectedWalletAddress) {
        return;
    }
    function mapTezosWallet<O>(provider: AbstractConnectionProvider<O, TezosProviderConnectionResult>): ConnectionProvider<O, any> {
        return provider.map(async (state: any) => {
          const {
            beacon_provider: createBeaconProvider
          } = await import("@rarible/tezos-sdk/dist/providers/beacon/beacon_provider")
          const provider = await createBeaconProvider(state.wallet as any, state.toolkit)
    
          return {
                wallet: new TezosWallet(provider),
                address: state.address,
            }
        })
    }
      
        const beacon = mapTezosWallet(new BeaconConnectionProvider({
            appName: "Lazy",
            accessNode: "https://mainnet.api.tez.ie",
            network: TezosNetwork.MAINNET
        }))
        
        //const raribleSdk = createRaribleSdk(wallet, "prod")
        const state: IConnectorStateProvider = {
            async getValue(): Promise<string | undefined> {
                const value = localStorage.getItem("saved_provider")
                return value ? value : undefined
            },
            async setValue(value: string | undefined): Promise<void> {
                localStorage.setItem("saved_provider", value || "")
            },
        }
        
        const connector = Connector
            .create(beacon, state) // use ConnectionState for store connector data (last connected provider, etc)
            .add(beacon)
    
    
        connector.connection.subscribe((con) => {
            console.log("connection: ")
            if (con.status === "connected") {
                this.sdk = createRaribleSdk((con.connection as any).wallet, "prod")
                this.setState({ selectedWalletAddress: (con.connection as any).address })
                afterFunction && afterFunction((con.connection as any).address);
            }
        })
    
        connector.getOptions().then(x => connector.connect(x[0]))
  }

  handleAnnounceSale = async (tokenID: string, ownerWalletAddress: string) => {
    this.setState({
      //contractAddress: contractAddress,
      tokenID,
      ownerWalletAddress,
    })
    this.setState({ display: 'announceSale' })
   };

  announceSaleContinue = (price: number, type: string) => {
    const { selectedWalletAddress: wal, tokenID } = this.state;

    const doSale = (addr: string) => {
        const selectedWalletAddress = wal || addr;
        if (this.sdk && selectedWalletAddress && tokenID) {
            this.setState({ display: 'loading' });
            this.sdk.order.sell({
                itemId: toItemId(tokenID!),
            }).then(x => {
                this.setState({ display: 'transactionReady' });
                x.submit({
                    amount: 1,
                    price: price,
                    currency: { '@type': 'XTZ'},
                    originFees: [{
                        account: toUnionAddress(`TEZOS:${selectedWalletAddress}`),
                        //2,5%
                        value: 250,
                    }],
                    payouts: [{
                        account: toUnionAddress(`TEZOS:${selectedWalletAddress}`),
                        //2.5%
                        value: 250,
                    }],
                    //+1 hour
                    expirationDate: new Date(Date.now() + 60 * 60 * 1000),
                }).then(x => {
                        console.log(x);
                    this.setState({
                        display: 'error',
                        errorTitle: 'Success!',
                        errorMessage: 'Your NFT is now for sale!  It will take roughly ~30-90 seconds for Tezos to update with your sale. \n\nClick this to reload the page for updated details.'
                    })
                    }).catch(e => {
                        console.log(e);
                        this.setState({
                            display: 'error',
                            errorTitle: 'Error!',
                            errorMessage: `Something went wrong putting your NFT up for sale. The transaction was reverted and nothing happened.\n\n
                            What Tezos says: \n\n${e}`
                        })
                    });
            })
        }
        
    }

    if (!this.sdk) {
        this.initOnboard(doSale);
    } else {
        doSale(wal!);
    }

  };

  handleCancelSale = async (item: Item) => {
  
    this.setState({
      saleID: item.bestSellOrder && item.bestSellOrder.id
    })
    this.setState({ display: 'cancelSale' })
   };

  cancelSaleContinue = () => {
        
    const { selectedWalletAddress: wal, saleID } = this.state;

    const cancelSale = (addr: string) => {
        const selectedWalletAddress = wal || addr;
        if (this.sdk && selectedWalletAddress && saleID) { 
            this.setState({ display: 'loading'});
            this.sdk.order.cancel({ orderId: toOrderId(saleID) })
                .then(x => {
                    console.log(x);
                this.setState({
                    display: 'error',
                    errorTitle: 'Success!',
                    errorMessage: 'Your NFT sale is now canceled!  It will take roughly ~30-90 seconds for Tezos to update with your cancellation. \n\nClick this to reload the page for updated details.'
                })
                }).catch(e => {
                    console.log(e);
                    this.setState({
                        display: 'error',
                        errorTitle: 'Error!',
                        errorMessage: `Something went wrong cancelling your NFT sale. The transaction was reverted and nothing happened.\n\n
                        What Tezos says: \n\n${e}`
                    })
                });
        }
    }

    if (!this.sdk) {
        this.initOnboard(cancelSale);
    } else {
        cancelSale(wal!);
    }
  };

  handleBuy = async (nft: Item) => {
    this.setState({
      nft,
    })
    if (!this.sdk) {
      this.initOnboard();
  }
    this.setState({ display: 'buy' })
   };

  buyContinue = () => {
    const {selectedWalletAddress: wal, nft} = this.state;

    const buy = (addr: string) => {
        const selectedWalletAddress = wal || addr;
        if (this.sdk && selectedWalletAddress && nft && (nft as any).ownership.bestSellOrder) { 
            this.sdk.order.buy({ orderId: (nft as any).ownership.bestSellOrder.id })
                .then(x => {
                    this.setState({ display: 'loading'});
                    (x as any).submit({ amount: 1}).then((y: any) => {
                        console.log(y)
                        this.setState({
                            display: 'error',
                            errorTitle: 'Success!',
                            errorMessage: "You've purchased this NFT! It will appear as purchased in ~30-90 seconds. \n\nClick this to reload the page for updated details."
                        })
                    }).catch((e: any) => {
                        console.log(e);
                        this.setState({
                            display: 'error',
                            errorTitle: 'Error!',
                            errorMessage: `Something went purchasing the NFT. The transaction was reverted and nothing happened.\n\n
                            What Tezos says: \n\n${e}`
                        })
                    });
                    
                }).catch(e => {
                    console.log(e);
                    this.setState({
                        display: 'error',
                        errorTitle: 'Error!',
                        errorMessage: `Something went purchasing the NFT. The transaction was reverted and nothing happened.\n\n
                        What Tezos says: \n\n${e}`
                    })
                });
        }
    };
    
    if (!this.sdk) {
        this.initOnboard(buy);
    } else {
        buy(wal!);
    }
 }; 
  
  emptyDisplay = () => {
    if (this.state.errorMessage.indexOf('reload') > -1) {
       location.reload();
    } 
    this.setState({ display: '' })
  }

  render() {
    const { display } = this.state;
    const { selectedWalletAddress } = this.state;
    
    const announceSale = <AnnounceTezosSale
        title={'List for Sale'}
        handleConfirm={this.announceSaleContinue}
        handleClose={this.emptyDisplay}
    />;

    var error = (
      <Error
        title={this.state.errorTitle}
        message={this.state.errorMessage}
        handleClose={this.emptyDisplay}
       // shareLink={this.state.shareLink}
      />
    )

    const cancelSale = <GenericModal
        title={'Cancel Sale'}
        message={'Would you like to cancel the sell order for this NFT?'}
        handleClose={this.emptyDisplay}
        handleSubmit={() => this.cancelSaleContinue()}
    />;

    const buy = this.state.nft && <BuyTezosModal
        nft={this.state.nft}
        address={selectedWalletAddress!}
        handleClose={this.emptyDisplay}
        handleSubmit={() => this.buyContinue()}
    />;

    const loading = <LoadingModal
        title={'Please wait...'}
        handleClose={this.emptyDisplay}
        message={'It will take ~10-30 seconds to prepare your transaction. Be sure to approve the transaction in your wallet.'}
    />;

    const transactionReady = <LoadingModal
        title={'Please wait...'}
        handleClose={this.emptyDisplay}
        message={'Transaction ready. Go to wallet to continue.'}
    />;

    return (
      <div>
        {display == 'loading' && loading}
        {display == 'transactionReady' && transactionReady}
        {display == 'announceSale' && announceSale}
        {display == 'cancelSale' && cancelSale}
        {display == 'buy' && buy}
        {display == 'error' && error}
      </div>
    )
  }
}

export default TezosMarketplace
