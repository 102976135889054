import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react'

export type AnnounceSaleProps = {
    title: string;
    isAuction?: boolean;
    handleConfirm: (price: number, type: string, duration?: number) => void;
    handleClose: () => void;
};

type State = {
    type: string;
    price?: number;
    duration: number;
}
const reg = new RegExp('^[0-9]+$');
class AnnounceSale extends React.Component<AnnounceSaleProps, State> {
   constructor(props: AnnounceSaleProps) {
       super(props);
       this.state = {
            type: 'wax',
            duration: 1,
            price: 0,
       };
   }

   onConfirm = () => {
       if (this.state.price) {
         this.props.handleConfirm(this.state.price, this.state.type, this.state.duration);
       }
   };

   onChangeCurrency = (event: React.MouseEvent<HTMLElement>, newType: string | null) => {
     if (newType) this.setState({ type: newType })
   };

   setPrice = (value: string) => {
     const countDecimals = function (value: number) {
        if(Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0; 
    }
    let val = Number(value);
    if (countDecimals(val) > 2) {
      val = Number(val.toFixed(2));
    }
    this.setState({ price: val === 0 ? undefined : val });
   };

  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
        
          <span className="title">{this.props.title}</span>
          <div className="inputs" style={{marginBottom: 14, paddingTop: 10}}>
            {!this.props.isAuction && <ToggleButtonGroup
                  color="primary"
                  value={this.state.type}
                  exclusive
                  onChange={this.onChangeCurrency}
                  style={{marginTop: 2}}
              >
              <ToggleButton value="wax">WAX</ToggleButton>
              <ToggleButton value="usd">USD</ToggleButton>
              </ToggleButtonGroup>}
              <span style={{color: 'white'}}>{'-'}</span>
              <FormControl variant="standard" sx={{ marginLeft: 20, m: 1, mt: 1, width: 200 }}>
                <Input
                  style={{minWidth: 200 }}
                  value={this.state.price}
                  type="number"
                  onChange={(event: any) => this.setPrice(event.target.value)}
                  endAdornment={<InputAdornment position="end">{this.state.type.toUpperCase()}</InputAdornment>}
                  inputProps={{
                    'aria-label': 'Price',
                  }}
                />
                <FormHelperText>Price</FormHelperText>
              </FormControl>
              {this.props.isAuction && (
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 30, maxWidth: 70, minHeight: 20, maxHeight: 20 }}>
                      <Select
                          id="duration-input"
                          value={this.state.duration}
                          style={{marginLeft: 30}}
                          onChange={(event: any) => this.setState({ duration: Number(event.target.value) })}
                      >
                      {[1,2,3,4,5,6,7,8,9,10,11,12,13,14].map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                      </Select>
                      <FormHelperText style={{overflow: 'visible', marginLeft: 8, width: 100}} id="demo-simple-select-helper-label">Duration (Days)</FormHelperText>

                  </FormControl>
              )}
          </div>
        <button className="" onClick={this.onConfirm}>
            List for Sale
          </button>
          <button className="clear" onClick={this.props.handleClose}>
            Close
          </button>
        </div>
      </div>
    )
  }
}

export default AnnounceSale
