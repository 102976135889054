import React from 'react'
import { withRouter } from 'react-router'
import firebase from '../../firebase'
import { LazyAlert } from './Alert'
import { inputStyle } from './generalStyles'
import { linkStyle } from './Login'
import NavBar from './NavBar'

class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Error',
      description: '',
      email: '',
      open: false
    }
    this.handleSignUp = this.handleSignUp.bind(this)
  }

  validateUsername(username) {
    const illegalChars = /\W/ // allow letters, numbers, underscores

    if (username === '') {
      this.setState({
        description: 'Enter a username.',
        open: true,
      })
      return false
    } else if (username.length < 4 || username.length > 16) {
      this.setState({
        description: 'Username must be between 4 and 16 characters in length.',
        open: true,
      })
      return false
    } else if (illegalChars.test(username)) {
      this.setState({
        description: 'Username must consist of only letters, numbers, and underscores.',
        open: true,
      })
      return false
    } else {
      return true
    }
  }

  validateEmail(email) {
    if (email === '') {
      this.setState({
        description: 'Enter a valid email address.',
        open: true,
      })
      return false
    } else {
      return true
    }
  }

  usernameExists(username) {
    let db = firebase.firestore()
    return db
      .collection('users')
      .doc(username)
      .get()
      .then((doc) => {
        if (doc.data() === undefined) {
          return false
        } else {
          return true
        }
      })
  }

  handleSignUp(event) {
    event.preventDefault()
    var { email, password, username } = event.target.elements
    username = username.value.toLowerCase()
    if (this.validateEmail(email.value) && this.validateUsername(username)) {
      this.usernameExists(username).then((exists) => {
        if (!exists) {
          // create new user account
          firebase
            .auth()
            .createUserWithEmailAndPassword(email.value, password.value)
            .then(() => {
              // add username mapping
              let db = firebase.firestore()
              db.collection('usernames')
                .doc(firebase.auth().currentUser.uid)
                .set({ username: username })
                .then(() => {
                  // set blank user object
                  db.collection('users')
                    .doc(username)
                    .set({ created_at: new Date() })
                    .then(() => {
                      // store username for later reference
                      localStorage.setItem('username', username)

                      // log event
                      firebase.analytics().logEvent('sign_up', {
                        email: email.value,
                        username: username
                      })
                      this.props.history.push('/')
                    })
                    .catch((error) => {
                      window.alert(error)
                    })
                })
                .catch((error) => {
                  window.alert(error)
                })
            })
            .catch((error) => {
              window.alert(error)
            })
        } else {
          this.setState({
            description: 'This username is already taken. Please try again.',
            open: true,
          })
        }
      })
    }
  }

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const emailFromURL = params.get('email');
    if (emailFromURL) {
      this.setState({ ...this.state, email: emailFromURL })
    }
  }

  updateEmail(value) {
    this.setState({ ...this.state, email: value })
  }

  render() {
    const allowingSignups = true
    const signupForm = (
      <form onSubmit={this.handleSignUp} style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <input onChange={e => this.updateEmail(e.target.value)} value={this.state.email} name="email" type="email" placeholder="Email" style={{ width: '300px', ...inputStyle }} />
          <input name="username" type="text" placeholder="Username" style={{ width: '300px', ...inputStyle }} />
          <input
            name="password"
            type="password"
            placeholder="Password"
            style={{ width: '300px', ...inputStyle }}
            autocomplete="off"
          />
          <center>
            <button type="submit" style={{ marginTop: '20px' }}>
              Sign Up
            </button>
          </center>
          <center style={{ marginTop: '20px', }}>
            <span>Already have an account?&nbsp;&nbsp;</span>
            <a href="/login" className={'text-hover'} style={linkStyle}>
              Sign in.
            </a>
          </center>
        </div>
      </form>
    )
    const notAllowingSignupsMessage = (
      <form>
        <center>
          Lazy is still in beta and we are not currently allowing new signups. Check back soon!
        </center>
        <center>
          <a href="/login" style={{ display: 'block', marginTop: '20px', color: '#000000' }}>
            Already have an account? Sign in.
          </a>
        </center>
      </form>
    )
    return (
      <div>
        <NavBar />
        <LazyAlert open={this.state.open}
          title={this.state.title}
          description={this.state.description}
          onClose={() => this.setState({ open: false })}
        />
        <div className="container">
          <div className="heading">
            <span className="title">Sign Up</span>
          </div>
          {allowingSignups && signupForm}
          {!allowingSignups && notAllowingSignupsMessage}
        </div>
      </div>
    )
  }
}

/*const SignUp = ({ history }) => {
  const handleSignUp = useCallback(async event => {
    event.preventDefault();
    const { email, password, username } = event.target.elements;

    let username_edited = username.value.toLowerCase();

    // check if username exists
    try {
        let db = firebase.firestore();
        db.collection("users").doc(username_edited).get().then(doc => {
            if (doc.data() === undefined) {
                // ok
                db.collection("users").doc(username_edited).set({created_at: new Date()}).then(success => {
                    try {
                        firebase
                          .auth()
                          .createUserWithEmailAndPassword(email.value, password.value).then(() => {
                            db.collection("usernames").doc(firebase.auth().currentUser.uid).set({username: username_edited}).then(success2 => {
                              // set local username value
                              localStorage.setItem('username', username_edited)
                              history.push("/");
                            })
                          })
                      } catch (error) {
                        alert(error);
                      }
                })
            }
            else {
                window.alert("The username is taken. Try again.")
            }
        })
    } catch (error) {
        alert(error);
    }
  }, [history]);

  return (
    <div>
        <NavBar />
        <div className="container">
            <div className="heading">
              <span className="title">Sign Up</span>
            </div>
            <form onSubmit={handleSignUp} style={{display: "flex", justifyContent: "center"}}>
                <div>
                  <input name="email" type="email" placeholder="Email" style={{width: "300px"}} />
                  <input name="username" type="text" placeholder="Username" style={{width: "300px"}} />
                  <input name="password" type="password" placeholder="Password" style={{width: "300px"}} />
                  <center><button type="submit" style={{marginTop: "20px"}}>Sign Up</button></center>
                  <center><a href="/login" style={{display: "block", marginTop: "20px", color: "#000000"}}>Already have an account? Sign in.</a></center>
                </div>
            </form>
        </div>
    </div>
  );
};*/

export default withRouter(SignUp)
