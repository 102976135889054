import React from 'react'
import { withRouter } from 'react-router'
import firebase from '../../firebase'
import { LazyAlert } from './Alert'
import { inputStyle } from './generalStyles'
import NavBar from './NavBar'

class ResetPassword extends React.Component {
  constructor(props) {
    super( props )
    this.state = {
      title: 'Error',
      description: '',
      open: false
    }
    this.handleResetPassword = this.handleResetPassword.bind(this)
  }

  handleResetPassword(event) {
    event.preventDefault()
    var { email } = event.target.elements

    if (email.value) {
      // reset password
      firebase
        .auth()
        .sendPasswordResetEmail(email.value)
        .then( () => {
          this.setState( {
            description: 'We just sent a reset link to your email address.',
            open: true,
          })
        })
        .catch((error) => {
          alert(error)
        })
    } else {
      this.setState( {
        description: 'Enter your email address.',
        open: true,
      } )
    }
  }

  render() {
    return (
      <div>
        <NavBar />
        <LazyAlert open={ this.state.open }
          title={ this.state.title }
          description={ this.state.description }
          onClose={() => this.setState({ open: false })}
        />
        <div className="container">
          <div className="heading">
            <span className="title">Reset Password</span>
          </div>
          <form
            onSubmit={this.handleResetPassword}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div>
              <input name="email" type="email" placeholder="Email" style={{ width: '300px', ...inputStyle }} />
              <center>
                <button type="submit" style={{ marginTop: '20px' }}>
                  Reset Password
                </button>
              </center>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(ResetPassword)
