import { Box, CircularProgress } from '@mui/material';
import React from 'react'

type Props = {
    title: string;
    message?: string;
    handleClose: () => void;
}

class LoadingModal extends React.Component<Props> {
  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
            <span className="title">{this.props.title}</span>
            <Box sx={{ display: 'flex', }}>
                <CircularProgress style={{ color: 'rgba(0,0,0,.3)', margin: '0 auto' }}  size={250} />
            </Box>
            {this.props.message}
            <button className="clear" onClick={this.props.handleClose} style={{ marginTop: 20}}>
                Wait for Transaction or Close
            </button>
        </div>
      </div>
    )
  }
}

export default LoadingModal
