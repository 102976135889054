import { createRef, useEffect, useState } from 'react'
import NavBar from '../general/NavBar'
import firebase from '../../firebase'
import '../general/NFTDetail.css'
import { isArray, isNil } from 'lodash'
import TezosMarketplace from './TezosMarketplace'
import { Item } from '@rarible/api-client/build/models/Item'
import { loadTezosNFT, TezosItem } from './TezosHelper'
import VideoWSkeleton from '../VideoWSkeleton'
import ImageWSkeleton from '../ImageWSkeleton'

export default function TezosDetail(props: any) {
  const assetID = props.match.params.assetID
  const waxMarketRef: any = createRef()

  const [NFT, setNFT] = useState<Item | undefined>()
  const [showBundle, setShowBundle] = useState<boolean>(false);

  useEffect(() => {
    loadNFT()
  }, [])


  const loadNFT = async () => {
    const _NFT = await loadTezosNFT(assetID);
    if (_NFT && !isArray(_NFT)) {
        setNFT(_NFT)
    }
  }

  if (!NFT) return <div/>
  const item: Item = NFT || {} as any;

  const owner = item.lastSale ? item.lastSale.buyer! : (item.bestSellOrder && item.bestSellOrder!.maker);

  const handleBuy = () => {
    waxMarketRef.current.handleBuy(item, owner);
  };

  const viewOnWax = (url: string) => {
    window.open(url)
  }

  const assetId = `${item.collection}:${item.tokenId}`;
  const url = `https://rarible.com/token/tezos/${assetId.replace('TEZOS:', '')}`;
  let media,
        animation_url: string,
        img_url: string | undefined,
        poster;

    const preview = item.meta && 
        item.meta.content.find(x => x.representation == 'PREVIEW')!;
    const original = item.meta && 
        item.meta.content.find(x => x.representation == 'ORIGINAL')!;
    let isVideo = false;
    if (original) { // get original
        isVideo = original.mimeType == "video/mp4"
        if (isVideo) {
          if (preview && preview.url && preview.url.length) {
            img_url = preview.url.split('ipfs/')[1];
            poster = `https://ipfs.ledgerwise.io/ipfs/${img_url}`;
            media = (<VideoWSkeleton videoSrc={original.url} poster={poster} imgClassName='detail-video' />)
          } else {
            animation_url = original.url;
              media = (<VideoWSkeleton videoSrc={animation_url} imgClassName='detail-video' />)
          }
          } else if (preview && preview.url) {
            img_url = preview.url.split('ipfs/')[1];
            poster = `https://ipfs.ledgerwise.io/ipfs/${img_url}`;
            media = (<ImageWSkeleton imgClassName='detail-image' imgSrc={poster} />)
          } else if (original && original.url) {
            img_url = original.url.split('ipfs/')[1];
            poster = `https://ipfs.ledgerwise.io/ipfs/${img_url}`;
            media = (<ImageWSkeleton imgClassName='detail-image' imgSrc={poster} />)
          }
      } else if (preview) {
        const originalCid = preview.url.split('ipfs/')[1];
        const url = `https://ipfs-resizer.ledgerwise.io/ipfs/${originalCid}`;
        media = (<ImageWSkeleton imgClassName='detail-image' imgSrc={url} />)
      } else {
        media = (<></>);
      }
    

    const tezosNft = item as TezosItem;
    const isOnSale = tezosNft.ownership && tezosNft.ownership.bestSellOrder && tezosNft.ownership.owner == this.state.owner;

    let listingPrice = 0, listingCurrency, showPrice: any = 0;
    if (isOnSale) {
        listingPrice = Number(tezosNft.ownership!.bestSellOrder!.makePrice || 0);
        listingCurrency = 'XTZ'
        showPrice = listingPrice.toFixed(2);
    }

    const contractId = item.contract && item.contract.split(':')[1];
    const tzktUrl = `https://tzkt.io/${contractId}/tokens/${item.tokenId}/transfers`;
    const objktUrl = `https://objkt.com/asset/${contractId}/${item.tokenId}`;

  return (
    <div>
      <NavBar />
      <div className="nft-detail-media-container">
       {media}
      </div>
      <div className="container detail-padding" style={{ height: 600 }}>
        <span className="detail-name">{item.meta!.name || 'NFT Still Processing...'}</span>
        <span className="detail-description">{item.meta!.description}</span>
        <div style={{ textAlign: 'center'}}>
          {isOnSale && (
            <div style={{ textAlign: 'center'}}>
                <button
                    className="clear"
                    onClick={handleBuy}
                    style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                    Buy for {showPrice} {listingCurrency}
                </button>
            </div>
        )}
        <div style={{ textAlign: 'center'}}>
        <button
            onClick={() => viewOnWax(url)}
            className="clear"
            style={{ marginBottom: '10px', marginTop: '50px' }}
          >
            View on Rarible
          </button>
          </div>
          <div style={{ textAlign: 'center'}}>
          <button
            onClick={() => viewOnWax(objktUrl)}
            className="clear"
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            View on Objkt
          </button>
          </div>
          <button
            onClick={() => viewOnWax(tzktUrl)}
            className="clear"
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            View on Tzkt
          </button>
        </div>
      </div>
      <TezosMarketplace ref={waxMarketRef as any} />
    </div>
  )
}
