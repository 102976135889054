import { clusterApiUrl, Connection, PublicKey } from '@solana/web3.js'
import { getMultipleAccountsAsync } from '../../util/solana_utils'
import { findMetadataAddress, decodeMetadata, fetchUriData } from '../../util/collectible_utils'
import { TOKEN_PROGRAM_ID } from '../../util/program_ids'
import { sampleSize } from 'lodash'

import axios from 'axios';

export const loadSolanaNFTs = async (walletAddress, page) => {
  const data = {
    jsonrpc: "2.0",
    id: 1,
    method: "qn_fetchNFTs",
    params: {
      wallet: walletAddress,
      page,
      perPage: 40,
    },
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(
      "https://shy-wider-dust.solana-mainnet.discover.quiknode.pro/f12b7cdbc4265fb094829ccbc39b248f7b091493/",
      data,
      config
    );

    console.log('Response data:', response.data);

    // Extract the assets array from the response
    const nfts = response.data.result && response.data.result.assets ? response.data.result.assets : [];

    // Perform additional processing on the NFTs if needed

    return nfts;
  } catch (error) {
    console.error(error);
    return [];
  }
};



export const loadSolanaNFT = async (walletAddress, contractAddress) => {
  // console.log('About to load Solana NFTs: ', {walletAddres, contractAddres})
  const solanaNfts = await loadSolanaNFTs(walletAddress);
  //console.log('Solana NFTs:', solanaNfts);
  //console.log('Contract Address:', contractAddress);
  
  const asset = solanaNfts.find((item) => item.tokenAddress === contractAddress);
  
  console.log('Filtered asset:', asset);
  
  return asset;
};

export const loadFeaturedSolanaNFTs = async (walletAddresses, contractAddresses, owners) => {
  const promises = walletAddresses.map((walletAddress) => loadSolanaNFTs(walletAddress))
  const results = await Promise.all(promises)
  const nfts = results.map((candidates, index) => {
    if (!Array.isArray(candidates)) {
      candidates = [candidates]
    }

    return {
      ...candidates.find((item) => item.tokenAddress === contractAddresses[index]),
      id: owners[contractAddresses[index]]['id'],
      walletAddress: walletAddresses[index]
    }
  })

  nfts.forEach((x, i) => {
    if (!x.imageUrl && results[0]) {
      const images = nfts.map((x) => x.imageUrl)
      nfts[i] = {
        ...results[0].find((item) => images.indexOf(item.image) == -1 && !item.animationUrl),
        walletAddress: walletAddresses[0]
      }
    }
  })

  return nfts
}

export const loadSolanaPins = async (walletAddress, pins) => {
  // load a batch of Polygon NFTs
  const NFTs = []
  const solanaNfts = await loadSolanaNFTs(walletAddress)

  for (const pin of pins) {
    const asset = solanaNfts.find((item) => item.tokenAddress === pin.contractAddress)
    const nft = {
      type: 'Solana',
      index: pin.index,
      data: asset,
      walletAddress,
      pinID: pin.id,
      caption: pin.caption
    }
    NFTs.push(nft)
  }

  return NFTs
}
