import React from 'react'

class WalletConfirmation extends React.Component {
  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">{this.props.walletName}</span>
          <span className="description">Waiting for {this.props.walletName} confirmation...</span>
          <center>
            <div className="loading-wheel"></div>
          </center>
        </div>
      </div>
    )
  }
}

export default WalletConfirmation
