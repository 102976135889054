import React from 'react'

class UnlistConfirmation extends React.Component {
  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">Unlist NFT</span>
          <span className="description">
            You are about to unlist <b>{this.props.name}</b> which is currently priced at{' '}
            {this.props.price / 1000000000000000000} ETH.
          </span>
          <button className="theme" onClick={this.props.handleContinue}>
            Continue
          </button>
          <button className="clear" onClick={this.props.handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    )
  }
}

export default UnlistConfirmation
