import portisModule from '@web3-onboard/portis'
import fortmaticModule from '@web3-onboard/fortmatic'
import torusModule from '@web3-onboard/torus'

const fortmatic = fortmaticModule({ apiKey: 'pk_live_C0C8DF37B01E10E4' })
const portis = portisModule({ apiKey: '09eeb3e0-678d-45f1-99d3-5a5db34a074f' })
const torus = torusModule()

export const walletPlugins = [
    portis,
    fortmatic,
    torus,
]

export const REACT_CORS = 'https://lazy-cors.herokuapp.com/';
export const MAINNET_RPC_URL = 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
export const AVAX_MAINNET_RPC_URL = 'https://avalanche-mainnet.infura.io/v3/2cb0b2337da7414680a6e1390603e594';