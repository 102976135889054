import React from 'react'

type Props = {
    title: string;
    message: string;
    handleClose: () => void;
    handleSubmit?: () => void;
    hideSubmit?: boolean;
}

class GenericModal extends React.Component<Props> {
  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">{this.props.title}</span>
          <span className="description" style={{ whiteSpace: 'pre-wrap'}}>{this.props.message}</span>
          {!this.props.hideSubmit && <button className="clear" onClick={this.props.handleSubmit}>
            Submit
          </button>}
          <button className="clear" onClick={this.props.handleClose}>
            Close
          </button>
        </div>
      </div>
    )
  }
}

export default GenericModal
