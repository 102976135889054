import { useEffect, useState } from 'react'
import axios from 'axios'
//import { loadPolygonNFT } from './PolygonHelper'
import NavBar from '../general/NavBar'
import firebase from '../../firebase'
import '../general/NFTDetail.css'
import VideoWSkeleton from '../VideoWSkeleton'
import ImageWSkeleton from '../ImageWSkeleton'

export default function PolygonDetail(props) {
  let walletAddress = props.match?.params?.walletAddress?.toLowerCase() || "";
  let contractAddress = props.match?.params?.contractAddress?.toLowerCase() || "";
  let tokenID = props.match?.params?.tokenID?.toLowerCase() || "";
  

  const [NFT, setNFT] = useState({})
  const [animationURL, setAnimationURL] = useState('')
  const [imageURL, setImageURL] = useState('')

  useEffect(() => {
    loadNFT()
  }, [])

  useEffect(() => {
    setAnimationURL(loadAnimationURL())
    setImageURL(loadImageURL())
  }, [NFT])

  const loadSinglePolygonNFT = async (contractAddress, tokenID) => {
    const options = {
      method: 'GET',
      url: `https://api.opensea.io/v2/chain/matic/contract/${contractAddress}/nfts/${tokenID}`,
      headers: {
        accept: 'application/json', 
        'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY // using the environment variable
      }
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const loadNFT = async () => {
    try {
      let _NFT = await loadSinglePolygonNFT(contractAddress, tokenID);
     // console.log("Loading Polygon NFT:", _NFT);
      setNFT(_NFT.nft);
    } catch (error) {
      console.error("Error loading NFT:", error);
      // Handle the error appropriately, for example, set an error state or show a notification
    }
  }

  const loadAnimationURL = () => {
    if (Object.keys(NFT).length === 0) {
      return null
    }

    let _animationURL = NFT.animation_url
    if (_animationURL === null) {
      _animationURL = NFT.asset_contract.animation_url
    }
    return _animationURL
  }

  const loadImageURL = () => {
    if (Object.keys(NFT).length === 0) {
      return null
    }

    let _imageURL = NFT.image_url
    if (_imageURL === null) {
      _imageURL = NFT.image_preview_url
    }
    if (_imageURL === null) {
      _imageURL = NFT.asset_contract.image_url
    }
    if (_imageURL === null) {
      _imageURL = NFT.animation_url;
    }
    if (_imageURL === null) {
      _imageURL = NFT.animation_original_url;
    }
  //  if (_imageURL.toLowerCase().endsWith('.mp4')) {
  //    setAnimationURL(_imageURL)
  //    //_imageURL = assets[0].image_thumbnailq_url;
  //  }
    if (
      (_imageURL === null || _imageURL === undefined) &&
      (animationURL === null || animationURL === undefined)
    ) {
      _imageURL = '/empty-media.png'
    }
    return _imageURL
  }

  const loadMedia = () => {
    if (animationURL) {
      return <VideoWSkeleton videoSrc={animationURL} poster={imageURL} imgClassName='detail-video' />
    } else {
      return <ImageWSkeleton imgClassName='detail-image' imgSrc={imageURL} />
    }
  }

  const loadOpenSeaURL = () => {
    if (!process.env.REACT_APP_TESTING) {
      return (
        'https://opensea.io/assets/matic/' +
        contractAddress +
        '/' +
        tokenID +
        '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      )
    } else {
      return (
        'https://testnets.opensea.io/assets/mumbai/' +
        contractAddress +
        '/' +
        tokenID +
        '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
      )
    }
  }

  const viewOnOpenSea = (openseaURL) => {
    firebase.analytics().logEvent('opensea_click', {
      contract_address: '_' + contractAddress,
      token_id: '_' + tokenID,
      wallet_address: '_' + walletAddress
    })

    window.open(openseaURL)
  }

  let media = loadMedia()
  let openseaURL = loadOpenSeaURL()

  // check if processing
  if (NFT.name === null && NFT.description === null && NFT.image_url === null) {
    setImageURL('/empty-media.png')
  }

  return (
    <div>
      <NavBar />
      <div className="nft-detail-media-container">{media}</div>
      <div className="container detail-padding">
        <span className="detail-name">{NFT.name || 'NFT Still Processing...'}</span>
        <span className="detail-description">{NFT.description || 'Check back later!'}</span>
        <center>
          <button
            onClick={() => viewOnOpenSea(openseaURL)}
            className="clear"
            style={{ marginBottom: '50px', marginTop: '50px' }}
          >
            View on OpenSea
          </button>
        </center>
      </div>
    </div>
  )
}
