import { createRef, useEffect, useState } from 'react'
import axios from 'axios'
import firebase from '../../firebase'
import NavBar from './NavBar'
import AllNFTs from './AllNFTs'
import { PinnedNFTsNew } from './PinnedNFTsNew'
import Marketplace from './Marketplace'
import WaxMarketplace from '../wax/WaxMarketplace'
import { loadWaxAuctionsByOwner } from '../wax/WaxHelper'
import { WaxAuctionNFTs } from './WaxAuctionNFTs'

import { PortfolioEditModal } from './PortfolioEditModal'
import GenericModal from '../modals/GenericModal'
import { doOnboard } from '../../util/helpers'
import { ProfileDisplay } from './ProfileDisplay'
import TezosMarketplace from '../tezos/TezosMarketplace'

export default function NewPortfolio(props) {
  const db = firebase.firestore()
  const username = props.match.params.username.toLowerCase()
  const marketplaceRef = createRef()
  const waxMarketRef = createRef()
  const tezosMarketRef = createRef()
  const [avalancheNFTs, setAvalancheNFTs] = useState([]);

  const [userExists, setUserExists] = useState()
  const [userLoading, setUserLoading] = useState(true);
  const [wallets, setWallets] = useState([])
  const [pins, setPins] = useState([])
  const [hiddenItems, setHiddenItems] = useState([])
  const [isLoadingHiddenItem, setIsLoadingHiddenItem] = useState(false)
  const [lastHideActionType, setLastHideActionType] = useState('')
  const [pinsOrder, setPinsOrder] = useState([])
  const [hasLoaded, setHasLoaded] = useState(false) // for Wallets
  const [pinsLoaded, setPinsLoaded] = useState(false)
  // WAX auctions cause user to lose ownership of asset so this is needed
  const [waxAuctionData, setWaxAuctionData] = useState()
  // Profile
  const [showEditModal, setShowEditModal] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [profilePictureSuccess, setProfilePictureSuccess] = useState(false);

  useEffect(() => {
    checkIfUserExists()
  }, [])

  useEffect(() => {
    if (userExists) {
      loadPins()
      loadWallets()
      loadHiddenItems()
    }
  }, [userExists])

  useEffect(() => {
    if (wallets && wallets.length > 0 && wallets.find(x => x.type == 'Wax')) {
      loadWaxAuctionData(wallets.find(x => x.type == 'Wax').address);
    }
  }, [wallets])

  useEffect(() => {
    if (hasLoaded) {
      loadPins()
    }
  }, [pinsOrder])

  const loadProfile = () => {
    db.collection('users')
      .doc(username)
      .get()
      .then((doc) => {
        if (doc.data().pins_order) {
          setPinsOrder(doc.data().pins_order)
        }
        setUserExists(doc.exists)
      })
  }

  const checkIfUserExists = () => {
    db.collection('users')
      .doc(username)
      .get()
      .then((doc) => {
        const data = doc.data();
        setUserLoading(false);
        if (data) {
          const profileData = {
            bio: data.bio,
            twitter: data.twitter,
            facebook: data.facebook,
            personalUrl: data.personalUrl,
            profilePictureUrl: data.profilePictureUrl,
            profileVideoUrl: data.profileVideoUrl,
          };
          setProfileData(profileData);
  
          if (data.pins_order) {
            setPinsOrder(doc.data().pins_order)
          }
          setUserExists(doc.exists)
        }
      })
  }

  const getProfileData = () => {
    db.collection('users')
      .doc(username)
      .get()
      .then((doc) => {
        const data = doc.data();
        const profileData = {
          bio: data.bio,
          twitter: data.twitter,
          facebook: data.facebook,
          personalUrl: data.personalUrl,
          profilePictureUrl: data.profilePictureUrl,
          profileVideoUrl: data.profileVideoUrl,
        };
        setProfileData(profileData);
      })
  }

  const setAsProfilePicture = (url, videoUrl) => {
    const postObject = { profilePictureUrl: url, profileVideoUrl: videoUrl ? videoUrl : '' };
    db.collection('users').doc(username).update(postObject);
    setProfilePictureSuccess(true)
  }

  const checkIfAuthor = () => {
    if (localStorage.getItem('username')) {
      return username.toLowerCase() === localStorage.getItem('username').toLowerCase()
    }
    return false
  }


  const loadWaxAuctionData = (owner) => {
    loadWaxAuctionsByOwner(owner).then((auctions) => {
      setWaxAuctionData(auctions);
    })
  }

  const loadWallets = () => {
    db.collection('users')
      .doc(username)
      .collection('wallets')
      .get()
      .then((snapshot) => {
        const wallets = []
        snapshot.docs.forEach((doc) => {
          const data = doc.data()
          wallets.push({ id: doc.id, type: data.type, address: data.address })
        })
        setWallets(wallets)
        setHasLoaded(true)
      })
  }

  const loadPins = () => {
    db.collection('users')
      .doc(username)
      .collection('pinned')
      .orderBy('created_at', 'asc')
      .get()
      .then((snapshot) => {
        const pins = []
        snapshot.docs.forEach((doc, idx) => {
          const data = doc.data()

          // default type to Ethereum
          let type = 'Ethereum'
          if (data.type) {
            type = data.type
          }

          // default index to created_at sorting
          let index = idx
          if (pinsOrder.length > 0) {
            index = pinsOrder.indexOf(doc.id)
            if (index === -1) {
              index = idx
            }
          }
          pins.push({
            id: doc.id,
            index,
            contractAddress: data.contractAddress,
            tokenID: data.tokenID,
            walletAddress: data.walletAddress,
            caption: data.caption,
            type
          })
        })
        setPins(pins)
      })
  }

  const loadHiddenItems = () => {
    setIsLoadingHiddenItem(true)
    db.collection('users')
      .doc(username)
      .collection('hidden')
      .orderBy('created_at', 'asc')
      .get()
      .then((snapshot) => {
        const hiddens = []
        snapshot.docs.forEach((doc, idx) => {
          const data = doc.data()

          // default type to Ethereum
          let type = 'Ethereum'
          if (data.type) {
            type = data.type
          }
          hiddens.push({
            id: doc.id,
            contractAddress: data.contractAddress,
            tokenID: data.tokenID,
            walletAddress: data.walletAddress,
            caption: data.caption,
            type
          })
        })
        setHiddenItems(hiddens)
        setIsLoadingHiddenItem(false)
      })
  }

  const checkPinCount = async (db, username) => {
    const snapshot = await db.collection('users').doc(username).collection('pinned').get();
    if (snapshot.docs.length >= 30) {
      alert('You can only pin 30 NFTs.');
      throw new Error('Pin count exceeded.');
    }
  };
  
  const addPinToFirestore = async (db, username, newData) => {
    await db
      .collection('users')
      .doc(username)
      .collection('pinned')
      .doc()
      .set(newData);
  };
  

  const getPinData = (type, data) => {
    let newData = {
      created_at: new Date(),
      caption: "",
      type,
    };
  
    if (type === "Ethereum" || type === "NBA Top Shot" || "Tezos") {
      newData.walletAddress = data.walletAddress;
      newData.contractAddress = data.contractAddress;
      newData.tokenID = data.tokenID;
    } else if (type === "Solana") {
      newData.walletAddress = data.walletAddress;
      newData.contractAddress = data.tokenAddress;
    } else if (type === "Wax") {
      newData.walletAddress = data.walletAddress;
      newData.contractAddress = "";
      newData.tokenID = data.tokenID;
    } else if (type === "Avalanche") {
      newData.walletAddress = data.walletAddress;
      newData.contractAddress = data.contractAddress;
      newData.tokenID = data.tokenID;
    } else if (type === "Polygon" ) {  
      newData.walletAddress = data.walletAddress;
      newData.contractAddress = data.contract;
      newData.tokenID = data.identifier;
    }
      else {
      throw new Error("Chain not found.");
    }
  
    return newData;
  };
  
  const checkIfPinned = async (db, username, newData) => {
    if (!newData.walletAddress || !newData.contractAddress || !newData.tokenID) {
      console.error("Invalid data for checkIfPinned:", newData);
      throw new Error("Invalid data for checkIfPinned.");
    }
    const ref = db.collection("users").doc(username).collection("pinned");
    const snapshot = await ref
      .where("walletAddress", "==", newData.walletAddress)
      .where("contractAddress", "==", newData.contractAddress)
      .where("tokenID", "==", newData.tokenID)
      .get();
  
    return !snapshot.empty;
  };
  

  const handlePin = async (type, data) => {
    try {
      await checkPinCount(db, username);
  
      const newData = getPinData(type, data);
  
      const nftExists = await checkIfPinned(db, username, newData);
      if (nftExists) {
        alert("You've already pinned this NFT.");
        return;
      }
  
      const caption = prompt("Enter a caption: ");
      if (caption === null) throw new Error("Caption exited.");
      newData.caption = caption;
  
      await addPinToFirestore(db, username, newData);
  
      loadPins();
    } catch (error) {
      console.error(error.message);
    }
  };
  

  const handleUnpin = async (pinID) => {
  try {
    const confirmUnpin = window.confirm('Are you sure you want to unpin this NFT?');
    if (!confirmUnpin) return;

    await db.collection('users').doc(username).collection('pinned').doc(pinID).delete();

    // reload pins to refresh the pinned NFTs list
    loadPins();
  } catch (error) {
    console.error('Failed to unpin the NFT:', error);
  }
};

const setHiddenNFT = (data) => {
  return db
    .collection("users")
    .doc(username)
    .collection("hidden")
    .doc()
    .set({
      created_at: new Date(),
      ...data,
    });
};

const handleHideSuccess = (type, data) => {
  setLastHideActionType(type);
  setHiddenItems([...hiddenItems, data]);
  loadHiddenItems();
  alert("Success! Your NFT has been hidden.");
  location.reload();
};

const removeHiddenItemFromView = (data) => {
  setNfts((prevNfts) =>
    prevNfts.filter(
      (nft) =>
        nft.data.walletAddress !== data.walletAddress ||
        nft.data.contractAddress !== data.contractAddress ||
        nft.data.tokenID !== data.tokenID
    )
  );
};

const handleHide = (type, data) => {
  const supportedChains = [
    "Ethereum",
    "Polygon",
    "NBA Top Shot",
    "Solana",
    "Wax",
    "Avalanche",
    "Tezos"
  ];
  if (!supportedChains.includes(type)) {
    return Promise.reject("Chain not found.");
  }

  //    type === "Polygon" ||

  const nftData =
    type === "Ethereum" ||
    type === "NBA Top Shot" ||
    type === "Avalanche" ||
    type === "Tezos"
      ? {
          walletAddress: data.walletAddress,
          contractAddress: data.contractAddress,
          tokenID: data.tokenID,
          type,
        }
      : type === "Solana"
      ? {
          walletAddress: data.walletAddress,
          contractAddress: data.tokenAddress,
          type,
        }
      : type === "Polygon"
      ? {
        walletAddress: data.walletAddress,
        contractAddress: data.contract,
        type,
      }
      : {
          walletAddress: data.walletAddress,
          contractAddress: "",
          tokenID: data.tokenID,
          type,
        };

  return db
    .collection("users")
    .doc(username)
    .collection("hidden")
    .get()
    .then(() => setHiddenNFT(nftData))
    .then(() => handleHideSuccess(type, nftData))
    .then(() => removeHiddenItemFromView(nftData))
    .catch((err) => {
      console.log(err);
      return Promise.reject("Check hidden count failed.");
    });
};




  const savePinsOrder = (_pinsOrder) => {
    if (checkIfAuthor()) {
      db.collection('users').doc(username).update({ pins_order: _pinsOrder })
      setPinsOrder(_pinsOrder)
    }
  }

  const handleBuy = (data) => {
    marketplaceRef.current.handleBuy(
      data.contractAddress,
      data.tokenID,
      data.name,
      data.listingPrice,
      data.ownerWalletAddress,
      data.listingCurrency,
      data.order
    )
  }

  const handleSell = (data) => {
    marketplaceRef.current.handleSell(
      data.contractAddress,
      data.tokenID,
      data.name,
      data.schemaName,
      data.ownerWalletAddress
    )
  }

  const handleWaxSell = (tokenID, ownerWalletAddress) => {
    waxMarketRef.current.handleAnnounceSale(
      tokenID,
      ownerWalletAddress
    )
  }

  const handleWaxCancelSell = (saleID) => {
    waxMarketRef.current.handleCancelSale(
      saleID
    )
  }

  const handleTezosBuy = (nft, wallet) => {
    tezosMarketRef.current.handleBuy(nft, wallet);
  }

  const handleTezosSell = (tokenID, ownerWalletAddress) => {
    tezosMarketRef.current.handleAnnounceSale(
      tokenID,
      ownerWalletAddress
    )
  }

  const handleTezosCancelSell = (saleID) => {
    tezosMarketRef.current.handleCancelSale(
      saleID
    )
  }

  const handleWaxAuction = (tokenID, ownerWalletAddress) => {
    waxMarketRef.current.handleAnnounceAuction(
      tokenID,
      ownerWalletAddress
    )
  }

  const handleWaxCancelAuction = (saleID) => {
    waxMarketRef.current.handleCancelAuction(
      saleID
    )
  }

  const handleWaxBidAuction = (nft) => {
    waxMarketRef.current.handleBidAuction(
      nft,
    )
  }

  const handleClaimWaxAuctionNFT = (nft) => {
    waxMarketRef.current.handleClaimAuctionNFT(
      nft,
    )
  }

  const handleClaimWaxMoney = (nft) => {
    waxMarketRef.current.handleClaimMoney(
      nft,
    )
  }

  const handleUnlist = (data) => {
    marketplaceRef.current.handleUnlist(
      data.contractAddress,
      data.tokenID,
      data.name,
      data.listingPrice,
      data.ownerWalletAddress,
      data.order
    )
  }

  const handleWaxBuy = (nft, wallet) => {
    waxMarketRef.current.handleBuy(nft, wallet);
  }

  const handleWaxBuyBundle = (salesData) => {
    waxMarketRef.current.handleBuyBundle(salesData);
  }

  const functions = {
    savePinsOrder: savePinsOrder,
    handlePin: handlePin,
    handleUnpin: handleUnpin,
    handleBuy: handleBuy,
    handleSell: handleSell,
    handleUnlist: handleUnlist,
    handleHide: handleHide,
    handleWaxSell: handleWaxSell,
    handleWaxCancelSell,
    handleWaxAuction,
    handleWaxCancelAuction,
    handleWaxBuy,
    handleWaxBidAuction,
    handleClaimWaxAuctionNFT,
    handleClaimWaxMoney,
    handleWaxBuyBundle,
    setAsProfilePicture,
    handleTezosBuy,
    handleTezosCancelSell,
    handleTezosSell
  }

  const waxAuctionNFTsComponent = (
    <WaxAuctionNFTs
      auctions={waxAuctionData}
      username={username}
      functions={functions}
      wallets={wallets}
    />
  );

  const pinnedNFTs = (
    <PinnedNFTsNew
      pins={pins}
      username={username}
      functions={functions}
      notifyPinsLoaded={() => {
        setPinsLoaded(true)
      }}
    />
  )

  const allNFTs = (
    <AllNFTs
      pins={pins}
      wallets={wallets}
      username={username}
      functions={functions}
      hiddenItems={hiddenItems}
      isLoadingHiddenItem={isLoadingHiddenItem}
      lastHideActionType={lastHideActionType}
      avalancheNFTs={avalancheNFTs}
    />
  )

  const initOnboard = () => {
    doOnboard();
    setWalletConnected(true);
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="heading" style={{ justifyContent: 'center', paddingLeft: 0, paddingTop: 20 }}>
        {checkIfAuthor() && (
                <button type="submit" style={{ position: 'absolute',  top: 80,}} onClick={() => setShowEditModal(true)}>
                  Edit Profile
                </button>
              )}
         <ProfileDisplay profileData={profileData} username={username} />
        </div>
        {waxAuctionNFTsComponent}
        {pins.length > 0 && pinnedNFTs}
        {wallets.length > 0 && (pinsLoaded || pins.length === 0) && allNFTs}
        {userExists && wallets.length === 0 && hasLoaded && (
          <center>This user has not added any wallets.</center>
        )}
        {userLoading && <center>Loading...</center>}
        {!userExists && !userLoading && <center>This user does not exist.</center>}
      </div>
     
      <Marketplace ref={marketplaceRef} />
      <WaxMarketplace ref={waxMarketRef} />
      <TezosMarketplace ref={tezosMarketRef} />
      {profilePictureSuccess && <GenericModal
          title={'Success'}
          message={`Successfully set your new profile picture.\n\nReload to see it!`}
          handleClose={() => setProfilePictureSuccess(false)}
          hideSubmit={true}
      />}
      {showEditModal && <PortfolioEditModal
        profileData={profileData}
        username={username}
        onClose={() => setShowEditModal(false)}
        onSubmit={() => {
          setShowEditModal(false);
          getProfileData();
        }}
      />}
    </>
  )
}
