import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useEffect, useMemo, useState } from 'react'
import firebase from '../../firebase'
import ImageWSkeleton from '../ImageWSkeleton'
import VideoWSkeleton from '../VideoWSkeleton'

export default function EthereumNFTNew(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.pinID
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const data = props.data
  const walletAddress = props.walletAddress
  const contractAddress = data.contract
  const tokenID = data.identifier
  const caption = props.caption
  const schemaName = data.token_standard
  const isDragging = props.isDragging

  const [name, setName] = useState(data.name)
  const [isAuthor, setIsAuthor] = useState(false)
  const [menuShown, setMenuShown] = useState(false)
  const [listAction, setListAction] = useState('')
  const [pinned, setPinned] = useState(props.pinned)
  const [listingPrice, setListingPrice] = useState()
  const [listedByUser, setListedByUser] = useState(false)
  const [listingCurrency, setListingCurrency] = useState('');

  const loadName = () => {
    if (name) {
      if (name.length > 40) {
        setName(name.substring(0, 40) + '...')
      }
    }
  }


  const isVideo = (fileURL) => {
    const fileURLParts = fileURL.split('.')
    const fileExtension = fileURLParts[fileURLParts.length - 1].toLowerCase()
    if (
      fileExtension === 'mp4' ||
      fileExtension === 'mov' ||
      fileExtension === 'webm' ||
      fileExtension === 'ogg'
    ) {
      return true
    }
    return false
  }

  const media = useMemo(() => {
    let returnValue = (<ImageWSkeleton width={320} height={320} imgSrc={data.image_url} />)
  
      if (data.animation_url) {
        if (isVideo(data.animation_url)) {
          returnValue = ( <VideoWSkeleton poster={data.image_url} videoSrc={data.animation_url} width={320} height={320} />)
        }
      }
  
      if (data.image_url) {
        if (isVideo(data.image_url)) {
          returnValue = ( <VideoWSkeleton poster={data.image_url} videoSrc={data.image_url} width={320} height={320} />)
        }
      }
      return returnValue;
  }, []);



  useEffect(() => {
    setIsAuthor(checkIfAuthor())
    loadListingInfo()
    loadName()
  }, [])

  useEffect(() => {
    loadListActions()
  }, [listingPrice, listedByUser, listingCurrency])

  const loadMenu = () => {
    let menu
    let menuButton

    if (menuShown) {
      const viewerIsAuthor = checkIfAuthor()
      const viewerIsAdmin = isAdmin()
      menu = (
        <div className="NFT-menu" style={{ display: 'inline-block' }}>
          {viewerIsAuthor && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleHide}>
              Hide
            </div>
          )}

          {viewerIsAuthor && props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnhide}>
              Unhide
            </div>
          )}

          {viewerIsAuthor && !pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handlePin}>
              Pin
            </div>
          )}
          {viewerIsAuthor && pinned && !props.hiddenItem && (
            <div className="NFT-menu-option" onClick={handleUnpin}>
              Unpin
            </div>
          )}
          {listAction === 'sell' && (
            <div className="NFT-menu-option" onClick={handleSell}>
              Sell
            </div>
          )}
          {listAction === 'unlist' && (
            <div className="NFT-menu-option" onClick={handleUnlist}>
              Unlist
            </div>
          )}
          {viewerIsAdmin && <div className="NFT-menu-option">Feature</div>}
          {viewerIsAuthor && <hr />}
          <div className="NFT-menu-option" onClick={handleViewRabbitHole}>
            RabbitHole
          </div>
          <div className="NFT-menu-option" onClick={handleViewBizarroWorld}>
            BizarroWorld
          </div>
          <div className="NFT-menu-option" onClick={handleViewOnOpenSea}>
            View on OpenSea
          </div>
          <div className="NFT-menu-option" onClick={viewOnEtherscan}>
            View on Etherscan
          </div>
          {viewerIsAuthor && <div className="NFT-menu-option" onClick={() => props.functions.setAsProfilePicture(data.image_url, data.animation_url)}>
            Set as Profile Picture
          </div>}
        </div>
      )
      menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-close-icon.png')" }}
        ></button>
      )
    } else {
      menu = <div className="NFT-menu" style={{ display: 'none' }}></div>
      menuButton = (
        <button
          className="options-btn"
          onClick={showOptions}
          style={{ backgroundImage: "url('/menu-more-icon.png')" }}
        ></button>
      )
    }
    return [menu, menuButton, pinned]
  }

  const showOptions = () => {
    setMenuShown(!menuShown)
  }

  const checkIfAuthor = () => {
    const username = localStorage.getItem('username')
    if (username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  const isAdmin = () => {
    if (firebase.auth().currentUser) {
      if (firebase.auth().currentUser.uid === 'hhoXubahBuWi9o4ifrKjHIVZe843') {
        return true
      }
    }
    return false
  }

  const handlePin = () => {
    setMenuShown(false)
    props.functions.handlePin(walletAddress, contractAddress, tokenID, 'Ethereum')
    .then(() => setPinned(true))
  }

  const handleHide = async () => {
    setMenuShown(false)
    props.functions.handleHide(walletAddress, contractAddress, tokenID, 'Ethereum').then(
      (value) => {
        // handleHide was successful
      },
      (reason) => {
        // handleHide was not successful
      }
    )
  }

  const handleUnhide = async () => {
    setMenuShown(false)
    props.functions.handleUnhide(props.pinID).then(
      (value) => {
        // handleUnhide was successful
      },
      (reason) => {
        // handleUnhide was not successful
      }
    )
  }

  const handleUnpin = async () => {
    setMenuShown(false)
    props.functions.handleUnpin(props.pinID)
    .then(() => {
      setPinned(false)
    })
  }

  const handleBuy = () => {
    setMenuShown(false)
    props.functions.handleBuy({
      walletAddress,
      contractAddress,
      tokenID,
      listingPrice,
      listingCurrency,
      name,
      order: data.seaport_sell_orders[0]
    })
  }

  const handleSell = () => {
    setMenuShown(false)
    props.functions.handleSell({
      walletAddress,
      contractAddress,
      tokenID,
      name,
      schemaName
    })
  }

  const handleUnlist = () => {
    setMenuShown(false)
    props.functions.handleUnlist({
      walletAddress,
      contractAddress,
      tokenID,
      listingPrice,
      name,
      schemaName,
      order: data.seaport_sell_orders[0]
    })
  }

  const handleViewRabbitHole = () => {
    setMenuShown(false)
    document.location.href =
      '/rabbithole/ethereum/'  + contractAddress + '/' + tokenID
  }

  const handleViewBizarroWorld = () => {
    setMenuShown(false)
    document.location.href =
      '/bizarroworld/ethereum/' + contractAddress + '/' + tokenID
  }

  const handleViewDetail = () => {
    if (props.isClickable !== false) {
      setMenuShown(false)
      document.location.href = '/ethereum/' + walletAddress + '/' + contractAddress + '/' + tokenID
    }
  }

  const handleViewOnOpenSea = () => {
    setMenuShown(false)
    window.open(
      'https://opensea.io/assets/ethereum/' +
        contractAddress +
        '/' +
        tokenID +
        '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
    )
  }

  const viewOnEtherscan = () => {
    setMenuShown(false)
    window.open(
      'https://etherscan.io/nft/' +
        contractAddress +
        '/' +
        tokenID 
    )
  }

  const loadListingInfo = () => {
    if (data.seaport_sell_orders && data.seaport_sell_orders.length > 0) {
      const order = data.seaport_sell_orders[0];
      if (order.cancelled == false) {
        const makerAddr = order.maker.address;
        if (makerAddr.toLowerCase() === walletAddress) {
          setListedByUser(true)
        } else {
          setListedByUser(false)
        }
        setListingPrice(Number(order.current_price));
        setListingCurrency('ETH')
      }
    }
    /*if (data.sell_orders) {
      if (data.sell_orders.length > 0) {
        if (schemaName === 'ERC721') {
          setListingPrice(data.sell_orders[0].current_price)
          setListingCurrency(data.sell_orders[0].payment_token_contract.symbol)
        } else if (schemaName === 'ERC1155') {
          if (data.sell_orders[0].maker.address.toLowerCase() === walletAddress) {
            // listing was created by Lazy user
            setListedByUser(true)
            setListingPrice(data.sell_orders[0].current_price)
            setListingCurrency(data.sell_orders[0].payment_token_contract.symbol)
          } else {
            // get orders
            setListedByUser(false)
          }
        }
      }
    }*/
  }

  const loadListActions = () => {
    const ordersExist = data.seaport_sell_orders && data.seaport_sell_orders.length > 0

    if (checkIfAuthor()) {
      if (schemaName === 'ERC721') {
        if (ordersExist) {
          setListAction('unlist')
        } else {
          setListAction('sell')
        }
      } else if (schemaName === 'ERC1155') {
        if (ordersExist) {
          if (listedByUser) {
            setListAction('unlist')
          } else {
            // get orders
          }
        } else {
          setListAction('sell')
        }
      }
    } else {
      if (schemaName === 'ERC721') {
        if (ordersExist) {
          setListAction('buy')
        }
      } else if (schemaName === 'ERC1155') {
        if (ordersExist) {
          if (listedByUser) {
            setListAction('buy')
          } else {
            // get orders
          }
        }
      }
    }
  }

  const [menu, menuButton] = loadMenu()

  let classes
  const NFTClass = isAuthor ? 'NFT-own' : 'NFT'
  if (props.isDraggable === true) {
    classes = `draggable ${NFTClass}`
  } else {
    classes = `clickable ${NFTClass}`
  }
  if ( !data.image_url || data.image_url.length == 0) return <div/>

  if (schemaName === 'erc20') return null;

  return (
    <div ref={setNodeRef} className={classes} style={style} {...attributes} {...listeners}>
      {menuButton}
      {menu}
      <div onClick={handleViewDetail}>
        {media}
        <div className="info">
          <div>
            <span className="name">{name}</span>
            <span>&nbsp;</span>
            {caption && <div style={{ position: 'relative', top: 10}} className="caption">"{caption}"</div>}
          </div>
        </div>
      </div>
      {listAction === 'buy' && (
        <center>
          <button
            className="clear"
            onClick={handleBuy}
            style={{ marginTop: '15px', marginBottom: '15px' }}
          >
            Buy for {parseFloat((listingPrice / 1000000000000000000).toFixed(3))} {listingCurrency}
          </button>
        </center>
      )}
      {listAction === 'unlist'  && (
            <div style={{ textAlign: 'center'}}>
                <button
                    className="clear"
                    onClick={handleUnlist}
                    style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                    Cancel Listing
                </button>
            </div>
        )}
    </div>
  )
}
