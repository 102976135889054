import { PublicKey } from '@solana/web3.js'
import { METADATA_PROGRAM_ID } from './program_ids'
import { BinaryReader, BorshError, deserializeUnchecked } from 'borsh'

BinaryReader.prototype.readPubkey = function () {
  const reader = this
  const array = reader.readFixedArray(32)
  return new PublicKey(array)
}

export class Metadata {
  // // PublicKey
  // updateAuthority

  // // PublicKey
  // mint
  // // String
  // name
  // // String
  // symbol
  // // String
  // uri

  // // PublicKey
  // masterEdition
  // // PublicKey
  // edition

  constructor(args) {
    this.updateAuthority = args.updateAuthority
    this.mint = args.mint
    this.name = args.name
    this.symbol = args.symbol
    this.uri = args.uri
  }
}
export const METADATA_SCHEMA = new Map([
  [
    Metadata,
    {
      kind: 'struct',
      fields: [
        ['key', 'u8'],
        ['updateAuthority', 'pubkey'],
        ['mint', 'pubkey'],
        ['name', 'string'],
        ['symbol', 'string'],
        ['uri', 'string']
      ]
    }
  ]
])
export const findMetadataAddress = async (mint) => {
  const seeds = [Buffer.from('metadata'), METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer()]
  return (await PublicKey.findProgramAddress(seeds, METADATA_PROGRAM_ID))[0]
}
export const getMasterEdition = async (mint) => {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from('edition')
      ],
      METADATA_PROGRAM_ID
    )
  )[0]
}

export function deserializeBorsh(schema, classType, buffer) {
  const reader = new BinaryReader(buffer)
  return deserializeStruct(schema, classType, reader)
}

export const decodeMetadata = async (buffer) => {
  const metadata = deserializeBorsh(METADATA_SCHEMA, Metadata, buffer)
  return metadata
}

export const fetchUriData = async (uri, mint) => {
  const res = await fetch(uri)
  if (!res.ok) {
    throw new Error(`Failed to fetch data from ${uri}`)
  }
  const data = await res.json()
  const { description, external_url, name, properties, image, animation_url, attributes } = data
  return {
    name,
    description,
    external_url,
    image,
    properties,
    mint,
    animation_url,
    attributes
  }
}

function deserializeStruct(schema, classType, reader) {
  const structSchema = schema.get(classType)
  if (!structSchema) {
    throw new BorshError(`Class ${classType.name} is missing in schema`)
  }

  if (structSchema.kind === 'struct') {
    const result = {}
    for (const [fieldName, fieldType] of structSchema.fields) {
      result[fieldName] = deserializeField(schema, fieldName, fieldType, reader)
    }
    return new classType(result)
  }

  if (structSchema.kind === 'enum') {
    const idx = reader.readU8()
    if (idx >= structSchema.values.length) {
      throw new BorshError(`Enum index: ${idx} is out of range`)
    }
    const [fieldName, fieldType] = structSchema.values[idx]
    const fieldValue = deserializeField(schema, fieldName, fieldType, reader)
    return new classType({ [fieldName]: fieldValue })
  }

  throw new BorshError(
    `Unexpected schema kind: ${structSchema.kind} for ${classType.constructor.name}`
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function deserializeField(schema, fieldName, fieldType, reader) {
  try {
    if (typeof fieldType === 'string') {
      const capitalizedFirstLetter = capitalizeFirstLetter(fieldType)
      switch (capitalizedFirstLetter) {
        case 'Array':
          return reader.readArray()
        case 'Buffer':
          return reader.readBuffer()
        case 'FixedArray':
          return reader.readFixedArray()
        case 'String':
          return reader.readString()
        case 'U8':
          return reader.readU8()
        case 'U16':
          return reader.readU16()
        case 'U32':
          return reader.readU32()
        case 'U64':
          return reader.readU64()
        case 'U128':
          return reader.readU128()
        case 'U256':
          return reader.readU256()
        case 'U512':
          return reader.readU512()
        default:
          return reader.readPubkey()
      }
    }

    if (fieldType instanceof Array) {
      if (typeof fieldType[0] === 'number') {
        return reader.readFixedArray(fieldType[0])
      }

      return reader.readArray(() => deserializeField(schema, fieldName, fieldType[0], reader))
    }

    if (fieldType.kind === 'option') {
      const option = reader.readU8()
      if (option) {
        return deserializeField(schema, fieldName, fieldType.type, reader)
      }

      return undefined
    }

    return deserializeStruct(schema, fieldType, reader)
  } catch (error) {
    if (error instanceof BorshError) {
      error.addToFieldPath(fieldName)
    }
    throw error
  }
}
