import React from 'react'
import { WaxBundleGrid } from '../WaxBundleGrid';
import { WaxNFTData } from '../WaxHelper';

type Props = {
    username: string;
    walletAddress: string;
    price: string;
    data: WaxNFTData;
    handleClose: () => void;
    handleBuy: () => void;
}

class WaxBundleModal extends React.Component<Props> {
  render() {
      if (!this.props.data.salesData) return;
    return (
        <div className="modal-background" style={{ display: 'inline-flex', left: 0 }}>
            <div className="modal" style={{ width: 420, overflow: 'hidden'}}>
                <WaxBundleGrid
                    username={this.props.username} 
                    walletAddress={this.props.walletAddress}  
                    ids={this.props.data.salesData.assets.map(x => x.asset_id)}
                />
                <span style={{ whiteSpace: 'pre-line'}} className="description">
                    {`Total Price: \n`}<b>{`${this.props.price}`}</b>
                </span>
                {(
                <button className="clear" onClick={() => this.props.handleBuy()}>
                    Buy Bundle
                </button>
                )}
                <button className="clear" onClick={() => this.props.handleClose()}>
                Close
                </button>
            </div>
        </div>
    )
  }
}

export default WaxBundleModal
