import axios from 'axios'
import { openseaGetMatic, openseaGetMaticByContract } from '../../util/api'
require('dotenv').config()

export const loadPolygonNFTs = async (walletAddress, page, cursor) => {
  let headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
  let endpointURL
  if (cursor) {
    endpointURL = cursor.replace('http://', 'https://')
  } else {
    endpointURL = openseaGetMatic( walletAddress );
  }

  return await axios.get(endpointURL, { headers }).then((result) => {
    //console.log("Polygon API Response:", result);
    var NFTs = []
    let nextCursor = result.data.next // This might need to be adjusted based on actual API response
    let results = result.data.nfts // Changed from result.data.results to result.data.nfts
   
    results.forEach((result) => {
      NFTs.push({ type: 'Polygon', data: result })
    })
    return [NFTs, nextCursor]
  })
}

const loadPolygonNFT = async (contractAddress, tokenID) => {
  const options = {
    method: 'GET',
    url: `https://api.opensea.io/v2/chain/matic/contract/${contractAddress}/nfts/${tokenID}`,
    headers: {
      accept: 'application/json', 
      'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY // using the environment variable
    }
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const loadPolygonPins = async (walletAddress, pins) => {
  // load a batch of Polygon NFTs
  let NFTs = []
  for (const pin of pins) {
    let asset = await loadPolygonNFT(pin.contractAddress, pin.tokenID)
    let nft = {
      type: 'Polygon',
      index: pin.index,
      data: asset,
      walletAddress,
      pinID: pin.id,
      caption: pin.caption
    }
    NFTs.push(nft)
  }
  //console.log("LOAD POLYGON PINS:", NFTs)
  return NFTs
}

export const loadFeaturedPolygonNFTs = async (
  walletAddresses,
  contractAddresses,
  tokenIDs,
  owners
) => {
  let NFTs = []
  let i = 0
  for (const contractAddress of contractAddresses) {
    let tokenID = tokenIDs[i]
    let walletAddress = walletAddresses[i]

    let NFT = await loadPolygonNFT(walletAddress, contractAddress, tokenID)

    if (NFT) {
      let metadata = owners[contractAddress + ':' + tokenID]
      let id = metadata['id']
      let createdAt = metadata['createdAt']
      let username = metadata['username']

      let nft = {
        type: 'Polygon',
        createdAt: createdAt,
        username: username,
        data: {
          id: id,
          walletAddress: walletAddress,
          contractAddress: contractAddress,
          tokenID: tokenID,
          name: NFT.name,
          image: NFT.image_url,
          animation: NFT.animation_url
        }
      }

      NFTs.push(nft)
    }
    i += 1
  }

  return NFTs
}
