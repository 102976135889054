import React from 'react'

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var options = null
    if (this.props.options) {
      options = this.props.options.map((action, idx) => {
        return (
          <button key={idx} onClick={this.props.handlers[idx]}>
            {action}
          </button>
        )
      })
    }
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">{this.props.title}</span>
          <span className="description">{this.props.message}</span>
          {options}
          <button className="green" onClick={this.props.handleConfirm}>
            {this.props.confirmLabel}
          </button>
          <button className="clear" onClick={this.props.handleClose}>
            {this.props.closeLabel}
          </button>
        </div>
      </div>
    )
  }
}

ConfirmationModal.defaultProps = {
  closeLabel: 'Close'
}

export default ConfirmationModal
