import axios from 'axios'
import { openseaGetByOwnerv2 } from '../../util/api'
require('dotenv').config()

const PAGE_LIMIT = 50

export const loadEthereumNFTs = async (walletAddress, cursor) => {

  return await openseaGetByOwnerv2(walletAddress, cursor)
    .then((result) => {
      const assets = result.data;
     // console.log(assets)
      return assets
    })
    .catch((err) => {
      console.log(err)
    })
}

const loadEthereumNFT = async (contractAddress, tokenID) => {
  const options = {
    method: 'GET',
    url: `https://api.opensea.io/v2/chain/ethereum/contract/${contractAddress}/nfts/${tokenID}`, 
    headers: {
      accept: 'application/json',
      'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY // using the environment variable
    }
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const loadEthereumPins = async (walletAddress, pins) => {
  let NFTs = [];
  for (const pin of pins) {
    //console.log(`Fetching NFT: ContractAddress=${pin.contractAddress}, TokenID=${pin.tokenID}`);
    let asset = await loadEthereumNFT(pin.contractAddress, pin.tokenID);
    //console.log('API Response:', asset);
    let nft = {
      type: 'Ethereum',  
      index: pin.index,
      data: asset,
      walletAddress,
      pinID: pin.id,
      caption: pin.caption
    };
    NFTs.push(nft);
  }
  return NFTs;
};

const findAssociatedPin = (pins, contractAddress, tokenID) => {
  var foundPin = null;
  pins.forEach((pin) => {
    if (pin.contractAddress === contractAddress && pin.tokenID === tokenID) {
      foundPin = pin;
    }
  });
  return foundPin;
};
