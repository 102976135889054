import React from 'react'

class BuyConfirmation extends React.Component {
  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">Buy NFT</span>
          <span className="description">
            You are about to purchase <b>{this.props.name}</b>.
          </span>
          <span className="description">
            Your Address: <b>{this.props.address}</b>
          </span>
          <span className="price">
            Total Price: {this.props.price / 1000000000000000000} {this.props.paymentToken} + Gas
            Fees
          </span>
          <button className="theme" onClick={this.props.handleContinue}>
            Continue
          </button>
          <button className="clear" onClick={this.props.handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    )
  }
}

export default BuyConfirmation
