import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import WalletItem from './WalletItem'
import firebase from '../../firebase'
import AddWallet from './AddWallet'

import { SwapWidget } from '@uniswap/widgets/dist/index.js'
import '@uniswap/widgets/dist/fonts.css'
import { MAINNET_RPC_URL } from '../../util/constants'
import { PortfolioEditModal } from './PortfolioEditModal'
import GenericModal from '../modals/GenericModal'
import { Icon } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkIcon from '@mui/icons-material/Link';
import { doOnboard } from '../../util/helpers'
import { ProfileDisplay } from './ProfileDisplay'
const { parse } = require('rss-to-json');

export default function Wallets() {
  const [wallets, setWallets] = useState([])
  const [addingWallet, setAddingWallet] = useState(false)
  // Uniswap
  const [walletConnected, setWalletConnected ] = useState(false);
  // Profile
  const [profileData, setProfileData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  // Newsletter {title, description, link, }
  const [rssData, setRssData] = useState();

  const username = localStorage.getItem('username')
  const db = firebase.firestore()

  const initOnboard = () => {
    doOnboard();
    setWalletConnected(true);
  };

  const loadRss = () => {
    parse('https://lazy-cors.herokuapp.com/https://lazynfts.substack.com/feed').then(x => {
      if (x.items && x.items.length > 0) {
        setRssData(x.items.slice(0,5));
      }
      //console.log(JSON.stringify(x, null, 3));
    });
  };

  const loadWallets = () => {
    db.collection('users')
      .doc(username)
      .collection('wallets')
      .get()
      .then((snapshot) => {
        const wallets = []
        snapshot.docs.forEach((doc) => {
          const data = doc.data()
          wallets.push({
            id: doc.id,
            type: data.type,
            address: data.address,
            displayName: data.display_name
          })
        })
        setWallets(wallets)
      })
  }

  const getProfileData = () => {
    db.collection('users')
      .doc(username)
      .get()
      .then((doc) => {
        const data = doc.data();
        const profileData = {
          bio: data.bio,
          twitter: data.twitter,
          facebook: data.facebook,
          personalUrl: data.personalUrl,
          profilePictureUrl: data.profilePictureUrl,
          profileVideoUrl: data.profileVideoUrl,
        };
        setProfileData(profileData);
      })
  }

  const addWallet = () => {
    setAddingWallet(true)
  }

  const closeAddWallet = () => {
    setAddingWallet(false)
  }

  const reset = () => {
    setAddingWallet(false)
  }

  useEffect(() => {
    loadWallets()
    getProfileData()
    loadRss()
  }, [])

  const walletComponents = wallets.map((wallet, idx) => {
    return <WalletItem key={idx} wallet={wallet} loadWallets={loadWallets} />
  })


  // load share buttons
  if (window.__sharethis__) {
    window.__sharethis__.load('inline-share-buttons', {
      id: 'my-inline-buttons',
      alignment: 'center',
      color: 'white',
      enabled: true,
      font_size: 16,
      padding: 12,
      radius: 8,
      networks: ['twitter', 'facebook', 'linkedin', 'email', 'sms', 'messenger', 'whatsapp'],
      size: 40,
      show_mobile_buttons: true,
      url: 'https://lazy.com/' + username,
      title:
        'Check out ' +
        username +
        "'s NFT collection at lazy.com/" +
        username +
        '. The lazy way to show off your NFTs!',
      subject: 'Check out ' + username + "'s NFT collection on Lazy!",
      message:
        'Check out ' +
        username +
        "'s NFT collection at lazy.com/" +
        username +
        '. The lazy way to show off your NFTs!'
    })
  }

  return (
    <div>
      <NavBar />
      <div className="container">
        <div className="heading">
          <span className="title">My Wallets</span>
          <button className="theme" style={{ marginRight: '20px' }} onClick={addWallet}>
            Add Wallet
          </button>
        </div>
        {walletComponents}
        {wallets.length === 0 && <center>No wallets found.</center>}
      </div>
      <div className="container">
        <h1
          style={{
            marginTop: '100px',
            marginLeft: '20px',
            marginBottom: '10px'
          }}
        >
          My Profile
        </h1>
        <div className="heading" style={{ justifyContent: 'center', paddingLeft: '0px', }}>
          <ProfileDisplay profileData={profileData} username={username} setShowEditModal={setShowEditModal} />
        </div>
      </div>
      <div className="container">
        <h1
          style={{
            marginTop: '100px',
            marginLeft: '20px',
            marginBottom: '10px'
          }}
        >
          Swap
        </h1>
        <div className="Uniswap" style={{ margin: '20px auto', width: 360, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {!walletConnected && (
            <button style={{ width: 200, marginBottom: 20 }} type="submit" onClick={() => initOnboard()}>
              Connect Wallet
            </button>
          )}
          <SwapWidget
            provider={walletConnected ? window.ethereum : undefined}
            jsonRpcEndpoint={MAINNET_RPC_URL}
            convenienceFee={1}
            convenienceFeeRecipient={'0xa679c6154b8d4619Af9F83f0bF9a13A680e01eCf'}
          />
        </div>
      </div>
      <div className="container">
        <h1
          style={{
            marginTop: '100px',
            marginLeft: '20px',
            marginBottom: '50px'
          }}
        >
          Recent Newsletters
        </h1>
        <div style={{ width: '100%', margin: '0 auto'}}>
          {rssData && rssData.map((x,i) => (
            <div className="selectable wallet" style={{ cursor: 'pointer', "&:hover": {
              background: "blue"
            }, }} key={i} onClick={() => window.open(x.link, '_blank')}>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: 168, minWidth: 168, height: 121, backgroundPosition: 'center', backgroundSize: 'cover',
                  borderRadius: '10%', backgroundImage: `url(${x.enclosures[0] ? x.enclosures[0].url : undefined})`}}/>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0px 0px 20px'}}>
                  <span style={{fontWeight: 'bold'}}>{x.title}</span>
                  <span style={{ fontWeight: 200, paddingTop: 6 }}>{x.description}</span>
                </div>
            </div>
          </div>
        ))}
        </div>
      </div>
      <div className="container" style={{ marginBottom: 50}}>
        <h1
          style={{
            marginTop: '100px',
            marginLeft: '20px',
            marginBottom: '10px'
          }}
        >
          Share
        </h1>
        <center>
          <a href={'/' + username}>
            <button className="clear" style={{ marginTop: '50px', marginBottom: '30px' }}>
              lazy.com/{username}
            </button>
          </a>
        </center>
        <div id="my-inline-buttons"></div>
      </div>
      {addingWallet && (
        <AddWallet
          handleCloseAddWallet={closeAddWallet}
          reloadWallets={loadWallets}
          reset={reset}
        />
      )}
      {showEditModal && <PortfolioEditModal
        profileData={profileData}
        username={username}
        onClose={() => setShowEditModal(false)}
        onSubmit={() => {
          setShowEditModal(false);
          getProfileData();
        }}
      />}
    </div>
  )
}
