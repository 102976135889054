import { useEffect, useState } from 'react'
import { OpenSeaPort, Network } from 'opensea-js'
import { OrderSide } from 'opensea-js/lib/types'
import NavBar from '../general/NavBar'
import axios from 'axios'
import EthereumShowSimilar from './EthereumShowSimilar'
import { openseaGetAssetsByContractv2 } from '../../util/api'

require('dotenv').config()

export default function EthereumRabbitHole(props) {
  const [NFT, setNFT] = useState({})
  const [listAction, setListAction] = useState('')
  const [price, setPrice] = useState()

  const { walletAddress, contractAddress, tokenID } = props.match.params;

  const loadNFT = async () => {
    // load from OpenSea
    await loadOpenSea(contractAddress, tokenID).then((assets) => {
      const contractAddress = assets.contract
        const tokenID = assets.identifier
        var name = assets.name
        if (name === null) {
          name = assets.identifier
        }
        var description = assets.description
        if (description === null) {
          description = assets.collection
        }
        var image_url = assets.image_url
        var iframe_url
        var animation_url = assets.animation_url

        // show default image if no media is found
        if (
          (image_url === null || image_url === undefined) &&
          (animation_url === null || animation_url === undefined)
        ) {
          image_url = '/empty-media.png'
        }

        // make sure image is not mp4
        if (image_url.toLowerCase().endsWith('.mp4')) {
          animation_url = image_url
        }

        // check if processing
        if (
          assets.name === null &&
          assets.description === null &&
          assets.image_url === null
        ) {
          name = 'NFT Still Processing...'
          description = 'Check back later!'
          image_url = '/empty-media.png'
        }
/*
        var owner = null
        var schemaName = null
        var currentPrice = null
        var listedByUser = null
        if (!props.legacy) {
          owner = assets.owners;
          schemaName = assets.token_standard
          currentPrice = null
          listedByUser = false
          if ( assets[ 0 ].sell_orders ) {
            if ( assets[ 0 ].sell_orders.length > 0 ) {
              currentPrice = assets[ 0 ].sell_orders[ 0 ].current_price
              //paymentToken = assets[ 0 ].sell_orders[ 0 ].payment_token_contract.symbol
              if (
                walletAddress &&
                assets[ 0 ].sell_orders[ 0 ].maker.address.toLowerCase() === walletAddress.toLowerCase()
              ) {
                listedByUser = true
              }
            }
          }

        }
*/
        // show default image if no media is found
        if (image_url === null && animation_url === null) {
          image_url = '/empty-media.png'
        }

        setNFT({
          contractAddress,
          tokenID,
          name,
          description,
          image: image_url,
          animation: animation_url,
          //owner,
          //currentPrice,
          //schemaName,
          //listedByUser
        })
      
    })
  }

  const loadOpenSea = async (contractAddress, tokenID) => {
    return await openseaGetAssetsByContractv2(contractAddress, tokenID).then((result) => {
      const assets = result.data.nft
      return assets
    })
  }
/*
  const loadActions = () => {
    if (NFT.schemaName === 'ERC721') {
      if (NFT.currentPrice) {
        setListAction('buy')
        setPrice(NFT.currentPrice)
      }
    } else if (NFT.schemaName === 'ERC1155') {
      if (NFT.currentPrice) {
        if (NFT.listedByUser) {
          setListAction('buy')
          setPrice(NFT.currentPrice)
        } else {
          // get orders
          getOrders().then((orders) => {
            if (orders.length > 0) {
              setListAction('buy')
              setPrice(orders[0].currentPrice)
            }
          })
        }
      }
    }
  }

  const getOrders = async () => {
    const walletAddress = props.match.params.walletAddress
    const contractAddress = props.match.params.contractAddress
    const tokenID = props.match.params.tokenID

    // initialize Seaport
    const provider = window.ethereum
    var network = null
    if (process.env.REACT_APP_TESTING) {
      network = Network.Rinkeby
    } else {
      network = Network.main
    }
    const seaport = new OpenSeaPort(provider, {
      networkName: network,
      apiKey: process.env.REACT_APP_OPENSEA_API_KEY
    })

    const { orders, _ } = await seaport.api.getOrders({
      asset_contract_address: contractAddress,
      token_id: tokenID,
      side: OrderSide.Sell,
      maker: walletAddress
    })
    return orders
  }
*/
  useEffect(() => {
    loadNFT().then(() => {
    //  loadActions()
    })
  }, [])

  var media = null
  var name = null
  var description = null
  var externalURL = null
  var viewButton = null
  if (NFT) {
    name = NFT.name
    description = NFT.description
    if (process.env.REACT_APP_TESTING) {
      externalURL =
        'https://testnets.opensea.io/assets/' +
        NFT.contractAddress +
        '/' +
        NFT.tokenID +
        '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
    } else {
      externalURL =
        'https://opensea.io/assets/ethereum/' +
        NFT.contractAddress +
        '/' +
        NFT.tokenID +
        '?ref=0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf'
    }
    viewButton = (
      <center>
        <button
          onClick={() => this.viewOnOpenSea(externalURL)}
          className="clear"
          style={{ marginBottom: '50px', marginTop: '50px' }}
        >
          View on OpenSea
        </button>
      </center>
    )
    if (NFT.animation) {
      media = (
        <video
          className="detail-video-small"
          poster={NFT.image}
          playsInline
          controls
          style={{ marginBottom: '15px' }}
        >
          <source src={NFT.animation} />
        </video>
      )
    } else {
      media = (
        <img
          className="detail-image-small"
          src={NFT.image}
          alt=""
          style={{ marginBottom: '15px' }}
        />
      )
    }
  }

  var address = ''
  if (props.legacy) {
    address = props.match.params.contractAddress + '/' + props.match.params.tokenID
  } else {
    address =
      props.match.params.walletAddress +
      '/' +
      props.match.params.contractAddress +
      '/' +
      props.match.params.tokenID
  }

  // load the buttons
  if (window.__sharethis__) {
    window.__sharethis__.load('inline-share-buttons', {
      id: 'my-inline-buttons',
      alignment: 'center',
      color: 'white',
      enabled: true,
      font_size: 16,
      padding: 12,
      radius: 8,
      networks: ['twitter', 'facebook', 'linkedin', 'email', 'sms', 'messenger', 'whatsapp'],
      size: 40,
      show_mobile_buttons: true,
      url:
        'https://lazy.com/explore/ethereum/' +
        props.match.params.walletAddress +
        '/' +
        props.match.params.contractAddress +
        '/' +
        props.match.params.tokenID,
      title:
        'Check out this NFT at lazy.com/explore/ethereum/' +
        +props.match.params.walletAddress +
        '/' +
        props.match.params.contractAddress +
        '/' +
        props.match.params.tokenID +
        '. The lazy way to show off your NFTs!',
      subject: 'Check out this NFT on Lazy!',
      message:
        'Check out this NFT at lazy.com/explore/ethereum/' +
        +props.match.params.walletAddress +
        '/' +
        props.match.params.contractAddress +
        '/' +
        props.match.params.tokenID +
        '. The lazy way to show off your NFTs!'
    })
  }
  var renderedListAction = null
  if (listAction === 'buy') {
    renderedListAction = (
      <center>
        <button className="clear" style={{ marginTop: '80px' }} onClick={this.handleBuy}>
          Buy for {parseFloat((this.state.price / 1000000000000000000).toFixed(2))} ETH
        </button>
      </center>
    )
  } else if (listAction === 'sell') {
    renderedListAction = (
      <center>
        <button className="clear" style={{ marginTop: '80px' }} onClick={this.handleSell}>
          Sell
        </button>
      </center>
    )
  } else if (listAction === 'unlist') {
    renderedListAction = (
      <center>
        <button className="clear" style={{ marginTop: '80px' }} onClick={this.handleUnlist}>
          Unlist
        </button>
      </center>
    )
  }

  const viewDetail = () => {
    window.location.href = '/ethereum/' + walletAddress + '/' + contractAddress + '/' + tokenID
  }

  return (
    <div>
      <NavBar />
      <div className="nft-detail-media-container-small">
        <div>
          {media}
          <center>
            <button className="clear gray-bg" onClick={viewDetail}>
              More Info
            </button>
          </center>
        </div>
      </div>
      {NFT.image && <EthereumShowSimilar
        walletAddress={walletAddress}
        contractAddress={contractAddress}
        tokenID={tokenID}
        url={NFT.image}
        view="similar"
        chain="ethereum"
      />}
    </div>
  )
}
