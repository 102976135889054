import { useEffect, useState } from 'react'
import NavBar from '../general/NavBar'
import EthereumShowSimilar from '../ethereum/EthereumShowSimilar'
import { useLocation } from 'react-router-dom'

export default function RabbitHoleGeneric(props) {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const contractAddress = query.get('contractAddress')
  const tokenID = query.get('tokenID')
  const url = query.get('url') ? atob(query.get('url')) : '';
  const chain = query.get('chain');
  const animationUrl = query.get('animationUrl') ? atob(query.get('animationUrl')) : '';
  
  let content;
  if (animationUrl && animationUrl.length > 0) {
    content = (
        <video className="video" poster={url} playsInline muted autoPlay loop>
          <source src={animationUrl} />
        </video>
    );
  } else {
    content = (
        <img className="detail-image-small" alt="" style={{marginBottom: 15 }}
            src={url}
        />
    );
  }

  return (
    <div>
      <NavBar />
      <div className="nft-detail-media-container-small">
        <div>
            {content }
        </div>
      </div>
      {url && <EthereumShowSimilar
        contractAddress={contractAddress}
        tokenID={tokenID}
        url={animationUrl && animationUrl.length > 0 && animationUrl.toLowerCase() != 'undefined'
        ? animationUrl 
            : url}
        view="similar"
        chain={chain}
      />}
    </div>
  )
}
