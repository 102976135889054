import React from 'react'
import { inputStyle } from '../general/generalStyles'

class TopShotsInputModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { username: '' }
    this.handleUpdateUsername = this.handleUpdateUsername.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
  }

  handleUpdateUsername(e) {
    e.preventDefault()
    this.setState({ username: e.target.value })
  }

  handleContinue() {
    this.props.handleContinue(this.state.username)
  }

  render() {
    return (
      <div className="modal-background" style={{ display: 'inline-flex' }}>
        <div className="modal">
          <span className="title">Enter Username</span>
          <span className="input-label">NBA Top Shots Username:</span>
          <input style={inputStyle} type="text" placeholder="Enter username..." onChange={this.handleUpdateUsername} />
          <button className="green" onClick={this.handleContinue}>
            Add Wallet
          </button>
          <button className="clear" onClick={this.props.handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    )
  }
}

export default TopShotsInputModal
