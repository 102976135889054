import { useEffect, useState } from 'react'
import NavBar from '../general/NavBar'
import EthereumShowSimilar from '../ethereum/EthereumShowSimilar'
import { useLocation } from 'react-router-dom'

export default function BizarroworldGeneric(props) {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const contractAddress = query.get('contractAddress')
  const tokenID = query.get('tokenID')
  const url = query.get('url') ? atob(query.get('url')) : '';
  const chain = query.get('chain');

  return (
    <div>
      <NavBar />
      <div className="nft-detail-media-container-small">
        <div>
            <img className="detail-image-small" alt="" style={{marginBottom: 15 }}
                src={url}
            />
        </div>
      </div>
      {url && <EthereumShowSimilar
        contractAddress={contractAddress}
        tokenID={tokenID}
        url={url}
        view="opposite"
        chain={chain}
      />}
    </div>
  )
}
