import { useState } from 'react'
import TopShotsInputModal from '../modals/TopShotsInputModal'
import axios from 'axios'
import firebase from '../../firebase'
import Error from '../modals/Error'
import { REACT_CORS } from '../../util/constants'

export default function AddNBATopShotWallet(props) {
  const [showPromptModal, setShowPromptModal] = useState(true)
  const [showError, setShowError] = useState(false)

  const promptModalContinue = (username) => {
    // get flow address
    axios
      .post(REACT_CORS + 'https://public-api.nbatopshot.com/graphql', {
        query: `query ProfilePage_getUserProfileByUsername($input: getUserProfileByUsernameInput!) {
            getUserProfileByUsername(input: $input) {
                publicInfo {
                    flowAddress
                }
            }
        }`,
        variables: {
          input: {
            username: username.replace('@', '')
          }
        }
      })
      .then((res) => {
        if (res.data.data) {
          let flowAddress = res.data.data.getUserProfileByUsername.publicInfo.flowAddress
          addWallet(flowAddress, username.toLowerCase())
        } else {
          setShowError(true)
          closeModal()
        }
      })
  }

  const promptModalCancel = () => {
    closeModal()
  }

  const closeModal = () => {
    setShowPromptModal(false)
    props.reset()
  }

  const addWallet = (walletAddress, displayName) => {
    // check for existing wallet
    try {
      let db = firebase.firestore()
      let username = localStorage.getItem('username')

      db.collection('users')
        .doc(username)
        .collection('wallets')
        .where('address', '==', walletAddress)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            alert('You have already added this wallet address to your Lazy account.')
          } else {
            // add to Firestore
            try {
              let db = firebase.firestore()
              let username = localStorage.getItem('username')

              db.collection('users')
                .doc(username)
                .collection('wallets')
                .doc()
                .set({
                  type: 'NBA Top Shot',
                  address: walletAddress,
                  display_name: displayName
                })
                .then(() => {
                  closeModal()
                  props.reloadWallets()
                })
                .catch((err) => {
                  console.log(err)
                })
            } catch (error) {
              console.log(error)
            }
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  const closeError = () => {
    setShowError(false)
  }

  return (
    <div>
      {showPromptModal && (
        <TopShotsInputModal handleContinue={promptModalContinue} handleCancel={promptModalCancel} />
      )}
      {showError && (
        <Error
          title="Error"
          message="The username you entered does not exist on NBA Top Shot."
          handleClose={closeError}
        />
      )}
    </div>
  )
}
