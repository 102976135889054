import React from 'react';
import { loadWaxNFTs, WaxNFTData } from './WaxHelper';
import WaxNFTNew from './WaxNFTNew';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { EffectCards, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/effect-cards/effect-cards.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

type Props = {
    walletAddress: string;
    username: string;
    ids: string[];
};

type State = {
    nfts: WaxNFTData[];
    isWaxLoading: boolean;
}

export class WaxBundleGrid extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            nfts: [],
            isWaxLoading: true,
        }
    }
    
    componentDidMount() {
        this.loadWax();
    }

    loadWax() {
        try {
            loadWaxNFTs({ ids: this.props.ids.join(',')}, true).then(x => {
                this.setState({
                    nfts: x,
                    isWaxLoading: false
                });
            });
        } catch (err) {
            this.setState({ isWaxLoading: false })
        }
    }

    render() {
        const nfts = this.state.nfts.map((nft, idx) => {
            if ( !nft.data.img && !nft.data.video) return;
            return (
                <SwiperSlide key={idx}>
                    <WaxNFTNew
                    key={idx}
                    data={nft}
                    walletAddress={nft.owner}
                    username={this.props.username}
                    isTiny={true}
                />
                </SwiperSlide>
            )
        });
        const swipe = (
        <Swiper
            pagination={{
                type: "fraction"
            }}
          navigation={true}
            effect={"cards"}
            grabCursor={true}
            modules={[Pagination, Navigation, EffectCards]}
            className="mySwiper"
            style={{ width: 430}}
        >
           {nfts}
        </Swiper>
        )
        return (
            <div className="container">
              <div className="heading" style={{ marginBottom: 0, marginTop: 10, paddingLeft: 20}}>
                <span className="title">Wax Bundle for Sale</span>
              </div>
              {this.state.isWaxLoading == true && <>Loading Wax NFTs...</>}
                <div className="NFT-container" style={{ width: 300, height: 500, marginLeft: 30 }}>
                    {swipe}
                </div>
            </div>
           
        )
    }
}