import React, { useState, useEffect } from "react";

const CountdownTimer = (date) => {  
    const getCountdown = () => {
        //const year = date.getFullYear();
        const timeRemaining = date.date - new Date();
        let countdown = {};
        if (timeRemaining > 0) {
          countdown = {
            Days: Math.floor(timeRemaining / (1000 * 60 * 60 * 24)),
            Hours: Math.floor((timeRemaining / (1000 * 60 * 60)) % 24),
            Minutes: Math.floor((timeRemaining / 1000 / 60) % 60),
            Seconds: Math.floor((timeRemaining / 1000) % 60),
          };
        }
        return countdown;
      };
      const [countdown, setCountdown] = useState(getCountdown());
      
  useEffect(() => {
    setTimeout(() => {
      setCountdown(getCountdown());
    }, 1000);
  });
  const data = [];
  Object.entries(countdown).forEach(([unit, value]) => {
    data.push(
      <React.Fragment>
        <strong>{value}</strong> {unit} {' '}
      </React.Fragment>
    );
  });

  return (
    <>
      <ul>{data}</ul>
    </>
  );
};

export default CountdownTimer;