import React, { useState } from 'react';
import { inputStyle } from './generalStyles';
import firebase from '../../firebase'

type ProfileData = {
  bio?: string;
  twitter?: string;
  facebook?: string;
  personalUrl?: string;
}

type Props = {
  username: string;
  onClose: () => void;
  onSubmit: () => void;
  imageUrl?: string;
  links?: string[];
  profileData: ProfileData;
};

export const PortfolioEditModal = (props: Props) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(props.imageUrl);
  const [bio, setBio] = useState<string | undefined>(props.profileData.bio);
  const [links, setLinks] = useState<string[]>(props.links || []);
  const [twitter, setTwitter] = useState<string | undefined>(props.profileData.twitter);
  const [facebook, setFacebook] = useState<string | undefined>(props.profileData.facebook);
  const [personalUrl, setPersonalUrl] = useState<string | undefined>(props.profileData.personalUrl);
  const db = firebase.firestore()

  const handleSubmit = () => {
    const postObject = {
      bio,
      twitter,
      facebook,
      personalUrl: getUrl(personalUrl),
    }
    db.collection('users').doc(props.username).update(postObject).then(x => {
      props.onSubmit();
    })
  };
  const handleClose = () => {
    props.onClose();
  };

  const getUrl = (url?: string) => {
    if (url && !/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    }
    return url;
  }

  return (
    <div className="modal-background" style={{ display: 'inline-flex' }}>
      <div className="modal" style={{ width: 600 }}>
        <span className="title">Edit Profile</span>
        <div style={{ marginBottom: 40 }} className="description editProfileContainer">
          <div style={{ display: 'flex'}}>
            <h3 style={{ marginLeft: 0, flexGrow: 1 }}>Bio</h3>
            <span style={{ fontSize: 14, color: 'darkslategrey', alignSelf: 'center' }}>Character Count {bio ? bio.length : 0}/160</span>
          </div>
          <textarea defaultValue={props.profileData.bio} style={{ ...inputStyle, margin: 10, height: 90, width: 530, paddingTop: 15 }} maxLength={160}  placeholder="Enter bio" onChange={(e) => setBio(e.target.value.substring(0, 160))} />
          <h3 style={{ marginLeft: 0 }}>Links</h3>
          <h4 style={{ marginLeft: 30, marginTop: 10 }}>Twitter Name: </h4>
          <input defaultValue={props.profileData.twitter} style={{ ...inputStyle, margin: 10, width: 530 }} placeholder="Enter Twitter Username" onChange={(e) => setTwitter(e.target.value)} />
          <h4 style={{ marginLeft: 30, marginTop: 10 }}>Facebook Name: </h4>
          <input defaultValue={props.profileData.facebook} style={{ ...inputStyle, margin: 10, width: 530 }} placeholder="Enter Facebook Username" onChange={(e) => setFacebook(e.target.value)} />
          <h4 style={{ marginLeft: 30, marginTop: 10 }}>Personal URL: </h4>
          <input maxLength={50} defaultValue={props.profileData.personalUrl} style={{ ...inputStyle, margin: 10, width: 530 }} placeholder="Website" onChange={(e) => setPersonalUrl(e.target.value)} />
        </div>
        <p style={{ fontSize: 14, margin: 10}}>Note: To change your profile picture, click on the "..." of the NFT you'd like as your picture, and select "Make Profile Picture"</p>
        <button className="clear" onClick={handleSubmit}>
          Submit
        </button>
        <button className="clear" onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );
};