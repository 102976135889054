import { useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import PromptModal from '../modals/PromptModal';
import AddEthereumWallet from '../ethereum/AddEthereumWallet';
import AddNBATopShotWallet from '../topshot/AddNBATopShotWallet';
import AddPolygonWallet from '../polygon/AddPolygonWallet';
import AddWaxWallet from '../wax/AddWaxWallet';
import AddSolanaWallet from '../solana/AddSolanaWallet';
import AddTezosWallet from '../tezos/AddTezosWallet';
import AddAvalancheWallet from '../avalanche/AddAvalancheWallet';

const walletTypes = {
  Ethereum: AddEthereumWallet,
  'NBA Top Shot': AddNBATopShotWallet,
  Polygon: AddPolygonWallet,
  Solana: AddSolanaWallet,
  Wax: AddWaxWallet,
  Tezos: AddTezosWallet,
  Avalanche: AddAvalancheWallet,
};

export default function AddWallet(props) {
  const [stage, setStage] = useState('choose_wallet_type');
  const { disconnect } = useWallet();

  const reset = () => {
    setStage('choose_wallet_type');
    props.reset();
  };

  useEffect(() => {
    disconnect();
  }, []);

  const handleWalletSelection = (walletType) => {
    setStage(walletType);
  };

  if (stage === 'choose_wallet_type') {
    return (
      <PromptModal
        title="Choose Wallet Type"
        options={Object.keys(walletTypes)}
        handlers={Object.keys(walletTypes).map((walletType) => () => handleWalletSelection(walletType))}
        handleClose={props.handleCloseAddWallet}
        closeLabel="Cancel"
      />
    );
  }

  const WalletComponent = walletTypes[stage];
  return <WalletComponent reloadWallets={props.reloadWallets} reset={reset} />;
}
