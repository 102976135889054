import { useEffect, useState } from 'react';
import ConfirmationModal from '../modals/ConfirmationModal';
import firebase from '../../firebase';
import { AVAX_MAINNET_RPC_URL, walletPlugins } from '../../util/constants';
import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';

export default function AddAvalancheWallet(props) {
  const [walletProvider, setWalletProvider] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [showModal, setShowModal] = useState(false);
  const [onboard, setOnboard] = useState();

  useEffect(() => {
    const initializeOnboard = async () => {
      const onboardInstance = Onboard({
        appMetadata: {
          name: 'Lazy',
          icon: 'lazy.png',
          description: 'Lazy wallet select',
        },
        chains: [
          {
            id: '0xa86a',
            token: 'AVAX',
            label: 'Avalanche Mainnet',
            rpcUrl: AVAX_MAINNET_RPC_URL,
          },
        ],
        wallets: [injectedModule(), ...walletPlugins],
      });
      setOnboard(onboardInstance);
      await connectWallet(onboardInstance);
    };

    initializeOnboard();
  }, []);

  const connectWallet = async (onboardInstance) => {
    try {
      const wallets = await onboardInstance.connectWallet();
      const address = wallets && wallets[0] && wallets[0].accounts && wallets[0].accounts[0] && wallets[0].accounts[0].address;
      if (address) {
        checkExistingWallet(address);
      }
    } catch (err) {
      console.log(err);
      closeModal();
    }
  };

  const checkExistingWallet = (walletAdd) => {
    const db = firebase.firestore();
    const username = localStorage.getItem('username');

    db.collection('users')
      .doc(username)
      .collection('wallets')
      .where('address', '==', walletAdd)
      .where('type', '==', 'Avalanche')
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          // Replace with a more advanced UI approach
          alert('You have already added this wallet address to your Lazy account.');
        } else {
          addWalletToFirestore(walletAdd);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addWalletToFirestore = (walletAdd) => {
    const db = firebase.firestore();
    const username = localStorage.getItem('username');

    db.collection('users')
      .doc(username)
      .collection('wallets')
      .doc()
      .set({
        type: 'Avalanche',
        address: walletAdd,
      })
      .then(() => {
        closeModal();
        // TODO: Replace location.reload() with a state update or other method
        location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeModal = () => {
    setShowModal(false);
    props.reset();
  };

  const promptMessage = (
    <p>
      <b>Selected Wallet Address: </b> {walletAddress}
    </p>
  );

  return (
    <div>
      {showModal && (
        <ConfirmationModal
          title={'Connect ' + walletProvider + ' Wallet'}
          message={promptMessage}
          handleConfirm={checkExistingWallet}
          confirmLabel="Add Wallet"
          handleClose={closeModal}
          closeLabel="Cancel"
        />
      )}
    </div>
);
}