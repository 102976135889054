import NavBar from './NavBar'
import './Home.css'
import { simpleInputStyle } from './generalStyles';
import { useState, useEffect, useRef } from 'react';

const TESTIMONIES = [
  { text: "I love being able to curate my collection of NFTs and have a shareable link to my gallery.", name: 'therealcarlo', image: '/testimony-image3.png' },
  { text: "Lazy is an innovative site that inspires me as an artist.", name: 'ronnie2021', image: '/testimony-image5.png' },
  { text: "I love the ability to PIN my favorite NFTs on my profile and the weekly newsletter.", name: 'web_horizons', image: '/testimony-image4.png' }
];

const LOOP_INTERVAL = 15000;
const FADE_TRANSITION_DURATION = 1000;

const IMAGES = [
  { src: '/image-landing-2.webp', srcSmall: '/image-landing-2-small.webp', sizes: '(max-width: 1024px) 640px' },
  { src: '/image-landing-3.webp', srcSmall: '/image-landing-3-small.webp', sizes: '(max-width: 1024px) 400px, 900px'},
  { src: '/image-landing-4.webp', srcSmall: '/image-landing-4-small.webp', sizes: '(max-width: 1024px) 400px, 900px' , onClick: () => document.location.href = 'https://lazy.com/rabbithole/ethereum/0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270/28000329' },
  { src: '/image-landing-5.webp', srcSmall: '/image-landing-5-small.webp', sizes: '(max-width: 1024px) 400px, 900px' },
  { src: '/image-landing-6.webp', srcSmall: '/image-landing-6-small.webp', sizes: '(max-width: 1024px) 400px, 900px' }
];


export default function Home(props) {

  const [fadeProp, setFadeProp] = useState({ fade: 'fade-in' });
  const [wordOrder, setWordOrder] = useState(0);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const inter = setInterval(() => {
      if (fadeProp.fade === 'fade-in') {
        setFadeProp({ fade: 'fade-out' });

        setTimeout(() => {
          setWordOrder((prevWordOrder) => (prevWordOrder + 1) % TESTIMONIES.length);
          setTimeout(() => setFadeProp({ fade: 'fade-in' }), 250)
        }, FADE_TRANSITION_DURATION);
      }
    }, [LOOP_INTERVAL]);

    return () => clearInterval(inter)
  }, [fadeProp])

  const scrollReference = useRef(null);

  useEffect(() => {
    if (scrollReference.current) {
      scrollReference.current.focus();
    }
  }, []);

  return (
    <div className='top-container' >
      <NavBar showGetStarted={true} />
      <div className="home-container">

        <div className="left-hand-side">
          <div className="landing-box-container">
            <h1 className='header-text'>The Lazy way to <br /> show off your NFTs</h1>
            <p className='paragraph-text'>We’ve built the easiest multi-chain platform to showcase your NFT empire. Buy, sell and show off your NFTs while learning from the world’s best collectors without breaking a sweat.</p>
            <div className="email-started-container">
              <input onChange={e => setEmail(e.target.value)} value={email} name="email" type="email" placeholder="Email" style={{ flexGrow: 1, ...simpleInputStyle }} />
              <button onClick={() => (document.location.href = `/signup?email=${email}`)} type="submit" style={{ paddingInline: 40 }}>
                Get started
              </button>
            </div>
          </div>
          <div className='privacy-container-desktop'>
            <p className='privacy-text'>By signing up you agree to our <b onClick={() => (document.location.href = '/privacypolicy')}>privacy policy</b> and <b onClick={() => (document.location.href = '/terms')}>terms of service</b></p>
          </div>
        </div>

        <div className="right-hand-side">
          <div tabIndex={0} ref={scrollReference} className="scroll-container">
            <div className='all-images-container'>
              {firstImage()}
              {IMAGES.map((image, index) => (
                <div key={index} onClick={image.onClick} style={{ cursor: image.onClick ? 'pointer' : '' }} className="image-container">
                  <picture>
                    <source className="images" media="(max-width: 1024px)" srcset={image.srcSmall} />
                    <source className='images' media="(min-width: 1024px)" srcset={image.src} />
                    <img className="images" src={image.src} alt="" />
                  </picture>
                </div>
              ))}
            </div>
            <div className='privacy-container-mobile'>
              <p className='privacy-text'>By signing up you agree to our <br /> <b onClick={() => (document.location.href = '/privacypolicy')}>privacy policy</b> and <b onClick={() => (document.location.href = '/terms')}>terms of service</b></p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )

  function firstImage() {
    return (
      <div className='image-container'>
        <img src="/image-landing-1.webp" alt="Join the 52,000+ collectors who use Lazy to display their Ethereum NFTs, WAX NFTs, Tezos NFTs, Avalanche NFTs, Polygon NFTs, NBA Top Shot Moments and Solana NFTs." className='images' />
        <div className='testimonial-container'>

          <div style={{ transition: `opacity ${FADE_TRANSITION_DURATION}ms ease`, opacity: fadeProp.fade === 'fade-in' ? 1 : 0 }} className={`fade-container`}>
            <div className='avatar-container' >
              <img src={TESTIMONIES[wordOrder].image} alt={`Testimonial avatar of ${TESTIMONIES[wordOrder].name}`} style={{ objectFit: 'contain' }} className='avatar-image' />
            </div>
            <div className="testimony-text">
              <p className="testimonial-text" >{TESTIMONIES[wordOrder].text} - <b onClick={() => window.location.href = `/${TESTIMONIES[wordOrder].name}`} style={{ cursor: 'pointer' }}>{TESTIMONIES[wordOrder].name}</b></p>
            </div>
            <button className="clear profile-button" onClick={() => window.location.href = `/${TESTIMONIES[wordOrder].name}`}>
              Profile
            </button>
          </div>

        </div>
      </div>
    )
  }
}
