import { compact } from 'lodash'
import { useEffect, useState } from 'react'
import { loadWaxNFTs } from '../wax/WaxHelper'
import WaxNFTNew from '../wax/WaxNFTNew'

export const WaxAuctionNFTs = (props) => {
  const [NFTs, setNFTs] = useState([])
  const [isAuthor, setIsAuthor] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const username = localStorage.getItem('username')

  useEffect(() => {
    setIsAuthor(checkIfAuthor())
  }, [])

  useEffect(() => {
    if (props.auctions && props.auctions.length > 0) {
        loadWaxNFTs({ ids: props.auctions.map(x => x.assets[0].asset_id).join(',')}).then(x => {
            setNFTs(x);
        })
    }
  }, props.auctions)

  let functions = {
    ...props.functions,
  }

  const nftComponents = NFTs.map((nft, idx) => {
    if (!nft || !nft.data) return undefined
    if (props.auctions[idx] && props.auctions[idx].state === 1) return undefined
    const wallet = props.wallets.filter(x => x.type == 'Wax');
    let address;
    if (wallet && wallet[0]) {
        address = wallet[0].address;
    }
    return (
        <WaxNFTNew
            key={nft.pinID}
            data={
                {
                    ...nft,
                    auctionsData: props.auctions[idx],
                }}
            username={props.username}
            pinned={false}
            walletAddress={address}
            functions={functions}
            isAuthor={false}
        />
    )
  })

  const checkIfAuthor = () => {
    let username = localStorage.getItem('username')
    if (username) {
      return username.toLowerCase() === props.username.toLowerCase()
    }
    return false
  }

  return nftComponents && compact(nftComponents).length > 0 && checkIfAuthor() && (
    <div>
      <h1 style={{ marginLeft: '20px', marginBottom: '10px' }}>Claim NFT or WAX Tokens From Auction (Only Visible to You)</h1>
      {NFTs.length > 0 ? (
        <div className="NFT-container">{nftComponents}</div>
      ) : hasLoaded ? (
        ''// show empty
      ) : (
        <div style={{ textAlign: 'center' }}>
          <img src="spin.svg" alt="Loading spinner" style={{ width: '150px' }} />
        </div>
      )}
    </div>
  )
}
