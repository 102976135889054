import React, { useCallback, useContext, useState } from 'react'
import { withRouter, Redirect } from 'react-router'
import firebase from '../../firebase'
import { AuthContext } from '../../Auth.js'
import NavBar from './NavBar'
import { LazyAlert } from './Alert';
import { inputStyle } from './generalStyles'

export const linkStyle = {
  borderBottom: 'solid 1px lightgrey', color: 'rgb(0, 0, 0, .8)'
};

const Login = ( { history } ) => {
  const { currentUser } = useContext( AuthContext )
  const [ modalData, setModal ] = useState( {
    description: '',
    open: false
  } );

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault()
      const { email, password } = event.target.elements
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(email.value, password.value)
          .then(() => {
            // load username
            const db = firebase.firestore()
            db.collection('usernames')
              .doc(firebase.auth().currentUser.uid)
              .get()
              .then((doc) => {
                localStorage.setItem('username', doc.data().username)
                // log event
                firebase.analytics().logEvent('sign_in', {
                  email: email.value,
                  username: doc.data().username
                })
                history.push('/wallets')
              })
          })
      } catch (error) {
        setModal( {
          description: error.message,
          open: true,
        })
      }
    },
    [history]
  )
  if (currentUser && localStorage.hasOwnProperty('username')) {
    return <Redirect to="/wallets" />
  }

  return (
    <div>
      <NavBar />
      <LazyAlert open={ modalData.open }
          title={ 'Error' }
          description={ modalData.description }
          onClose={() => setModal({ description: '', open: false})}
        />
      <div className="container">
        <div className="heading">
          <span className="title">Log In</span>
        </div>
        <form onSubmit={handleLogin} style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <input name="email" type="email" placeholder="Email" style={{ width: '300px', ...inputStyle }} />
            <input
              name="password"
              type="password"
              placeholder="Password"
              style={{ width: '300px', ...inputStyle }}
              autoComplete="off"
            />
            <center>
              <button type="submit" style={{ marginTop: '20px' }}>
                Log in
              </button>
            </center>
            <center style={ { marginTop: '20px', }}>
              <span>Don't have an account?&nbsp;&nbsp;</span>
              <a href="/signup" className={ 'text-hover' } style={linkStyle}>
                 Sign up.
              </a>
            </center>
            <center style={ { marginTop: '20px'}}>
              <a href="/reset" className={'text-hover'} style={linkStyle}>
                Forgot your password?
              </a>
            </center>
          </div>
        </form>
      </div>
    </div>
  )
}

export default withRouter(Login)
