import { isArray } from 'lodash';
import { toItemId} from '@rarible/types/build/item-id'

import { Item } from '@rarible/api-client/build/models/Item'

import { Ownership } from '@rarible/api-client/build/models/Ownership'
import { toOwnershipId} from '@rarible/types/build/ownership-id'
import { REACT_CORS } from '../../util/constants';
import axios from 'axios';

export type TezosItem = Item & { ownership?: Ownership };

export type TezosOptions = {
  owner?: string;
  ids: string[];
  cursor?: string;
}

const headers = { 'X-API-KEY': process.env.REACT_APP_RARIBLE_API_KEY, 'contentType': 'application/json', 'accept': 'application/json' };

const getItemsByOwner = async (owner: string, continuation?: string) => {
  const url = `${REACT_CORS}https://api.rarible.org/v0.1/items/byOwner?owner=TEZOS:${owner}&continuation=${continuation}`;
  const resp = await axios.get(url, {headers}).catch(err => console.log('errfuck',err))
  if (resp?.data?.items) {
    return resp.data
  }
  return null;
}

const getOwnershipsByIds = async (ids: any[]) => {
  const url = `${REACT_CORS}https://api.rarible.org/v0.1/ownerships/byIds`
  const body = {ids}
  const resp = await axios.post(url, body, {headers});
  if (resp?.data?.ownerships) {
    return resp.data?.ownerships
  }
  return null;
};



const getItemsByIds = async (ids: any[]) => { 
  const idsFormatted = ids.map(x => toItemId(x));
  const body = {ids: idsFormatted}
  const url = `${REACT_CORS}https://api.rarible.org/v0.1/items/byIds`;
  const resp = await axios.post(url, body, {headers});
  if (resp?.data?.items) {
    return resp.data
  }
  return null;
}

export const loadTezosNFTs = async (options: TezosOptions): Promise<[Item[] | undefined, string | undefined]>  => {



  if (options.ids) {
    const resp = await getItemsByIds(options.ids);
    let ownerships: any;
    if (options.owner && !isArray(options.owner)) {
      ownerships = await getOwnershipsByIds(options.ids.map(x => toOwnershipId(`${x}:${options.owner!.replace('TEZOS:', '')}`)));
    } else if (options.owner && isArray(options.owner) && options.owner.length > 0) {
        ownerships = await getOwnershipsByIds(options.ids.map((x, i) => toOwnershipId(`${x}:${options.owner![i].replace('TEZOS:', '')}`)));
    }
    if (resp && isArray(resp.items)) {
        if (ownerships) {
            return  [resp.items.map(x => ({ ...x, ownership: ownerships.find((y: any) => y.itemId == x.id ) })), resp.continuation];
        }
        return [resp.items, resp.continuation]
    }
  } else if (options.owner) {
    let resp;
    if (options.cursor) {
      resp = await getItemsByOwner(options.owner, options.cursor)
    } else {
      resp = await getItemsByOwner(options.owner);
    }
    const ownerships = await getOwnershipsByIds(resp.items.map(x => toOwnershipId(`${x.id}:${options.owner}`)));
    if (resp && isArray(resp.items)) {
        return [resp.items.map(x => ({ ...x, ownership: ownerships.find(y => y.itemId == x.id ) })), resp.continuation]
    }
  }
  return [undefined,undefined];
}

export const loadTezosNFT = async (assetId: string) => {
    const [nfts,] = await loadTezosNFTs({ ids: [assetId] })
    if (nfts && nfts.length === 1) {
      return nfts[0];
    }
    return [];
}

export const loadTezosPins = async (walletAddress: string, pins: any) => {
  // load a batch of Tezos NFTs
  const NFTs = []
  const [tezosNfts,] = await loadTezosNFTs({ ids: pins.map((x: any) => `${x.tokenID}`), owner: walletAddress });

  if (tezosNfts) {
    for (const pin of pins) {
      const asset = tezosNfts.find((item) => `${item.collection}:${item.tokenId}` === pin.tokenID)
      const nft = {
        type: 'Tezos',
        index: pin.index,
        data: asset,
        walletAddress,
        pinID: pin.id,
        caption: pin.caption
      }
      NFTs.push(nft)
    }

    return NFTs
  } else {
    return [];
  }
}
