import { createRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import firebase from '../../firebase'
import NavBar from './NavBar'
import { loadPolygonPins } from '../polygon/PolygonHelper'
import { loadTopShotPins } from '../topshot/TopShotHelper'
import { loadSolanaPins } from '../solana/SolanaHelper'
import { loadEthereumPins } from '../ethereum/EthereumHelper'
import TopShotNFTNew from '../topshot/TopShotNFTNew'
import PolygonNFT from '../polygon/PolygonNFT'
import EthereumNFTNew from '../ethereum/EthereumNFTNew'
import SolanaNFT from '../solana/SolanaNFT'
import WaxNFTNew from '../wax/WaxNFTNew'
import { loadWaxPins } from '../wax/WaxHelper'
import { loadTezosPins } from '../tezos/TezosHelper'
import TezosNFTNew from '../tezos/TezosNFTNew'
import { loadAvalanchePins } from '../avalanche/AvalancheHelper'
import AvalancheNFT from '../avalanche/AvalancheNFT'

export default function HiddenNFTs(props) {
  const db = firebase.firestore()
  const history = useHistory()
  const username = props.match.params.username.toLowerCase()
  const [hiddenItems, setHiddenItems] = useState([])
  const [isLoadingHiddenItem, setIsLoadingHiddenItem] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [userExists, setUserExists] = useState()
  const [NFTs, setNFTs] = useState([])
  const checkIfAuthor = () => {
    const savedUsername = localStorage.getItem('username')
    if (savedUsername) {
      return savedUsername.toLowerCase() === username.toLowerCase()
    }
    return false
  }
  const loadHiddenItems = () => {
    setIsLoadingHiddenItem(true)
    db.collection('users')
      .doc(username)
      .collection('hidden')
      .orderBy('created_at', 'asc')
      .get()
      .then((snapshot) => {
        const hiddens = []
        snapshot.docs.forEach((doc, idx) => {
          const data = doc.data()

          // default type to Ethereum
          let type = 'Ethereum'
          if (data.type) {
            type = data.type
          }
          hiddens.push({
            id: doc.id,
            contractAddress: data.contractAddress,
            tokenID: data.tokenID,
            walletAddress: data.walletAddress,
            type
          })
        })
        setHiddenItems(hiddens)
        if (hiddens.length === 0) {
          setHasLoaded(true)
        }
        setIsLoadingHiddenItem(false)
      })
      .catch((err) => {
        console.log(err)
        setHiddenItems([])
        setIsLoadingHiddenItem(false)
        setHasLoaded(true)
      })
  }
  const checkIfUserExists = () => {
    db.collection('users')
      .doc(username)
      .get()
      .then((doc) => {
        setUserExists(doc.exists)
      })
  }

  const loadNFTs = async (walletType, walletAddress, pins) => {
    if (walletType === 'Ethereum') {
      const ethereumNFTs = await loadEthereumPins(walletAddress, pins)
      setNFTs((prev) => prev.concat(ethereumNFTs))
    } else if (walletType === 'Polygon') {
      const polygonNFTs = await loadPolygonPins(walletAddress, pins)
      setNFTs((prev) => prev.concat(polygonNFTs))
    } else if (walletType === 'NBA Top Shot') {
      const topShotNFTs = await loadTopShotPins(walletAddress, pins)
      setNFTs((prev) => prev.concat(topShotNFTs))
    } else if (walletType === 'Solana') {
      const solanaNfts = await loadSolanaPins(walletAddress, pins)
      setNFTs((prev) => prev.concat(solanaNfts))
    } else if (walletType === 'Wax') {
      const waxNfts = await loadWaxPins(walletAddress, pins)
      setNFTs((prev) => prev.concat(waxNfts)) 
    } else if (walletType === 'Tezos') {
      const tezosNfts = await loadTezosPins(walletAddress, pins)
      setNFTs((prev) => prev.concat(tezosNfts)) 
    } else if (walletType === 'Avalanche') {
      const avalancheNfts = await loadAvalanchePins(walletAddress, pins)
      setNFTs((prev) => prev.concat(avalancheNfts)) 
    }
  }

  useEffect(() => {
    if (userExists) {
      loadHiddenItems()
    }
  }, [userExists])

  useEffect(() => {
    if (!checkIfAuthor()) {
      history.push('/')
    }
    checkIfUserExists()
  }, [])

  const handleUnhide = (pinID) => {
    var p = new Promise((resolve, reject) => {
      if (window.confirm('Are you sure you want to unhide this NFT?')) {
        // remove from firestore
        db.collection('users')
          .doc(username)
          .collection('hidden')
          .doc(pinID)
          .delete()
          .then(() => {
            setNFTs((prev) => {
              return prev.filter((item) => item.pinID !== pinID)
            })
            resolve()
          })
          .catch((err) => {
            reject()
          })
      } else {
        reject()
      }
    })
    return p
  }

  const functions = {
    handleUnhide: handleUnhide
  }

  const loadHiddenNFTData = async () => {
    const hiddenItem = {}
    hiddenItems.forEach((pin) => {
      const pinKey = pin.walletAddress + '-' + pin.type
      const pinData = {
        id: pin.id,
        index: pin.index,
        contractAddress: pin.contractAddress,
        tokenID: pin.tokenID,
        walletAddress: pin.walletAddress,
        type: pin.type
      }

      if (hiddenItem.hasOwnProperty(pinKey)) {
        hiddenItem[pinKey].push(pinData)
      } else {
        hiddenItem[pinKey] = [pinData]
      }
    })
    // load NFTs for each wallet
    for (const key of Object.keys(hiddenItem)) {
      const [walletAddress, walletType] = key.split('-')
      try {
        await loadNFTs(walletType, walletAddress, hiddenItem[key])
      } catch (err) {
        console.log(err)
        continue
      }
    }
    setHasLoaded(true)
  }
  useEffect(() => {
    ;(async () => {
      if (!isLoadingHiddenItem && hiddenItems.length > 0) {
        await loadHiddenNFTData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenItems, isLoadingHiddenItem])

  const nftComponents = NFTs.map((nft, idx) => {
    if (!nft || !nft.data) return <></>
    if (nft.type === 'Ethereum') {
      return (
        <EthereumNFTNew
          key={nft.pinID}
          data={nft.data}
          username={username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={true}
          isDraggable={false}
          hiddenItem
        />
      )
    } else if (nft.type === 'NBA Top Shot') {
      return (
        <TopShotNFTNew
          key={nft.pinID}
          data={nft.data}
          username={username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={true}
          isDraggable={false}
          hiddenItem
        />
      )
    } else if (nft.type === 'Polygon') {
      return (
        <PolygonNFT
          key={nft.pinID}
          data={nft.data}
          username={username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={true}
          isDraggable={false}
          hiddenItem
        />
      )
    } else if (nft.type === 'Solana') {
      return (
        <SolanaNFT
          key={nft.pinID}
          data={nft.data}
          username={username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={true}
          isDraggable={false}
          hiddenItem
        />
      )
    } else if (nft.type === 'Wax') {
      return (
        <WaxNFTNew
          key={nft.pinID}
          data={nft.data}
          username={username}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          caption={nft.caption}
          functions={functions}
          hiddenItem
        />
      );
    } else if (nft.type === 'Tezos') {
      return (
        <TezosNFTNew
          key={nft.pinID}
          data={nft.data}
          username={username}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          caption={nft.caption}
          functions={functions}
          hiddenItem
        />
      );
    } else if (nft.type === 'Avalanche') {
      return (
        <AvalancheNFT
          key={nft.pinID}
          data={nft.data}
          username={username}
          pinned={true}
          pinID={nft.pinID}
          walletAddress={nft.walletAddress}
          index={idx}
          functions={functions}
          caption={nft.caption}
          isClickable={true}
          isDraggable={false}
          hiddenItem
        />
      )
    }
  })

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="heading" style={{ justifyContent: 'center', paddingLeft: '0px' }}>
          <div>
            <center>
              <div className="user-label">@{username}'s Hidden NFTs</div>
            </center>
          </div>
        </div>
        <h1 style={{ marginLeft: '20px', marginBottom: '10px' }}>Hidden NFTs</h1>
        <div className="NFT-container">
          {hasLoaded ? (
            NFTs.length > 0 ? (
              nftComponents
            ) : (
              'No hidden items available'
            )
          ) : (
            <div style={{ textAlign: 'center' }}>
              <img src="../spin.svg" alt="Loading spinner" style={{ width: '150px' }} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
