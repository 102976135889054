import { sampleSize, isArray, compact } from 'lodash';
import {AtomicMarketApi} from "atomicmarket"
//import fetch from 'node-fetch'

type WaxCollection = {
    //collection_name: string;
    name: string;
    img: string;
    author: string;
}

type WaxData = {
    img?: string;
    name: string;
    rarity: string;
    backimg: string;
    video?: string;
};

type PriceData = {
   amount: string; // in WAX, even if in delphi
   median: string; // for delphi
   token_symbol: string;
};

export type WaxSalesData = {
  assets: any[];
  assets_contract: string;
  buyer: string | null;
  collection_name: string;
  listing_price: string;
  listing_symbol: string; 
  offer_id: string;
  ordinality: "1"
  //price: {token_contract: 'eosio.token', token_symbol: 'WAX', token_precision: 8, median: null, amount: '10000000000'}
  sale_id: string;
  seller: string; // owner
  state: number; // should be 1
  price: PriceData;

  auction_id?: string; // cheat for auction data
  bids?: WaxBid[];
  end_time?: string; // "1651961227000"
};

export type WaxBid = {
  account: string; // wallet of bidder
  amount: string; // WAX (needs to be divided by 8)
  number: number; // no of bid (1 is 1st bid)
  txid: string;
};

export type WaxNFTData = {
    asset_id: string;
    owner: string;
    name: string;
    collection?: WaxCollection;
    data: WaxData;
    sales: Sale[];
    auctions: Auction[];
    salesData?: WaxSalesData;
    auctionsData?: WaxSalesData;
};

type Sale = {
  sale_id: string;
  market_contract: string; //"atomicmarket"
}

type Auction = {
  auction_id: string;
  market_contract: string; //"atomicmarket"
}

// changeUrl?
// https://wax-atomic-api.eosphere.io
//const api = new AtomicMarketApi("https://wax.api.aa.atomichub.io", "atomicmarket", {fetch: fetch as any});
const proxyUrl = 'https://lazy-cors.herokuapp.com/';
const api = new AtomicMarketApi(proxyUrl + "https://wax.api.atomicassets.io", "atomicmarket", {fetch: fetch as any});
export const WAX_IPFS_URL = "https://ipfs.ledgerwise.io/ipfs/";

export type WaxOptions = {
  owner?: string;
  ids?: string;
}

export const loadWaxAuctionsByOwner = async (owner: string) => {
  const resp: any = await api.getAuctions({ participant: owner });
  if (resp) {
    return resp as WaxSalesData[]; 
  } 
  return Promise.resolve();
};

export const loadWaxNFTs = async (options: WaxOptions, pageNumber?: number, noSalesData?: boolean) => {
  let resp: any = await api.getAssets(options, 1, pageNumber);
  let auctionsResp;
  if (options.owner && !noSalesData) {
    auctionsResp = await loadWaxAuctionsByOwner(options.owner);
  } 
  if (resp && isArray(resp)) {
    if (auctionsResp) {
      const toGetIds = compact(auctionsResp.filter(x => x.state === 1).map(x => x.assets[0].asset_id));
      if (toGetIds.length > 0) {
        const newNFTs = await api.getAssets({ ids: toGetIds.join(',') })
        resp = resp.concat(newNFTs);
      }
    }

    // Get extra data
    const salesPromises: Promise<any>[] = [],
      auctionsPromises: Promise<any>[] = [],
      salesIndexes: number[] = [],
      auctionsIndexes: number[] = [];
    resp.forEach((nft: any, i: number) => {
        if (!options.owner) {
          salesPromises.push(api.getSales({ asset_id: nft.asset_id }));
          salesIndexes.push(i);
        }
      if ((nft.auctions.length > 0 || nft.owner === 'atomicmarket') && !noSalesData) {
        auctionsPromises.push(api.getAuctions({ asset_id: nft.asset_id }));
        auctionsIndexes.push(i);
      }
    });
    if (!noSalesData) {
      if (salesPromises.length > 0 && !options.owner) {
        const sales = await Promise.all(salesPromises);
        if (sales) {
          sales.forEach((sale, i) => {
            resp[salesIndexes[i]].salesData = sale.find((x: any) => x.state === 1);
          })
        }
      } else {
        const sales = await api.getSales({ owner: '4ogmy.c.wam'});
        if (sales && sales.length > 0) {
          resp.forEach((nft: any, i: number) => {
            sales.filter(x => x.state != 3).forEach((x) => {
              if (x.assets.map(x => x.asset_id).indexOf(nft.asset_id) > -1) {
                resp[i].salesData = x;
              }
            });
          });
        }
      }
    }
    if (auctionsPromises.length > 0 && !noSalesData) {
      const auctions = await Promise.all(auctionsPromises);
      if (auctions) {
        auctions.forEach((auction, i) => {
          resp[auctionsIndexes[i]].auctionsData = auction[0];
          // state 1 active
          // state 4 over and claimable
        })
      }
    }
    return resp as WaxNFTData[];
  }
  return [];
}
/*
export const loadFeaturedWaxNFTs = async (assetIds: string[]) => {
    const resp = await api.getAssets({ ids: assetIds.join(',')}, 1, 100);
    if (resp && isArray(resp)) {
          return resp as any as WaxNFTData[];
    }
    return [];
}*/

export const loadWaxNFT = async (assetId: string) => {
    const resp = await loadWaxNFTs({ ids: assetId })
    if (resp && resp.length === 1) {
      return resp[0] as any as WaxNFTData;
    }
    return [];
}

export const loadWaxPins = async (walletAddress: string, pins: any) => {
  // load a batch of Polygon NFTs
  const NFTs = []
  const waxNfts = await loadWaxNFTs({ ids: pins.map((x: any) => x.tokenID).join(',') });

  for (const pin of pins) {
    const asset = waxNfts.find((item) => item.asset_id === pin.tokenID)
    const nft = {
      type: 'Wax',
      index: pin.index,
      data: asset,
      walletAddress,
      pinID: pin.id,
      caption: pin.caption
    }
    NFTs.push(nft)
  }

  return NFTs
}
