import React, { useState } from 'react'
import './NavBar.css'
import firebase from '../../firebase'
import { withRouter } from 'react-router-dom'
import SideBar from './Sidebar'
import { useMediaQuery } from 'react-responsive'

const NavBar = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear()
        props.history.push('/login')
      })
  }
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 800px)'
  })

  const currentUser = firebase.auth().currentUser
  var content = null

  if (currentUser) {
    content = (
      <SideBar pageWrapId={'page-wrap'} outerContainerId={'App'} currentUser />
    )
  } else if (props.showGetStarted) {
    content = (
      <React.Fragment>
        <div className="buttons">
          {isDesktopOrLaptop ? (
            <div className='link-container'>
              <a
                className='link'
                href="/faq"
              >
                FAQ
              </a>
              <a
                className='link'
                href="/about"
              >
                About
              </a>
              <a
                className='link'
                onClick={() => (document.location.href = '/signup')}
              >
                Create your showcase
              </a>
              <button
                style={{ fontSize: 14, fontWeight: 700, display: 'flex', paddingInline: 40, paddingBlock: 16 }}
                onClick={() => (document.location.href = '/login')}
              >
                Sign In
              </button>
            </div>
          )
            :
            (
              <SideBar pageWrapId={'page-wrap'} outerContainerId={'App'} currentUser={currentUser} />
            )
          }
        </div>
      </React.Fragment>
    )
  }
  let logoURL = '/'
  if (currentUser) {
    logoURL = '/explore'
  }
  return (
    <div className="nav">
      <div className="branding">
        <a href={logoURL}>
          <span className="logo">Lazy</span>
        </a>
      </div>
      {content}
    </div>
  )
}

export default withRouter(NavBar)
